import Driver from "../../entities/Driver";
import DriverDBInterface, { UpdateOrderFunctionParams } from "../interfaces/DriverDBInterface";
import { findWithRedux } from "./utils/find_firebase";
import firebase from "../../../services/Firebase"
import { deleteStorage } from "../../redux/backendStorage";
import { allWithRedux } from "./utils/all_firebase";
import {db} from "../../../services/Firebase"

export default class DriverFirebase implements DriverDBInterface{

  async all():Promise<Driver[]>{
    return allWithRedux("driver", Driver);
  }

  async find(id:string) : Promise<Driver|undefined>{
    return findWithRedux(id, "driver", Driver);
  }

  async update(driver:Driver){
    let removedBlank = Object.fromEntries(Object.entries(driver).filter(([_, v]) => v != null));
    await db.collection("driver").doc(driver.id).update({
      ...removedBlank
    })
    deleteStorage("driver/"+driver.id);
  }

  async updateDriverDeliveryOrder(params:UpdateOrderFunctionParams):Promise<any>{
    const updateDriverDeliveryOrder = firebase.functions().httpsCallable('updateDriverDeliveryOrder')
    updateDriverDeliveryOrder(params);
    deleteStorage("associated/"+params.associatedId);
    deleteStorage("driver/"+params.driverId);
    return null;
  }
}