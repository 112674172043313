import React, { useCallback, useEffect, useState } from "react";

import { useFirestore } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
  Fab,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ChatIcon from "@material-ui/icons/Chat";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import * as yup from "yup";
import { compareString } from "../../utils/index";
import PhoneMaskedInput from "../../components/inputMasks/PhoneMaskedInput";
import CepMaskedInput from "../../components/inputMasks/CepMaskedInput";
import CpfMaskedInput from "../../components/inputMasks/CpfMaskedInput";
import Neighborhood from "../../services/db/Neighborhood";
import Products from "../../services/db/Products";
import firebase from "../../services/Firebase";
import Loading from "../../components/Loading";
import SelectInput from "../../components/inputs/SelectInput";
import Flavor from "../../services/db/Flavor";
import DriverOrderInteractor from "../../app/interactors/driver/DriverOrderInteractor";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
  chatIcon: {
    float: "right",
  },
}));

const frequencyOptions = [
  {
    label: "Semanal",
    value: "weekly",
  },
  {
    label: "Quinzenal",
    value: "biweekly",
  },
  {
    label: "Próxima entrega",
    value: "individual",
  },
];

function getFormatedDate(dateInSeconds) {
  const date = new Date(dateInSeconds * 1000);

  return format(date, "yyyy-MM-dd");
}

function getIndividualItems(associated) {
  if (!associated.individualPurchases) {
    return [];
  }

  return associated.individualPurchases.map((item) => ({
    ...item,
    frequency: "individual",
  }));
}

const createAssociated = firebase.functions().httpsCallable("createAssociated");
const updateAssociated = firebase.functions().httpsCallable("updateAssociated");

function CreateAssociated() {
  const db = useFirestore();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(true);
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [flavourOptions, setFlavourOptions] = useState([]);
  const { associated, id } = location.state ? location.state : {};
  const classes = useStyles();

  const isIndividualProduct = useCallback(
    function (productId) {
      return products.find((product) => product.id === productId)?.individual;
    },
    [products]
  );

  const isTastable = useCallback(
    function (productId) {
      return products.find((product) => product.id === productId)?.tastable;
    },
    [products]
  );

  const passwordOptions = (associated) => {
    if (!associated) {
      return yup
        .string()
        .required("Senha é obrigatório")
        .min(6, "Senha deve ter no mínimo 6 caracteres");
    } else {
      return yup.string();
    }
  };

  const schema = yup.object().shape({
    displayName: yup.string().required("Nome é obrigatório").trim(),
    cpf: yup
      .string()
      .required("CPF é obrigatório").trim(),
    password: passwordOptions(associated),
    email: yup.string().email().required("Email é obrigatório").trim(),
    phone: yup.string().required("Telefone é obrigatório").trim(),

    cep: yup.string().required("CEP deve existir").trim(),
    address: yup.string().required("Endereço deve existir").trim(),
    deliveryAddress: yup.string().required("Endereço de entrega deve existir").trim(),
    neighborhoodId: yup.string().required("Bairro deve existir").trim(),

    birthdate: yup.date().required(),
    entry_date: yup.date().required(),
    observation: yup.string().trim(),

    // Nested
    paymentPlan: yup.array().of(
      yup.object().shape({
        productId: yup.string().required("Selecione um produto").trim(),
        quantity: yup.number().min(1, "Selecione no mínimo 1"),
        frequency: yup.string().required("Selecione uma frequencia").trim(),
      })
    ),
  });

  const { handleSubmit, control, errors, watch, setValue, trigger, getValues } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: associated
        ? {
            ...associated,
            paymentPlan: [
              ...associated.paymentPlan,
              ...getIndividualItems(associated),
            ],
            birthdate: getFormatedDate(associated.birthdate.seconds),
          }
        : {},
    });

  const {
    fields: fieldsFlavour,
    append: appendFlavour,
    remove: removeFlavour,
  } = useFieldArray({
    control,
    name: "intolerances",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "paymentPlan",
  });

  const paymentPlan = watch("paymentPlan", fields);

  const inactivateAssociated = async () =>{
    await db.collection("associated").doc(id).update({active:false})
    await DriverOrderInteractor.removeAssociatedFromDeliveries(id);
    history.goBack();
  }

  const activateAssociated = async () =>{
    await db.collection("associated").doc(id).update({active:true})
    history.goBack();
  }

  const submitHandler = async (associatedInfo) => {
    setLoading(true);

    //checkForPlanReceiveOnce
    if (associatedInfo.paymentPlan) {
      for (const product of associatedInfo.paymentPlan) {
        if (
          !isIndividualProduct(product.productId) &&
          !isTastable(product.productId) &&
          product.frequency === "individual"
        ) {
          alert("Produto de plano não pode ter apenas uma entrega!");
          setLoading(false);
          return;
        } else if (
          (isIndividualProduct(product.productId) ||
            isTastable(product.productId)) &&
          product.frequency !== "individual"
        ) {
          alert("Produto avulso não pode ter frequencia de plano!");
          setLoading(false);
          return;
        }
      }
    }

    Object.keys(associatedInfo).forEach((k) => {
      if (associatedInfo[k] === "") {
        delete associatedInfo[k];
      }
    });

    if (!("paymentPlan" in associatedInfo)) {
      associatedInfo.paymentPlan = [];
    }

    associatedInfo.birthdate = firebase.firestore.Timestamp.fromDate(
      associatedInfo.birthdate
    );
    associatedInfo.entry_date = firebase.firestore.Timestamp.fromDate(
      associatedInfo.entry_date
    );

    try {
      if (associated) {
        await updateAssociated({
          uid: id,
          data: associatedInfo,
        });
        history.goBack();
      } else {
        await createAssociated(associatedInfo);
        history.goBack();
      }
      // alert("Associado criado com sucesso!");
    } catch (error) {
      alert("Erro: " + error);
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setInputLoading(true);
      //Bairro
      const bairrosFetched = await Neighborhood.all(db);
      setNeighborhoodOptions(
        Object.keys(bairrosFetched)
          .map((id) => ({
            label: bairrosFetched[id].props.name,
            value: id,
          }))
          .sort((a, b) => {
            return compareString(a.label, b.label);
          })
      );

      // Products
      const productsFetched = await Products.all(db);
      setProductOptions(
        Object.keys(productsFetched).map((id) => ({
          label: productsFetched[id].props.name,
          value: id,
        }))
      );
      setProducts(
        Object.keys(productsFetched)
          .map((id) => ({
            ...productsFetched[id].props,
            id,
          }))
          .sort((a, b) => {
            return compareString(a.name, b.name);
          })
      );

      // Flavours
      const flavoursFetched = await Flavor.all(db);
      setFlavourOptions(
        Object.keys(flavoursFetched)
          .map((id) => ({
            label: flavoursFetched[id].props.name,
            value: id,
          }))
          .sort((a, b) => {
            return compareString(a.label, b.label);
          })
      );

      setInputLoading(false);
    })();
  }, [db]);

  useEffect(() => {
    const inter = paymentPlan
      .map((product, index) => {
        if (
          (isIndividualProduct(product.productId) ||
            isTastable(product.productId)) &&
          product.frequency !== "individual"
        ) {
          return index;
        }
        return null;
      })
      .filter((p) => p !== null);
    //console.log(inter);
    inter.forEach((index) => {
      setValue(`paymentPlan[${index}].frequency`, "individual");
      trigger("paymentPlan");
    });
  }, [isIndividualProduct, isTastable, paymentPlan, setValue, trigger]);

  if (inputLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <h3>
            Informações Gerais{" "}
            {associated && (
              <Fab
                size="small"
                className={classes.chatIcon}
                onClick={() => {
                  history.push({
                    pathname: "/admin/chat",
                    state: {
                      id,
                    },
                  });
                }}
              >
                <ChatIcon />
              </Fab>
            )}
          </h3>

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome"
            name="displayName"
            autoComplete="name"
            type="text"
            error={!!errors.displayName}
            helperText={errors.displayName?.message}
            defaultValue={associated?.displayName}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cpf"
            label="CPF/CNPJ"
            name="cpf"
            autoComplete="cpf"
            type="text"
            error={!!errors.cpf}
            helperText={errors.cpf?.message}
            defaultValue={associated?.cpf}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            autoComplete="phone"
            type="text"
            InputProps={{
              inputComponent: PhoneMaskedInput,
              defaultValue: associated?.phone,
            }}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />

          <Controller
            as={TextField}
            control={control}
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message}
            defaultValue={associated?.email}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <h3>Informações de Entrega</h3>

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cep"
            label="CEP"
            name="cep"
            autoComplete="cep"
            type="text"
            InputProps={{
              inputComponent: CepMaskedInput,
              defaultValue: associated?.cep,
            }}
            error={!!errors.cep}
            helperText={errors.cep?.message}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="address"
            label="Endereço"
            name="address"
            autoComplete="address"
            error={!!errors.address}
            helperText={errors.address?.message}
            defaultValue={associated?.address}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="deliveryAddress"
            label="Endereço de entrega"
            name="deliveryAddress"
            autoComplete="address"
            error={!!errors.deliveryAddress}
            helperText={errors.deliveryAddress?.message}
            defaultValue={associated?.deliveryAddress}
          />

          <SelectInput
            required
            options={neighborhoodOptions}
            label="Bairro"
            name="neighborhoodId"
            control={control}
            error={!!errors.flavour}
            helperText={errors.flavour?.message}
            defaultValue={associated?.neighborhoodId}
          />
          <h3>Outras Informações</h3>

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="birthdate"
            label="Data de nascimento"
            name="birthdate"
            type="date"
            error={!!errors.birthdate}
            helperText={errors.birthdate?.message}
            InputLabelProps={{ shrink: true }}
          />
          
          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="entry_date"
            label="Data de entrada no clube"
            name="entry_date"
            type="date"
            error={!!errors.entry_date}
            helperText={errors.entry_date?.message}
            InputLabelProps={{ shrink: true }}
          />

          <Controller
            as={TextField}
            control={control}
            variant="outlined"
            margin="normal"
            fullWidth
            id="observation"
            label="Observação"
            name="observation"
            type="text"
            error={!!errors.observation}
            helperText={errors.observation?.message}
            defaultValue={associated?.observation}
          />

          {fieldsFlavour.map((item, index) => {
            return (
              <div key={item.id}>
                <SelectInput
                  required
                  options={flavourOptions}
                  label="Alergia"
                  name={`intolerances[${index}].flavorId`}
                  control={control}
                  defaultValue={`${item.flavorId}`}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={() => removeFlavour(index)}
                >
                  Delete
                </Button>
              </div>
            );
          })}
          <br />
          <br />
          <button
            type="button"
            onClick={() => {
              appendFlavour({
                flavorId: flavourOptions[0].value,
              });
            }}
          >
            Adicionar Alergia
          </button>
          <br />
          <br />
          <h3>Cadastro de Produtos</h3>
          {fields.map((item, index) => {
            const freq = getValues(`paymentPlan[${index}].frequency`);
            const prodId = getValues(`paymentPlan[${index}].productId`);

            return (
              <div key={item.id}>
                <SelectInput
                  required
                  options={productOptions}
                  label="Produto"
                  name={`paymentPlan[${index}].productId`}
                  control={control}
                  defaultValue={`${item.productId}`}
                />

                <Controller
                  as={TextField}
                  control={control}
                  variant="outlined"
                  id="quantity"
                  margin="normal"
                  label="Quantidade"
                  name={`paymentPlan[${index}].quantity`}
                  required
                  fullWidth
                  type="number"
                  defaultValue={`${item.quantity}`}
                />

                <SelectInput
                  disabled={
                    freq === "individual" && isIndividualProduct(prodId)
                  }
                  required
                  control={control}
                  options={frequencyOptions}
                  label="Frequência"
                  name={`paymentPlan[${index}].frequency`}
                  defaultValue={`${item.frequency}`}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={() => remove(index)}
                >
                  Delete
                </Button>
                <br />
                <br />
              </div>
            );
          })}
          <button
            type="button"
            onClick={() => {
              append({
                quantity: 1,
                frequency: "weekly",
                productId: "SuvhmgOhtKXULnLE0uBr" || productOptions[0].value, // Hardcoded 'kombucha plano'
              });
            }}
          >
            Adicionar Item
          </button>
          <div className={classes.submitWrapper}>
            {!associated && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Criar Associado
              </Button>
            )}
            {associated && (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  Atualizar Associado
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  onClick={() => {
                    history.push({
                      pathname: "/admin/associated_receipt",
                      state: { id },
                    });
                  }}
                >
                  Ver Recibos
                </Button>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={loading}
                    onClick={() => {inactivateAssociated()}}
                >
                  Inativar Associado
                </Button>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={loading}
                    onClick={() => {activateAssociated()}}
                >
                  Ativar Associado
                </Button>
              </>
            )}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default CreateAssociated;
