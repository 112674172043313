import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import PaymentsIndexListItem, {
  PaymentsIndexListItemProps,
} from "../../atoms/associated/PaymentsIndexListItem";

export default function PaymentsIndexList(props: {
  list: PaymentsIndexListItemProps[];
}) {
  return (
    <List>
      {props.list.map((item) => {
        return (
          <PaymentsIndexListItem
            sent_date={item.sent_date}
            value={item.value}
            status={item.status}
            raw_status={item.raw_status}
          />
        );
      })}
    </List>
  );
}
