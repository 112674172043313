import React from "react";

import usePresenter from "../../redux/usePresenter";
import ShowReceiptPresenter from "../../presenters/shared/receipt/ShowReceiptPresenter";
import ShowReceiptInfo from "../organisms/receipt/ShowReceiptInfo";
import { Divider, List } from "@material-ui/core";
import ReceiptBillItem from "../atoms/shared/receipt/ReceiptBillItem";


type ShowReceiptTemplateProps = {
  receipt_id:string
}

export default function ShowReceiptTemplate(props: ShowReceiptTemplateProps) {
  const show_data:any = usePresenter(new ShowReceiptPresenter(props.receipt_id));

  if(!show_data) return <></>;
  console.log(show_data);
  return (
    <>
      <ShowReceiptInfo 
        idx={"0"}
        {...show_data}
      />
      <Divider />
      <List>
        <ReceiptBillItem 
          forceShow={true}
          description={"Valor Pago"}
          value={String(show_data.paidValue)}
          idx={"0"}   
        />
      </List>
    </>
  );
}
