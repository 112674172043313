import React from "react";

import {useLocation} from "react-router-dom";
import DeliveryHistoryIndex from "../../app/components/templates/DeliveryHistoryIndex";


export default function DeliveryHistory() {
    const location = useLocation();


    const { id } = location.state ? location.state : {};

    return <DeliveryHistoryIndex associated_id={id} />

}