import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useFirestore } from "react-redux-firebase";
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import roles from "../../../constants/roles";
import Associated from "../../../services/db/Associated";
import { messaging } from "../../../services/Firebase";
import { VAPIDKEY } from "../../../constants/keys";

import database from "../../database/DataBaseConfig";
import User from "../../entities/User"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

export default function LoginForm(){
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const classes = useStyles();
  const db = useFirestore();
  const firebase = useFirebase();
  const history = useHistory();
  const dispatch = useDispatch();

  const config_messaging  = async (user: User ) =>{
    if(!messaging) return;
    let currentToken:string;
    try{
      currentToken = await messaging.getToken({ vapidKey: VAPIDKEY })
    }
    catch(e:any){
      return;
    }
    if (currentToken) {
      await db
        .collection("users")
        .doc(user.id)
        .update({ notificationKey: currentToken });
      if (user.role === roles.ASSOCIATED) {
        await db
          .collection("associated")
          .doc(user.id)
          .update({ notificationKey: currentToken });
      }
    } else {
      alert("Por favor habilite o envio de notificações ;)");
    }
  }

  const onSubmit = (e:any) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    firebase
      .login({
        email,
        password,
      })
      .then(async (user) => {
        setEmail("");
        setPassword("");

        const uid = firebase.auth().currentUser?.uid;
        if(!uid) throw new Error("User nor found");
        const user_doc = await database.users_db.find(uid);
        if(!user_doc)  throw new Error("User nor found");
        config_messaging(user_doc);

        if (user_doc.role === roles.ASSOCIATED) {
          const associated = await Associated.find(
            db,
            user_doc.id
          );
          if(!associated) throw new Error("User nor found"); 
          dispatch({type: "app/associated/update", payload: associated})
  
          if (!associated.props.acceptedTerm) {
            history.replace("/termo");
          } else {
            history.replace("/associated");
          }
        } else if (user_doc.role === roles.DRIVER) {
          history.replace("/driver");
        } else if (user_doc.role === roles.ADMIN) {
          history.replace("/admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <form className={classes.form} noValidate>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        error={!!error}
        helperText={error}
      />
      <TextField
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Senha"
        type="password"
        id="password"
        autoComplete="current-password"
      />

      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.submit}
          disabled={loading}
        >
          Entrar
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </div>
    </form>
  )
}