import { isSameWeek } from "date-fns";
import {
  compareDeliveryDates,
  normalizeDeliveryDate,
} from "../../utils/date_manipulation";
import database from "../database/DataBaseConfig";
import Associated from "../entities/Associated";
import Product from "../entities/Product";
import DeliveryDaysInteractor from "./delivery/DeliveryDaysInteractor";

export default class AssociatedInteractor {
  static weekIsSuspended(associated: Associated, eval_date: Date) {
    if (!associated.suspendedDates) return false;
    return associated.suspendedDates.some((suspendedDate) => {
      return compareDeliveryDates(eval_date, suspendedDate);
    });
  }

  /**
   * Funcao para verificar se motorista deve ir ao associado essa semana
   * (i.e. associado deve estar na lista de entrega)
   */
  static associatedShouldReceiveDeliveryThisWeek(
    associated: Associated
  ): Boolean {
    // Anotacoes cliente
    /**
     * O ENTREGADOR NUNCA DEVE IR A UM DESTINO SOMENTE PARA BUSCAR UMA GARRAFA. CUSTO DESNECESSÁRIO.
     * A ÚNICA EXCEÇÃO É PARA CLIENTES QUE TIVERAM SEU PLANO SUSPENSO.
     * SOMENTE NESSE CASO DEVEMOS BUSCAR A GARRAFA. EM SEGUIDA O ASSOCIADO SE TORNA INATIVO
     */
    if (AssociatedInteractor.lastDeliveryWasThisWeek(associated)) return true;

    // Se usuario estiver suspenso (i.e. nao puder receber) nao deve-ir
    if (AssociatedInteractor.weekIsSuspended(associated, new Date()) === true)
      return false;

    if (
      associated.deleted === true ||
      AssociatedInteractor.hasPaymentPlan(associated) === false
    ) {
      if (AssociatedInteractor.hasPendingItems(associated)) return true;
    }

    let has_any_item_this_week =
      DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(
        new Date(),
        associated
      );
    return has_any_item_this_week;
  }

  static hasPaymentPlan(associated: Associated) {
    let plan = associated.paymentPlan || [];
    return plan.length > 0;
  }

  static hasPendingItems(associated: Associated) {
    if (
      associated.deliveryInfo.pendingItems &&
      associated.deliveryInfo.pendingItems.length > 0
    ) {
      return true;
    } else return false;
  }

  static lastDeliveryWasThisWeek(associated: Associated) {
    if (
      associated.deliveryInfo.lastDeliveryDate &&
      isSameWeek(associated.deliveryInfo.lastDeliveryDate, new Date())
    ) {
      return true;
    }
    return false;
  }

  static associatedIsIntolerantTo(associated: Associated, flavor_id: string) {
    if (associated.intolerances === undefined) return false;

    for (let intolerance of associated.intolerances) {
      if (intolerance.flavorId === flavor_id) return true;
    }
    return false;
  }

  static async getFlavorNameForProduct(
    associated: Associated,
    product: Product
  ) {
    let flavor = await database.flavor_db.find(product.flavorId);
    if (flavor) {
      if (
        !AssociatedInteractor.associatedIsIntolerantTo(associated, flavor.id)
      ) {
        return flavor.name;
      }
      return "Sabor alternativo";
    }
    return "Sem sabor";
  }

  static getWeekDayAssociateds(associateds: Associated[], day: number) {
    return associateds.filter(
      (associated) => associated.deliveryInfo.weekDay === day
    );
  }

  static async getDeliveryObservationOnDate(id: string, date: Date) {
    let associated = await database.associated_db.find(id);
    if (!associated) return "";

    date = normalizeDeliveryDate(date);
    let delivery_date = date.toISOString().split("T")[0];

    let delivery_obs = associated.delivery_observation_on_date || {};
    return delivery_obs[delivery_date] || "";
  }

  static async updateDeliveryObservationOnDate(
    id: string,
    date: Date,
    obs: string
  ) {
    let associated = await database.associated_db.find(id);
    if (!associated) return "";
    date = normalizeDeliveryDate(date);
    let delivery_date = date.toISOString().split("T")[0];
    let delivery_obs = associated.delivery_observation_on_date || {};
    delivery_obs[delivery_date] = obs;

    associated.delivery_observation_on_date = delivery_obs;

    await database.associated_db.update(id, associated);
  }
}
