import { CircularProgress } from "@material-ui/core";
import React from "react";

type LoadingCircleProps = {
  show: boolean
}

export default function LoadingCircle({show}:LoadingCircleProps) {
  if(!show) return (<></>)
  
  return (
    <div className="loading-container">
      <CircularProgress />
    </div>
  );
}
