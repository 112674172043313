import React from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import AsscoaitedDeliveryInfoList from "../../app/components/molecules/associated/AssociatedDeliveryInfoList";


function InformacaoDeEntrega() {
  const params = useParams();

  let { date } = params;
  const user          = useSelector((state) => state.firebase.auth);
  
  return <AsscoaitedDeliveryInfoList
    date={new Date(date.replaceAll("-", "/"))}
    associated_id={user.uid}
  />
}

export default InformacaoDeEntrega;
