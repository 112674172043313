import { isSameDay, subDays } from "date-fns";



export function getTodayNumber(){
  return (new Date()).getDay();
}

export function normalizeDeliveryDate(date){
  let domingo_transponse = subDays(date, date.getDay());
  domingo_transponse.setHours(0,0,0,0);
  return domingo_transponse;
}

export function compareDeliveryDates(date1, date2){
  return isSameDay( normalizeDeliveryDate(date1), normalizeDeliveryDate(date2))
}

export function treatAsUTC(date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function daysBetween(startDate, endDate) {
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.floor((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
}