import PresenterInterface from "../../PresenterInterface";
import { getMonth, getYear } from "date-fns";
import Receipt from "../../../entities/Receipt";
import database from "../../../database/DataBaseConfig";
import PaymentsIndexPresenter from "../../associated/PaymentsIndexPresenter";
import { ReceiptIndexListItemType } from "../../../components/atoms/admin/ReceiptIndexListItem";

export default class IndexReceiptPresenter implements PresenterInterface {
  storage_path: string;

  constructor(
    private date: Date,
    private status: string,
    private query: string
  ) {
    this.storage_path = `/admin/receipt/index/${status}/${getYear(
      date
    )}/${getMonth(date)}/${query}`;
  }

  async getData(): Promise<ReceiptIndexListItemType[]> {
    let receipts = await this.getReceiptList();
    return this.formatReceiptListToData(receipts);
  }

  private async getReceiptList(): Promise<Receipt[]> {
    return await database.receipt_db.searchStatusAndDate(
      this.status,
      this.date
    );
  }

  private formatReceiptListToData(
    receipts: Receipt[]
  ): ReceiptIndexListItemType[] {
    let formatted_data: ReceiptIndexListItemType[] = [];
    receipts.forEach((receipt: Receipt) => {
      if (
        receipt.associatedName
          .toLowerCase()
          .includes(this.query.toLowerCase()) ||
        this.query === ""
      ) {
        formatted_data.push({
          sent_date: PaymentsIndexPresenter.getFormattedDate(receipt.date),
          status: PaymentsIndexPresenter.getFormattedStatus(receipt.status),
          value: PaymentsIndexPresenter.getFormattedFloat(receipt.paidValue),
          raw_status: receipt.status,
          associatedName: receipt.associatedName,
          id: receipt.id,
          receipt_info: receipt,
        } as ReceiptIndexListItemType);
      }
    });
    return formatted_data;
  }
}
