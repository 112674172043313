import PresenterInterface from "../PresenterInterface";
import { ShowReceiptInfoProps } from "../../components/organisms/receipt/ShowReceiptInfo";
import { calculateNewReceipt } from "../../interactors/ReceiptsInteractor";

import {getInfoForShowReceipt} from "../../presenters/utils/receipt"
import database from "../../database/DataBaseConfig";

export default class CreateReceiptPresenter implements PresenterInterface{
  storage_path:string

  constructor(public associated_id:string){
    this.storage_path = "/receipt/create/"+this.associated_id;
  }

  async getData():Promise<ShowReceiptInfoProps>{
    let new_receipt = await calculateNewReceipt(this.associated_id);
    return getInfoForShowReceipt(new_receipt)
  }

  static async createReceipt(associated_id: string, paidValue:string, file_url:string){
    let associated = await database.associated_db.find(associated_id);
    if(!associated) throw new Error("Associated not found");
    let new_receipt = await calculateNewReceipt(associated_id);

    await database.receipt_db.createReceiptForAssociated({
      associatedId: associated.id,
      associatedName: associated.displayName,
      bills: new_receipt.bills,
      date: (new Date()).toISOString().slice(0, 10),
      credits_before_confirmation: new_receipt.credits_before_confirmation,
      credits_after_confirmation: new_receipt.credits_after_confirmation,
      file_url,
      base_value: new_receipt.base_value,
      paidValue
    })
  }
}



