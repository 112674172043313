import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel from "./Application"

type UsersFields = {
    displayName: string,
    birthdate: any,
    email: string,
    phone: string,
    photoURL: string,
    role: string,

    neighborhoodId?:string
}

export default class Users extends ApplicationModel {

    public static modelName = "users";

    constructor(
        public db: ExtendedFirestoreInstance,
        public props: UsersFields,
        public docRef: DocumentReference<DocumentData>
    ) {
        super(db, props, docRef)
    }


}
