import React from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { TextField } from "@material-ui/core";

import InputMask from "react-input-mask";

type form_params = {
  control: Control<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  field_name: string;
  label: string;
  [id: string]: any;
  type?: string;
  required?: boolean;
};

const FormField = ({
  control,
  required,
  label,
  errors,
  type,
  field_name,
  ...props
}: form_params) => {
  if (props.mask) {
    return (
      <Controller
        style={{ marginTop: "4px" }}
        as={
          <InputMask mask={props.mask} disabled={false}>
            {() => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={label}
              />
            )}
          </InputMask>
        }
        control={control}
        variant="outlined"
        margin="normal"
        required={required === undefined ? true : required}
        fullWidth
        id={field_name}
        label={label}
        name={field_name}
        type={type || "text"}
        error={!!errors[field_name]}
        helperText={errors[field_name]?.message}
        {...props}
      />
    );
  }

  return (
    <Controller
      style={{ marginTop: "4px" }}
      as={TextField}
      control={control}
      variant="outlined"
      margin="normal"
      required={required === undefined ? true : required}
      fullWidth
      id={field_name}
      label={label}
      name={field_name}
      type={type || "text"}
      error={!!errors[field_name]}
      helperText={errors[field_name]?.message}
      {...props}
    />
  );
};

export default FormField;
