import { data_format } from "../../../presenters/associated/PaymentsIndexPresenter";

import { Button, CircularProgress, Container } from "@material-ui/core";

import React from "react";
import { useHistory } from "react-router-dom";
import PaymentsIndexList from "../../molecules/associated/PaymentsIndexList";
import PaymentsIndexDatePicker from "../../atoms/associated/PaymentsIndexDatePicker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export type PaymentsIndexTemplate = {
  list: data_format[];
  date: Date | null;
  setDate: (date: MaterialUiPickersDate) => void;
};

export default function PaymentsIndexTemplate(props: PaymentsIndexTemplate) {
  const history = useHistory();

  if (!props.list) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <>
        <Container>
          <PaymentsIndexDatePicker date={props.date} setDate={props.setDate} />
          <PaymentsIndexList list={props.list} />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              fontSize: "12px",
              float: "right",
            }}
            onClick={() => {
              history.push("/associated/pagamento/envio");
            }}
          >
            Enviar Comprovante
          </Button>
        </Container>
      </>
    </div>
  );
}
