import React, { useEffect, useState } from "react";

import {
  IconButton,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Grid,
  TextFieldimport,
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ImageIcon from "@material-ui/icons/Image";

import Associated from "../../services/db/Associated";
import Receipt from "../../services/db/Receipt";
import { useFirestore } from "react-redux-firebase";
import firebase from "../../services/Firebase";
import { deleteStorage } from "../../app/redux/backendStorage";
import ShowReceiptTemplate from "../../app/components/templates/ShowReceiptTemplate";

export default function AcceptReceipt() {
  const db = useFirestore();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  let [loading, setLoading] = useState(true);
  const [receipt, setReceipt] = useState(location.state ? location.state : "");
  const [value, setValue] = useState(receipt.props.paidValue);

  useEffect(() => {
    setLoading(false);
  }, []);

  let accepted = receipt.props.status !== "pending";

  const handleReject = async () => {
    await Receipt.update(db, receipt.id, { status: "refused" });
    alert("Recibo recusado");
    history.push("/admin/receipt");
  };
  const handleAccept = async () => {
    setLoading(true);

    let acceptReceipt = firebase.functions().httpsCallable("acceptReceipt");
    let response = await acceptReceipt({ receipt_id: receipt.id });
    if (response.data["error"]) alert(response.data["error"]);
    else alert("Recibo aceito");

    deleteStorage("receipt/" + receipt.id);

    setLoading(false);
    history.push("/admin/receipt");
  };

  if (loading)
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );

  return (
    <div>
      <Container>
        <iframe
          src={receipt.props.file}
          style={{
            width: "100%",
            minHeight: "300px",
            marginTop: "20px",
          }}
        />
        <ShowReceiptTemplate receipt_id={receipt.id} />
        {!accepted && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleAccept}
              >
                Aceitar
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={handleReject}
              >
                Rejeitar
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

const useStyles = makeStyles({
  submit: {
    margin: "10px",
  },
});
