import {
    Divider,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import { useHistory } from "react-router-dom";


export type DeliveryHistoryListItemType = {
    number_items: string;
    date:string;
    total_value: string;


};

export default function DeliveryHistoryListItem(props: DeliveryHistoryListItemType) {


    return (
        <>
            <ListItem>
                <ListItemText
                    primary={props.date + " - R$ " + props.total_value}
                    secondary={  props.number_items + " Items"}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"

                    >
                        {/*<ArrowForwardIos />*/}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
}
