import React, { useCallback, useState } from "react";

import { useFirestore } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneMaskedInput from "../../components/inputMasks/PhoneMaskedInput";
import firebase from "../../services/Firebase";

const schema = yup
  .object()
  .shape({
    displayName: yup.string().required("Nome é obrigatório").trim(),
    email: yup.string().email().required("Email é obrigatório").trim(),
    phone: yup.string().required("Telefone é obrigatório").trim(),
  });

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

export default function CreateDriver() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const db = useFirestore();
  const classes = useStyles();
  const createDriver = firebase.functions().httpsCallable("createDriver");
  const updateDriver = firebase.functions().httpsCallable("updateDriver");
  const deleteDriver = firebase.functions().httpsCallable("deleteDriver");

  const [uid, setUid] = useState(location.state ? location.state.uid : "");
  const [driverProps, setDriverProps] = useState(
    location.state ? location.state.props : ""
  );

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const createNewDriver = useCallback(
    async ({ email, password, displayName, phone }) => {
      let newData = { email, password, displayName, phone };
      Object.keys(newData).forEach(
        (k) => newData[k] == "" && delete newData[k]
      );
      if (uid !== "") {
        try {
          await updateDriver({ uid: uid, data: newData });
        } catch (e) {
          alert(e);
        }
      } else {
        try {
          await createDriver(newData);
        } catch (e) {
          alert(e);
        }
      }
    },
    [createDriver, uid, updateDriver]
  );

  const submitHandler = async (driverInfo) => {
    setLoading(true);

    try {
      await createNewDriver(driverInfo);
      history.push("/admin/driver");
    } catch (error) {
      alert("Erro ao criar motorista.");
    }

    setLoading(false);
  };

  const deleteHandler = async () => {
    setLoading(true);

    for (var i = 0; i < driverProps.deliveries.length; i++) {
      if (driverProps.deliveries[i].order.length !== 0) {
        alert("Esse motorista tem entregas vinculadas a ele.");
        history.replace("/admin/driver");
        setLoading(false);
        return;
      }
    }

    try {
      await deleteDriver(uid);
      history.replace("/admin/driver");
    } catch (error) {
      alert("Erro ao deletar motorista.");
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome"
            name="displayName"
            autoComplete="name"
            type="text"
            defaultValue={driverProps.displayName}
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            autoComplete="phone"
            type="text"
            InputProps={{
              inputComponent: PhoneMaskedInput,
            }}
            defaultValue={driverProps.phone}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />

          <TextField
            inputRef={register}
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            defaultValue={driverProps.email}
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <div className={classes.submitWrapper}>
            {uid === "" && (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  Criar Motorista
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </>
            )}
            {uid !== "" && (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  Atualizar Motorista
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </>
            )}
            {uid && (
              <div
                style={{
                  fontSize: "14px",
                  color: "rgb(222 111 111)",
                  margin: "2px auto",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={deleteHandler}
              >
                Deletar Motorista
              </div>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}
