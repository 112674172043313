import { DocumentData, DocumentReference } from "@firebase/firestore-types";
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
} from "react-redux-firebase";
import ApplicationModel, { ModelDict, StaticThis } from "./Application";
import Associated from "./Associated";
import Flavor from "./Flavor";
import { deliveryInfo } from "./helpers/associated/DeliveryHelper";
import { db } from "../Firebase";

type ProductFields = {
  flavorId: string;
  name: string;
  returnableName: string;
  price: number;
  stock: number | 0;
  deleted?: boolean | null;
  imageUrl: string;
};

export default class Products extends ApplicationModel {
  public static modelName = "products";

  constructor(
    public db: ExtendedFirestoreInstance,
    public props: ProductFields,
    public docRef: DocumentReference<DocumentData>
  ) {
    super(db, props, docRef);
  }

  static async all<Products extends ApplicationModel>(
    this: StaticThis<Products>,
    db: ExtendedFirestoreInstance
  ): Promise<ModelDict<Products>> {
    const modelName = this.modelName;
    let query = db.collection(modelName);

    const data = await query.get();

    let ret: ModelDict<Products> = {};

    await Promise.all(
      data.docs.map(async (obj) => {
        ret[obj.id] = new this(db, obj.data(), obj);
      })
    );

    return ret;
  }

  static async search<Products extends ApplicationModel>(
    this: StaticThis<Products>,
    db: ExtendedFirestoreInstance,
    field: string,
    q: any
  ): Promise<ModelDict<Products>> {
    const modelName = this.modelName;
    let query = db
      .collection(modelName)
      .where("deleted", "==", false)
      .where(field, "==", q);

    const data = await query.get();
    let ret: ModelDict<Products> = {};

    await Promise.all(
      data.docs.map(async (obj) => {
        ret[obj.id] = new this(db, obj.data(), obj);
      })
    );

    return ret;
  }

  //TODO: Deixar isso mais bonito
  static getMonthlyPrice(
    productProps: ProductFields,
    quantity: number,
    frequency: "weekly" | "biweekly"
  ) {
    let willReceive = 0;
    if (frequency === "weekly") willReceive = 4;
    else willReceive = 2;

    return quantity * productProps.price * willReceive;
  }

  /**
   * @param associated Que ira identificar separacao dos dados
   * @returns 2 listas onde, a primeira contem os produtos do associado
   * e a outra contem as o resto dos produtos
   */
  static async separateProducts(
    db: ExtendedFirestoreInstance,
    associated: Associated
  ) {
    let associatedProds: Products[] = [];
    let otherProds: Products[] = [];

    const allProds = await Products.search(db, "individual", false);
    Object.values(allProds).forEach((prod) => {
      for (let userProd of associated.props.paymentPlan) {
        if (userProd.productId === prod.docRef.id) {
          associatedProds.push(prod);
        }
      }
      if (prod.props.deleted === true) return;
      otherProds.push(prod);
    });

    return [associatedProds, otherProds];
  }

  static async getAllProductsForWeek(
    date: Date,
    allAssociateds: Array<Associated>
  ) {
    return await Products.getAllProductsForAssociateds(
      Object.values(allAssociateds),
      date
    );
  }

  static async addFlavorForProductList(
    productList: Array<
      {
        flavorName?: string;
        intolerants: number;
        total: number;
        intolerant: boolean;
        flavor: any;
      } & Products
    >,
    db: ExtendedFirestoreInstance
  ) {
    await Promise.all(
      productList.map(async (product: any) => {
        return (productList[productList.indexOf(product)].flavor =
          await Flavor.find(db, product.props.flavorId));
      })
    );
    return productList;
  }

  static async getAllProductsForWeekDay(
    weekDay: Number,
    allAssociateds: Array<Associated>
  ) {
    let weekAssociateds = Object.values(allAssociateds).filter(
      (associated) => associated.props.deliveryInfo.weekDay === weekDay
    );
    return await Products.getAllProductsForAssociateds(
      weekAssociateds,
      new Date()
    );
  }

  static combineDeliveryInfos(src: deliveryInfo, dest: deliveryInfo) {
    for (let productId of Object.keys(src.products)) {
      let productData = src.products[productId];
      dest.totalProducts += productData.total;
      if (dest.products[productId]) {
        dest.products[productId].total += productData.total;
        dest.products[productId].intolerants += productData.intolerants;
      } else {
        dest.products[productId] = {
          ...productData,
          total: productData.total,
          intolerants: productData.intolerants,
          intolerant: false,
        };
      }
    }
    return dest;
  }

  static async getAllProductsForAssociateds(
    associateds: Array<Associated>,
    date: Date
  ) {
    let infosReduced: deliveryInfo = {
      deliveryType: "nextDelivery",
      totalProducts: 0,
      products: {},
    };
    let allProducts = await Products.all(db as any);
    for (let associated of associateds) {
      let associatedInfo = await associated.delivery.getDeliveryInfo(
        date,
        allProducts
      );
      infosReduced = Products.combineDeliveryInfos(
        associatedInfo,
        infosReduced
      );
    }
    return infosReduced;
  }

  static async createWithImage(
    db: ExtendedFirestoreInstance,
    firebase: ExtendedFirebaseInstance,
    props: any,
    image: File
  ) {
    // Create new File on storage and change its name to avoid conflict
    const newImageUrl = await Products.createUrlForFile(firebase, image);
    console.log(props);

    await Products.create(db, {
      ...props,
      deleted: false,
      imageUrl: newImageUrl,
    });
  }

  static async updateWithImage(
    db: ExtendedFirestoreInstance,
    firebase: ExtendedFirebaseInstance,
    id: string,
    props: any,
    image: File
  ) {
    let product = await Products.find(db, id);

    if (product) {
      // Delete storage image
      if (image) {
        let oldImageUrl = product.props.imageUrl;

        if (oldImageUrl) {
          let imageRef = firebase.storage().refFromURL(oldImageUrl);
          await imageRef.delete();
        }

        const newImageUrl = await Products.createUrlForFile(firebase, image);
        props.imageUrl = newImageUrl;
      }

      await Products.update(db, id, {
        ...props,
      });
    }
  }

  static async reactivate(db: ExtendedFirestoreInstance, uid: string) {
    await Products.update(db, uid, { deleted: false });
  }

  static async deleteWithImage(db: ExtendedFirestoreInstance, uid: string) {
    await Products.update(db, uid, { deleted: true });
    /*
    let product = await Products.find(db, uid);
    if(alert){
      // Delete storage image
      let imageUrl = alert.props.imageUrl
      let imageRef = firebase.storage().refFromURL(imageUrl);
      await imageRef.delete();

      // Delete from db
      await db.collection("alerts").doc(uid).delete();
    } */
  }

  private static async createUrlForFile(
    firebase: ExtendedFirebaseInstance,
    file: File
  ) {
    // Create new File on storage and change its name to avoid conflict
    const newFile = new File(
      [file],
      Math.random().toString(36).substring(7) + file.name,
      { type: file.type }
    );
    const uploadedImage = await firebase.uploadFile("produtos", newFile);
    const newImageUrl =
      await uploadedImage.uploadTaskSnapshot.ref.getDownloadURL();

    return newImageUrl;
  }
}
