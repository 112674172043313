import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export type PaymentsIndexDatePickerType = {
  date: Date | null;
  setDate: (date: MaterialUiPickersDate) => void;
};

export default function PaymentsIndexDatePicker(
  props: PaymentsIndexDatePickerType
) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        variant="inline"
        openTo="year"
        views={["year", "month"]}
        label="Ano e Mês"
        value={props.date}
        onChange={props.setDate}
        style={{ width: "100%", marginTop: "20px" }}
      />
    </MuiPickersUtilsProvider>
  );
}
