import React from "react";
import { List } from "@material-ui/core";
import DailyShippingListItem from "../../atoms/driver/DailyShippingListItem";
import { DailyShippingProductData } from "../../../presenters/driver/DailyShippingPresenter";

export default function (props: { products: DailyShippingProductData[], allergics:{[key:string]:{name:string, quantity:number}[]} }) {
  return (
    <List>
      {props.products.map((data, index) => (
        <DailyShippingListItem
          image_url={data.image_url}
          name={data.name}
          index={index}
          total={data.total}
          intolerant_count={data.intolerant_count}
          flavor_name={data.flavor_name}
          have_any_intolerant={data.have_any_intolerant}
          associateds_with_allergie={props.allergics[data.name]}
        />
      ))}
    </List>
  );
}
