import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel from "./Application"

type NeighborhoodFields = {
  name: string,
  taxPlan: string,
  taxIndividual: string,
  color:string
}

export default class Neighborhood extends ApplicationModel {

    public static modelName = "neighborhood";

    constructor(
        public db: ExtendedFirestoreInstance,
        public props: NeighborhoodFields,
        public docRef: DocumentReference<DocumentData>
    ) {
        super(db, props, docRef)
    }

}
