import React from "react";
import { Divider, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

export type ModalListItemProps = {
  primary: string,
  secondary: number | string,
  alternative?:number,
  index: number
}

export default function ModalListItem(props:ModalListItemProps) {

  return (
    <div key={props.index}>
      <ListItem>
        <ListItemText primary={props.primary} secondary={props.alternative ? "Sabor alternativo: " +  props.alternative : ""}/>
        <ListItemSecondaryAction>
          {props.secondary}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
}
