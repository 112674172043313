import Associated from "../../entities/Associated";
import SuspendedDaysInteractor from "../SuspendendDaysInteractor";
import { addDays, isBefore } from "date-fns";
import { compareDeliveryDates } from "../../../utils/date_manipulation";
import DeliveryDaysInteractor from "./DeliveryDaysInteractor";

export type DeliveryTypes =
  | "nextDelivery"
  | "futureDelivery"
  | "suspended"
  | "alreadyReceived"
  | "nextSuspended"
  | "notReceive"
  | "combinedInfo";

export default class DeliveryTypeInteractor {
  static deliveryTypeOnDate(date: Date, associated: Associated): DeliveryTypes {
    if (
      SuspendedDaysInteractor.weekIsSuspended(date, associated) ||
      associated.deleted === true
    ) {
      return "suspended";
    }

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (isBefore(date, today)) return "alreadyReceived";

    if (associated.deliveryInfo.lastDeliveryDate) {
      if (
        compareDeliveryDates(associated.deliveryInfo.lastDeliveryDate, date)
      ) {
        return "alreadyReceived";
      }
    }

    try {
      let nextDeliveryDate =
        DeliveryDaysInteractor.getNextDeliveryDate(associated);

      // Associado deve receber essa semana e o dia de entrega ainda não chegou
      if (compareDeliveryDates(nextDeliveryDate, date)) return "nextDelivery";
    } catch (e) {}

    let todayNumber = today.getDay();

    let last_delivery_was_not_today: boolean = true;
    if (associated.deliveryInfo.lastDeliveryDate)
      last_delivery_was_not_today = !compareDeliveryDates(
        associated.deliveryInfo.lastDeliveryDate,
        today
      );

    if (
      associated.deliveryInfo.weekDay < todayNumber &&
      last_delivery_was_not_today &&
      DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(date, associated) &&
      compareDeliveryDates(date, addDays(today, 7)) // date eh semana que vem
    )
      return "nextDelivery";

    if (DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(date, associated))
      return "futureDelivery";

    return "notReceive";
  }
}
