import { addDays } from "date-fns";
import database from "../../../database/DataBaseConfig"
import DeliveryInfoInteractor from "../../../interactors/delivery/DeliveryInfoInteractor";
import PresenterInterface from "../../PresenterInterface"
import { formatProductListInfoArray } from "../../utils/product";

export default class MuralDeliveriesPresenter implements PresenterInterface{


  storage_path:string
  constructor(){
    this.storage_path = "/mural_essa_semana"
  }

  async getData(){
    let associateds = await database.associated_db.allActiveAssociateds();

    let this_week_info = await DeliveryInfoInteractor.deliveryInfoForAssociateds(associateds, new Date());
    let next_week_info = await DeliveryInfoInteractor.deliveryInfoForAssociateds(associateds, addDays(new Date(), 7))
    
    let this_week_prods = await formatProductListInfoArray(Object.values(this_week_info.products))
    let next_week_prods = await formatProductListInfoArray(Object.values(next_week_info.products))

    return {
      this_week_prods, next_week_prods
    }
  }

}