import ApplicationEntity from "../../../entities/ApplicationEntity";
import {db} from "../../../../services/Firebase"
import { injectReduxStore } from "../../../redux/backendStorage";

type model_constructorT = new(id:string, data:any) => ApplicationEntity

// ALL:
export async function findAllFirebase(collection_path:string, model_constructor:model_constructorT){
  let query = db.collection(collection_path)
  let response = await query.get();

  let ret_list:any[] = []
  response.docs.forEach(obj => {
    ret_list.push(new model_constructor(obj.ref.id, obj.data()))
  });

  return ret_list
}

export async function allWithRedux(collection_path:string, model_constructor:model_constructorT){
  return injectReduxStore(()=>{
    return findAllFirebase(collection_path, model_constructor);
  }, collection_path + "#all");
}