import { isSameWeek } from "date-fns";
import { normalizeDeliveryDate } from "../../utils/date_manipulation";
import Associated from "../entities/Associated";

export default class SuspendedDaysInteractor{
  static weekIsSuspended(date:Date, associated:Associated){
    let suspended_days = associated.suspendedDates || []
    let normalized_date = normalizeDeliveryDate(date)
    for(let suspended_day of suspended_days){
      if(isSameWeek(suspended_day, normalized_date)){
        return true;
      }
    }
    return false;
  }
}