import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Products from "../../services/db/Products";
import { useFirestore } from "react-redux-firebase";
import ProductInfo from "../../components/ProductInfo";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectInput from "../../components/inputs/SelectInput";
import firebase from "../../services/Firebase";
import { deleteStorage } from "../../app/redux/backendStorage";

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    marginTop: "100px",
    justifyContent: "center",
  },
  hText: {
    paddingTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rodapeComentary: {
    fontSize: "12px",
    color: "rgb(111 111 111)",
    margin: "10px auto",
  },
}));

const frequencyOptions = [
  { label: "Semanal", value: "weekly" },
  { label: "Quinzenal", value: "biweekly" },
];

function printFloat(f) {
  return parseFloat(f).toFixed(2).replace(".", ",");
}


const planNotification = firebase.functions().httpsCallable("sendUpdatePlanNotification");

export default function PlanProduct() {
  const db = useFirestore();
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const associated = useSelector((state) => state.backend.associated);

  let [loading, setLoading] = useState(true);
  let [product, setProduct] = useState({});
  let [quantity, setQuantity] = useState(0);
  let [frequency, setFrequency] = useState("weekly");

  let [alreadyOnPlan, setalreadyOnPlan] = useState(false);

  let { id } = params;

  useEffect(() => {
    const fetchData = async () => {
      const productFetched = await Products.find(db, id);

      const planItem = associated.planHelper.getPlanItem(
        productFetched.docRef.id
      );
      if (planItem) {
        setalreadyOnPlan(true);
        setQuantity(planItem.quantity);
        setFrequency(planItem.frequency);
      }
      setProduct(productFetched);
      setLoading(false);
    };

    fetchData();
  }, [db, id, history, associated]);



  async function handleRemover() {
    await associated.planHelper.remove(product.docRef.id);
    deleteStorage("associated/"+associated.docRef.id)
    history.goBack();
  }

  yup.addMethod(yup.number, "validQuantity", function (errorMessage) {
    return this.test(`test-quantity-validity`, errorMessage, function (value) {
      const { path, createError } = this;
      return true;
      return (
          (value <= product.props.stock) ||
          createError({ path, message: errorMessage })
      );
    });
  });

  const schema = yup.object().shape({
    quantity: yup
        .number().validQuantity('Não temos essa quantidade em estoque no momento')
        .required("Quantidade é obrigatória")
        .min(1, "Adicione pelo menos 1 item"),
    frequency: yup.string().required("Frequencia é obrigatória"),
  });

  const { register, handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
  });


  const submitHandler = async ({ quantity, frequency }) => {
    setLoading(true);
    await associated.planHelper.add(product.docRef.id, quantity, frequency);
    try {
      await planNotification({
        uid: associated.docRef.id,
      });
    }catch (e) {
      console.log(e)
    }

    deleteStorage("associated/"+associated.docRef.id)
    history.goBack();
  };

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.hText}>
      <ProductInfo product={product} />
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <TextField
            inputRef={register}
            variant="outlined"
            id="quantity"
            margin="normal"
            label="Quantidade"
            name="quantity"
            required
            fullWidth
            type="number"
            onChange={(ev) => setQuantity(ev.target.value)}
            defaultValue={quantity}
            error={!!errors.quantity}
            helperText={errors.quantity?.message}
          />
          {/* <ControlledAutocomplete
            id="combo-box-demo"
            options={["weekly", "biweekly"]}
            control={control}
            name="frequency"
            getOptionLabel={(option)=> option === "weekly" ? "Semanal" : "Quinzenal"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Frequência"
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!errors.frequency}
                helperText={errors.frequency?.message}
              />
            )}
            onOptionChange={(f)=>setFrequency(f)}
            defaultValue={frequency}
          /> */}
          <SelectInput
            required
            control={control}
            options={frequencyOptions}
            label="Frequência"
            name="frequency"
            error={!!errors.frequency}
            helperText={errors.frequency?.message}
            defaultValue={frequency}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ margin: "auto" }}>Total Mensal:</div>
              <div style={{ margin: "auto 10px auto 0px" }}>
                R${" "}
                {printFloat(
                  Products.getMonthlyPrice(product.props, quantity, frequency)
                )}
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                style={{ fontSize: "12px" }}
                disabled={loading}
              >
                {alreadyOnPlan ? "Atualizar Plano" : "Quero"}
              </Button>
            </div>
            {alreadyOnPlan && (
              <div className={classes.rodapeComentary} onClick={handleRemover}>
                Suspender Item
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
