import React, { useState} from "react";
import {  useLocation } from "react-router-dom";

import usePresenter from "../../../app/redux/usePresenter";
import IndexAssociatedReceiptPresenter from "../../../app/presenters/admin/associated/IndexAssociatedReceiptPresenter";
import ReceiptList from "../../../app/components/templates/admin/associated/ReceiptList";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Button,
  InputAdornment,
  Container
} from '@material-ui/core';
import PriceMaskedInput from "../../../components/inputMasks/PriceMaskedInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingCircle from "../../../app/components/atoms/LoadingCircle";

function IndexAssociatedReceipt() {
  const location = useLocation();
  const { id } = location.state ? location.state : {};

  const presenter_data = usePresenter(new IndexAssociatedReceiptPresenter(id)) || {};
  if(Object.keys(presenter_data).length === 0) return <></>
  
  const credits = presenter_data.credits || {}
  return (<div>
    <ReceiptList {...presenter_data.receipt_list_props} />

    <Divider />
    <Container>
      <h3> Visão Geral </h3>
      <Divider />
      <Container>
        <List>
          <ListItem>
            <ListItemText 
              primary={"Último Pagamento Realizado"}
            />
            <ListItemSecondaryAction>
              R$ {credits.paid}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary={"Saldo utilizado esse mês"}
            />
            <ListItemSecondaryAction>
              R$ {credits.utilized}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText 
              primary={"Saldo Total"}
            />
            <ListItemSecondaryAction>
              R$ {credits.total}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Container>
    </Container>

    <Divider />
    <CreditsForm associatedId={id} defaultValue={0} />
  </div>
  );
}

export default IndexAssociatedReceipt;

function CreditsForm({associatedId, defaultValue}) {
  let [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    price: yup.string().required("Preço é obrigatório").trim()
  });

  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = async (form_info) => {
    let value = Number(form_info.price);
    setLoading(true);
    await IndexAssociatedReceiptPresenter.createCorrection(associatedId, value);
    setLoading(false);
  }

  if(loading){return <LoadingCircle show={true}/>}
  return(
    <Container>
      <h3> Registrar pagamento realizado pelo associado </h3>
      
      <form onSubmit={handleSubmit(submitHandler)} >
        <TextField 
          inputRef={register}
          error={!!errors.price}
          helperText={errors.price?.message}
          type="number"
          id="filled-secondary" 
          label="Valor do Pagamento" 
          variant="filled" 
          fullWidth={true} 
          color="primary"
          name="price"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
            inputComponent: PriceMaskedInput,
            defaultValue: "0.00",
          }}
        />
        <Button 
          type="submit"
        > Registrar </Button>
      </form>
    </Container>
  )
}