import database from "../../../database/DataBaseConfig";
import Associated, {DeliveryInfoProp} from "../../../entities/Associated";
import PresenterInterface from "../../PresenterInterface";
import { db } from "../../../../services/Firebase";
import DriverOrderInteractor from "../../../interactors/driver/DriverOrderInteractor";
import { deleteStorage } from "../../../redux/backendStorage";
import AssociatedFirebase from "../../../database/firebase/AssociatedFirebase";

type ProductSelect = {
  name: string;
  id: string;
  image_url: string;
};

type NeighborhoodSelect = {
  label: string;
  value: string;
};

type FlavorOptions = {
  name: string;
  id: string;
};

type ProductFormInfo = {
  confirmed: boolean;
  quantity: number;
  frequency: "weekly" | "biweekly" | null;
  productId: string;
};

type IntoleranceFormInfo = {
  confirmed: boolean;
  flavorId: string;
};

export type AssociatedFormValues = {
  displayName: string;
  neighborhoodId: string;
  deliveryAddress: string;
  image_url: string;
  cpf: string;
  cep: string;
  phone: string;
  email: string;
  address: string;
  birthdate: string;
  entry_date: string;
  observation: string;
  password: "";
  delivery_observation: string;
  deliveryInfo:{
    baseDeliveryDate: string;
  }

  paymentPlan: ProductFormInfo[];
  individualPurchases: ProductFormInfo[];
  intolerances: IntoleranceFormInfo[];
};

export type EditAssociatedFormProps = {
  id?: string;
  deleted: boolean | undefined;
  planProducts: ProductSelect[];
  individualProducts: ProductSelect[];
  neighborhoods: NeighborhoodSelect[];
  flavors: FlavorOptions[];
  defaultValues?: AssociatedFormValues;
};

type ProductFormField = {
  quantity: number;
  frequency?: "weekly" | "biweekly";
  confirmed: boolean;
  productId: string;
};

type FormFields = {
  displayName: string;
  neighborhoodId: string;
  deliveryAddress: string;
  paymentPlan: ProductFormField[];
  individualPurchases: ProductFormField[];

  deliveryInfo:{
    baseDeliveryDate?:string
  }

  cpf: string;
  cep: string;
  phone: string;
  email: string;
  address: string;
  delivery_observation?: string;
  password?: string;
  observation: string;
  profile_image?: any;
};
export default class EditAssociatedPresenter implements PresenterInterface {
  storage_path: string;
  constructor(private associated_id: string) {
    this.storage_path = "/admin/associated/edit/" + associated_id;
  }

  async getData(): Promise<EditAssociatedFormProps> {
    let prods = await this.getProducts();
    let neighbors = await this.getNeighborhoods();
    let flavors = await this.getFlavors();
    let associated = await database.associated_db.find(this.associated_id);
    if (!this.associated_id || !associated)
      return { deleted: undefined, ...prods, ...neighbors, flavors };

    return {
      id: associated.id,

      ...prods,
      ...neighbors,
      flavors,
      deleted: associated.deleted,
      defaultValues: {
        displayName: associated.displayName,
        neighborhoodId: associated.neighborhoodId,
        deliveryAddress: associated.deliveryAddress,
        deliveryInfo:{ baseDeliveryDate: associated.deliveryInfo.baseDeliveryDate ?
              associated.deliveryInfo.baseDeliveryDate.toISOString().slice(0, 10)
              : "",
        },
        image_url: "",
        delivery_observation: associated.delivery_observation || "",
        cpf: associated.cpf,
        cep: associated.cep,
        phone: associated.phone,
        email: associated.email,
        address: associated.address,
        birthdate: associated.birthdate
          ? associated.birthdate.toISOString().slice(0, 10)
          : "",
        entry_date: associated.entry_date
          ? associated.entry_date.toISOString().slice(0, 10)
          : "",
        observation: associated.observation,
        password: "",
        paymentPlan: associated.paymentPlan.map((prod) => {
          return {
            confirmed: true,
            ...prod,
          };
        }),
        individualPurchases: associated.individualPurchases.map((prod) => {
          return {
            confirmed: true,
            quantity: prod.quantity,
            productId: prod.productId,
            frequency: null,
          };
        }),
        intolerances: (associated.intolerances || []).map((intole) => {
          return {
            ...intole,
            confirmed: true,
          };
        }),
      },
    };
  }

  async getFlavors(): Promise<FlavorOptions[]> {
    return await database.flavor_db.all();
  }

  async getNeighborhoods(): Promise<{ neighborhoods: NeighborhoodSelect[] }> {
    let allNeighbors = await database.neighborhood_db.all();
    return {
      neighborhoods: allNeighbors.map((neighbor) => {
        return {
          label: neighbor.name,
          value: neighbor.id,
        };
      }),
    };
  }
  async getProducts(): Promise<{
    planProducts: ProductSelect[];
    individualProducts: ProductSelect[];
  }> {
    let allProducts = await database.product_db.allActive();

    return {
      planProducts: allProducts
        .filter((prod) => !prod.individual)
        .map((prod) => {
          return {
            name: prod.name,
            id: prod.id,
            image_url: prod.imageUrl,
          };
        }),
      individualProducts: allProducts
        .filter((prod) => prod.individual)
        .map((prod) => {
          return {
            name: prod.name,
            id: prod.id,
            image_url: prod.imageUrl,
          };
        }),
    };
  }

  static async updateAssociated(id: string, params: FormFields) {
    let associated = new Associated(id, params);
    if (!associated) return;

    // change associated values
    // verify if they`re correct


    // Delete un-modifyable fields
    delete params.profile_image;
    delete associated.credits;
    delete associated.suspendedDates;

    // Set array to empty if null is sent
    associated.paymentPlan = associated.paymentPlan || [];
    associated.individualPurchases = associated.individualPurchases || [];
    associated.intolerances = associated.intolerances || [];


    let removedBlank = Object.fromEntries(
      Object.entries(associated).filter(([_, v]) => v != null)
    );
    await database.associated_db.updateDeliveryInfo(id, params.deliveryInfo as DeliveryInfoProp)
    await database.associated_db.update(id, removedBlank as Associated);
  }

  static async inactivateAssociated(id: string) {
    await database.associated_db.inactivateAssociated(id);
    await DriverOrderInteractor.removeAssociatedFromDeliveries(id!);
    deleteStorage("associated/" + id);
  }

  static async activateAssociated(id: string) {
    await database.associated_db.activateAssociated(id);

    deleteStorage("associated/" + id);
  }
}
