import React, { useCallback, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";

import PriceMaskedInput from "../../components/inputMasks/PriceMaskedInput";

import { useHistory, useLocation } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Neighborhood from "../../services/db/Neighborhood";
import { TwitterPicker } from "react-color";

const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório").trim(),
  taxIndividual: yup.string().required("Frete é obrigatório").trim(),
  taxPlan: yup.string().required("Frete é obrigatório").trim(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

function CreateNeighborhood() {
  const db = useFirestore();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState("#000");
  const [defaultName, setDefaultName] = useState(
    location.state ? location.state.props.name : ""
  );
  // const [taxType, setTaxType] = useState(0)
  // const [tax, setTax] = useState(location.state ? location.state.props.tax || 0 : "")

  const taxPlan = location.state ? location.state.props.taxPlan || "0" : "";
  const taxIndividual = location.state
    ? location.state.props.taxIndividual || "0"
    : "";
  const classes = useStyles();
  const protectedNeighbor = location.state
    ? location.state.uid === "SemBairro"
    : false;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const createNewData = useCallback(
    async (formProps) => {
      // Cria o bairro apenas se o nome não existir.
      // Outra possibilidade é utilizar o id dos documentos como o nome,
      // já que deve ser único. (fazer controle por regras do firestore)
      const neighborhoodAlreadyExists = await db
        .collection("neighborhood")
        .where("name", "==", formProps.name)
        .get();


      if (neighborhoodAlreadyExists.size === 0) {
        Neighborhood.create(db, { ...formProps, color });
      } else {
        if(location.state){
          let uid = location.state.uid;
          Neighborhood.update(db, uid, { ...formProps, color });
        }

      }
    },
    [color, db, location.state]
  );

  const submitHandler = async (neighbor) => {
    setLoading(true);
    try {
      await createNewData(neighbor);
      history.push("/admin/neighborhood");
    } catch (error) {
      //console.log(error);
      alert(`Erro ao criar Bairro: ${error.message}`);
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    if (location.state) {
      if (protectedNeighbor) return;
      let uid = location.state.uid;
      await Neighborhood.delete(db, uid);
      history.goBack();
    }
  };

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={protectedNeighbor}
            id="name"
            label="Nome"
            name="name"
            autoComplete="name"
            defaultValue={defaultName}
            type="text"
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={protectedNeighbor}
            id="taxPlan"
            label="Frete - Plano"
            name="taxPlan"
            autoComplete="taxPlan"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              inputComponent: PriceMaskedInput,
              inputProps: { defaultValue: taxPlan || "0.00" },
            }}
            error={!!errors.taxPlan}
            helperText={errors.taxPlan?.message}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={protectedNeighbor}
            id="taxIndividual"
            label="Frete - Avulso"
            name="taxIndividual"
            autoComplete="taxIndividual"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              inputComponent: PriceMaskedInput,
              inputProps: { defaultValue: taxIndividual || "0.00" },
            }}
            error={!!errors.taxIndividual}
            helperText={errors.taxIndividual?.message}
          />

          <p>
            <label>Cor do Bairro</label>
          </p>
          <TwitterPicker
            color={color}
            onChangeComplete={(newcolor) => {
              setColor(newcolor.hex);
            }}
          />

          <div className={classes.submitWrapper}>
            {defaultName ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Atualizar Bairro
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Criar Bairro
              </Button>
            )}
            {defaultName && !protectedNeighbor && (
                <div
                    style={{
                      fontSize: "14px",
                      color: "rgb(222 111 111)",
                      margin: "2px auto",
                      fontWeight: 600,
                      cursor: "pointer",
                      textAlign: "center"
                    }}
                    onClick={deleteHandler}
                >
                  Excluir Bairro
                </div>
            )}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default CreateNeighborhood;
