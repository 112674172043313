import database from "../../database/DataBaseConfig";
import Associated from "../../entities/Associated";
import Associated_Delivery, {
  DeliveredProduct,
} from "../../entities/Associated_Delivery";
import Product from "../../entities/Product";
import AssociatedInteractor from "../AssociatedInteractor";
import DeliveryHistoryInteractor from "./DeliveryHistoryInteractor";
import DeliveryInfoInteractor, {
  ProductOnDelivery,
} from "./DeliveryInfoInteractor";
import associated from "../../../routes/associated";

export type ProductToReturn = {
  required: boolean;
} & ProductOnDelivery;

export default class DeliveryInteractor {
  static async shouldReceiveToday(
    associated_id: string
  ): Promise<ProductOnDelivery[]> {
    let associated = await database.associated_db.find(associated_id);
    let today_date = new Date();
    if (!associated) return [];

    let info = await DeliveryInfoInteractor.deliveryInfoOnDate(
      today_date,
      associated
    );

    if (info.deliveryType === "nextDelivery") {
      return Object.values(info.products);
    } else if (info.deliveryType === "alreadyReceived") {
      let full_info = await DeliveryInfoInteractor.getFullDeliveryInfo(
        today_date,
        associated
      );
      let full_delivery_products = Object.values(full_info.products);

      let history = await DeliveryHistoryInteractor.deliveryHistoryOnWeek(
        associated_id,
        today_date
      );
      if (history) {
        return await mergeDeliveryWithHistory(
          full_delivery_products,
          history,
          associated
        );
      }
    }
    return [];
  }

  static combineProductOnDelivery(
    src: ProductOnDelivery[],
    dest: ProductOnDelivery[]
  ): ProductOnDelivery[] {
    for (let product of src) {
      let index = dest.findIndex((productF) => productF.id === product.id);
      if (index !== -1) {
        dest[index].total += product.total;
        dest[index].intolerants += product.intolerants;
      } else {
        dest.push(product);
      }
    }
    return dest;
  }

  static async getReceivedToday(
    associated_id: string
  ): Promise<ProductToReturn[]> {
    let associated = await database.associated_db.find(associated_id);
    let today_date = new Date();
    if (!associated) return [];

    let info = await DeliveryInfoInteractor.deliveryInfoOnDate(
      today_date,
      associated
    );
    let return_products = await pendingItemsToProductToReturn(associated);
    if (info.deliveryType === "nextDelivery") {
      return return_products;
    } else if (info.deliveryType === "alreadyReceived") {
      let history = await DeliveryHistoryInteractor.deliveryHistoryOnWeek(
        associated_id,
        today_date
      );
      if (!history) return return_products;
      return this.formatHistoryToProductToReturn(history, associated);
    }
    return return_products;
  }

  static async shouldReturnToday(
    associated_id: string
  ): Promise<ProductToReturn[]> {
    let associated = await database.associated_db.find(associated_id);
    let today_date = new Date();
    if (!associated) return [];

    let info = await DeliveryInfoInteractor.deliveryInfoOnDate(
      today_date,
      associated
    );
    let return_products = await pendingItemsToProductToReturn(associated);
    if (info.deliveryType === "nextDelivery") {
      return return_products;
    } else if (info.deliveryType === "alreadyReceived") {
      let history = await DeliveryHistoryInteractor.deliveryHistoryOnWeek(
        associated_id,
        today_date
      );
      if (!history) return return_products;
      return_products = await addHistoryToProductToReturn(
        history.returnedProducts,
        return_products,
        associated
      );
      return_products = await addHistoryToProductToReturn(
        history.broken,
        return_products,
        associated
      );
      return_products = await removeInformationOfDeliveryWithRequiredProducts(
        history.products,
        return_products
      );
      return return_products;
    }
    return return_products;
  }

  static async formatHistoryToProductToReturn(
    history: Associated_Delivery,
    associated: Associated
  ) {
    let ret_array: ProductToReturn[] = [];

    for (const item of history.returnedProducts) {
      let product = await database.product_db.find(item.productId);
      if (!product) continue;
      ret_array.push({
        ...(await transformProductToProductOnDelivery(
          product,
          associated,
          item.quantity
        )),
        required: product.returnable === "Sim" || false,
      });
    }
    return ret_array;
  }

  static combineProductToReturn(
    src: ProductToReturn[],
    dest: ProductToReturn[]
  ): ProductToReturn[] {
    for (let product of src) {
      let index = dest.findIndex((productF) => productF.id === product.id);
      if (index !== -1) {
        dest[index].total += product.total;
        dest[index].intolerants += product.intolerants;
      } else {
        dest.push(product);
      }
    }
    return dest;
  }
}

async function pendingItemsToProductToReturn(
  associated: Associated
): Promise<ProductToReturn[]> {
  let pendingItems = associated.deliveryInfo.pendingItems;
  if (!pendingItems) return [];
  let ret_array: ProductToReturn[] = [];
  for (let item of pendingItems) {
    let product = await database.product_db.find(item.productId);
    if (!product) continue;
    ret_array.push({
      ...(await transformProductToProductOnDelivery(
        product,
        associated,
        item.quantity
      )),
      required: item.required || false,
    });
  }
  return ret_array;
}

async function addHistoryToProductToReturn(
  returned_prods: DeliveredProduct[],
  pending: ProductToReturn[],
  associated: Associated
): Promise<ProductToReturn[]> {
  for (let history_prod of returned_prods) {
    let product = await database.product_db.find(history_prod.productId);
    if (!product) continue;

    let index = pending.findIndex((prod) => prod.id === history_prod.productId);
    if (index === -1) {
      pending.push({
        ...(await transformProductToProductOnDelivery(
          product,
          associated,
          history_prod.quantity
        )),
        required: product.returnable !== "Não",
      });
    } else {
      pending[index].total += history_prod.quantity;
      if (pending[index].intolerant) {
        pending[index].intolerants += history_prod.quantity;
      }
    }
  }
  return pending;
}
async function removeInformationOfDeliveryWithRequiredProducts(
  delivered_prods: DeliveredProduct[],
  pending: ProductToReturn[]
) {
  for (let delivered_prod of delivered_prods) {
    let product = await database.product_db.find(delivered_prod.productId);
    if (!product) continue;
    if (product.returnable !== "Sim") continue;
    let index = pending.findIndex(
      (prod) => prod.id === delivered_prod.productId
    );
    if (index === -1) continue;
    pending[index].total -= delivered_prod.quantity;
  }
  return pending.filter((prod) => prod.total > 0);
}

async function mergeDeliveryWithHistory(
  delivery_products: ProductOnDelivery[],
  history: Associated_Delivery,
  associated: Associated
) {
  for (let product_delivered of history.products) {
    let product = await database.product_db.find(product_delivered.productId);
    if (!product) continue;
    if (product.individual) {
      let added = false;
      delivery_products = delivery_products.reduce((acc: any, prod, idx) => {
        if (prod.id === product_delivered.productId) {
          added = true;
          prod.total += product_delivered.quantity;
          if (prod.intolerant) prod.intolerants += product_delivered.quantity;
        }
        acc.push(prod);
        return acc;
      }, []);
      if (!added)
        delivery_products.push(
          await transformProductToProductOnDelivery(
            product,
            associated,
            product_delivered.quantity
          )
        );
    }
  }
  return delivery_products;
}

async function transformProductToProductOnDelivery(
  product: Product,
  associated: Associated,
  quantity: number
): Promise<ProductOnDelivery> {
  let is_intolerant = AssociatedInteractor.associatedIsIntolerantTo(
    associated,
    product.flavorId
  );
  return {
    ...product,
    intolerant: is_intolerant,
    total: quantity,
    flavorName: await AssociatedInteractor.getFlavorNameForProduct(
      associated,
      product
    ),
    intolerants: is_intolerant ? quantity : 0,
  };
}
