import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { ModelDict } from "../../Application";
import Associated, { PaymentPlanItem } from "../../Associated";
import { getWeekOfMonth } from "date-fns";
import Products from "../../Products";
import { shouldAssociatedReceiveDay } from "../../../../utils/associated_delivery_days";

export default class PlanHelper {
  constructor(
    public db: ExtendedFirestoreInstance,
    public associated: Associated
  ) {}

  public async add(
    product_id: string,
    quantity: number,
    frequency: "weekly" | "biweekly"
  ) {
    let alreadyOnPlan = false;
    let items = this.associated.props.paymentPlan;

    for (let i = 0; i < items.length; i++) {
      if (product_id === items[i].productId) {
        items[i].quantity = Number(quantity);
        items[i].frequency = frequency;
        alreadyOnPlan = true;
        break;
      }
    }

    if (!alreadyOnPlan) {
      items.push({
        productId: product_id,
        quantity: Number(quantity),
        frequency,
      });
    }
    await this.update(items);
  }

  public async planPriceOnMonth(
    month: number,
    allProducts: ModelDict<Products> | undefined
  ) {
    await Associated.awaitForAssociations([this.associated]);
    if (!allProducts) allProducts = await Products.all(this.db);
    let numberOfDeliveries = this.calculateNumberOfDeliveriesInMonth(month);
    let totalPrice = 0;
    let frete = Number(this.associated.neighborhood.props.taxPlan);

    for (let info of this.associated.props.paymentPlan) {
      let productPrice = Number(allProducts[info.productId].props.price);

      if (info.frequency === "weekly")
        totalPrice += productPrice * info.quantity * numberOfDeliveries;
      else
        totalPrice +=
          productPrice * info.quantity * Math.round(numberOfDeliveries / 2);
    }

    totalPrice += frete * numberOfDeliveries;
    return totalPrice;
  }

  public async planPriceOnMonthWithSuspendedDays(
    month: number,
    allProducts: ModelDict<Products> | undefined
  ) {
    await Associated.awaitForAssociations([this.associated]);

    if (!allProducts) allProducts = await Products.all(this.db);
    let deliveries = this.returnDeliveriesInMonth(month);
    let totalPrice = 0;
    let frete = Number(this.associated.neighborhood.props.taxPlan);
    // isso retorna apenas o primeiro dia da semana da entrega suspendida
    let suspended = await this.associated.suspendedDays.getSuspendedDays();

    let suspendedWeeks = suspended.map((day: any) => {
      return getWeekOfMonth(day);
    });
    deliveries = deliveries.filter((item) => {
      return !suspendedWeeks.includes(getWeekOfMonth(item));
    });
    console.log(deliveries);

    for (let i = 0; i < deliveries.length; i++) {
      for (let info of this.associated.props.paymentPlan) {
        let productPrice = Number(allProducts[info.productId].props.price);

        totalPrice += productPrice * info.quantity;
      }
    }
    totalPrice += frete * deliveries.length;

    return totalPrice;
  }

  public async remove(product_id: string) {
    let items = this.associated.props.paymentPlan.filter(
      (item) => item.productId !== product_id
    );
    await this.update(items);
  }

  public getPlanItem(product_id: string): PaymentPlanItem | undefined {
    for (let planItem of this.associated.props.paymentPlan) {
      if (product_id === planItem.productId) {
        return planItem;
      }
    }
    return undefined;
  }

  public async getAllPlanItems() {
    const products = await Products.all(this.db);

    return this.associated.props.paymentPlan.map((planItem) => {
      return {
        ...planItem,
        ...products[planItem.productId],
      };
    });
  }

  private async update(newItems: PaymentPlanItem[]) {
    await Associated.update(this.db, this.associated.docRef.id, {
      paymentPlan: newItems,
    });
    this.associated.props.paymentPlan = newItems;
  }

  private returnDeliveriesInMonth(month: number) {
    let today = new Date();
    let year = today.getFullYear();
    let iteratorDay = 1;
    let listOfDays = [];
    let iteratorDate = new Date(year, month, iteratorDay);

    let numberOfDeliveries = 0;
    while (iteratorDate.getMonth() === month) {
      if (shouldAssociatedReceiveDay(iteratorDate, this.associated.props)) {
        numberOfDeliveries += 1;
        listOfDays.push(iteratorDate);
      }
      iteratorDay += 1;
      iteratorDate = new Date(year, month, iteratorDay);
    }

    return listOfDays;
  }

  private calculateNumberOfDeliveriesInMonth(month: number) {
    let today = new Date();
    let year = today.getFullYear();
    let iteratorDay = 1;
    let iteratorDate = new Date(year, month, iteratorDay);

    let numberOfDeliveries = 0;
    while (iteratorDate.getMonth() === month) {
      if (iteratorDate.getDay() === this.associated.getDeliveryDay()) {
        // Sun=0, Mon=1, Tue=2, etc.
        numberOfDeliveries += 1;
      }
      iteratorDay += 1;
      iteratorDate = new Date(year, month, iteratorDay);
    }

    return numberOfDeliveries;
  }
}
