import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel from "./Application"
import Associated from  './Associated'
import Products from './Products'
import { getMonth, addDays } from "date-fns"

type ReceiptFields = {
    file: string,
    paid_value: number,
    associated_ref: string,
    date: string,
    status: string
}

export default class Receipt extends ApplicationModel {

    public static modelName = "receipt";

    constructor(
        public db: ExtendedFirestoreInstance,
        public props: ReceiptFields,
        public docRef: DocumentReference<DocumentData>
    ) {
        super(db, props, docRef)
    }


    static async individualInfo(associated: Associated, date: Date, db: ExtendedFirestoreInstance) {
        const billsInfo = await Receipt.billsInfoCreator(associated, db, "avulso")
        return billsInfo
    }

    static async additionalInfo(associated: Associated, date: Date, db: ExtendedFirestoreInstance) {
        const billsInfo = await Receipt.billsInfoCreator(associated, db, "additionals")
        return billsInfo
    }

    static async discountInfo(associated: Associated, date: Date, db: ExtendedFirestoreInstance) {
        const billsInfo = await Receipt.billsInfoCreator(associated, db, "discounts")
        return billsInfo
    }



    static async billsInfoCreator(associated : Associated, db: ExtendedFirestoreInstance, type: String ){

        const bills =  db.collection("associated/"+ associated.docRef.id+"/bill").where("type", "==",type)
        const data = await bills.get()
        const bill_list = await Promise.all(data.docs.map(async(element:any )=>{return element.data()}))
        const new_list:any = []
        let value: Number = 0

        bill_list.forEach(element => {

            let index = new_list.findIndex((item:any)=>item.name === element.name)
            if (index === -1){
                new_list.push({...element, quantity: element.price.quantity, dates:[element.date]})
            }else{
                new_list[index].quantity += element.price.quantity
                if (!new_list[index].dates.find((date:any)=>date === element.date)) {
                    new_list[index].dates.push(element.date)
                }
            }
        });


        value = bill_list.reduce((acc : Number, element : any)=>{return acc + element.price.total},0)
        return {list:new_list, value: value}
    }


}
