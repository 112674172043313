import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom";
import {Container, Button,Checkbox, FormControlLabel} from '@material-ui/core'
import Loading from '../components/Loading'
import { useSelector }  from 'react-redux';
import { useForm } from "react-hook-form";
import {useFirebase, useFirestore} from "react-redux-firebase";

import {  useDispatch } from "react-redux";
import Associated from "../services/db/Associated"

export default function TermView() {
    const [loading, setLoading] = useState(true)
    let [fileUrl, setFileUrl] = useState("")
    const dispatch = useDispatch()
    const firebase = useFirebase()
    const db = useFirestore()
    const history = useHistory()
    const termUrl = "termo/termo.pdf"
    const termPath = "termo/"
    const storageRf = firebase.storage().ref();
    const termRef = storageRf.child(termUrl)

    const associated    = useSelector((state) => state.backend.associated);
    const user = useSelector((state)=> state.firebase.auth)
    const associated_id = user.uid
    useEffect(() => {

        const lista = async () => {
            setLoading(true)
            setFileUrl(await termRef.getDownloadURL())

            setLoading(false)
        }
        lista()
    }, [firebase])


    const { register, handleSubmit, errors} = useForm();

    const submitHandler = async (form) =>{
        const accept = form.accept

        if (accept) {
            console.log(associated)
            await Associated.update(db, associated_id, {acceptedTerm: true} )
            const associated_firestore = await Associated.find(db, associated_id)
            dispatch({type: "app/associated/update", payload: associated_firestore})
            alert("Seja bem vindo :)")
            history.push("/associated/mural")
        } else {
            alert("É necessário aceitar o termo para fazer parte do grupo");
        }

    }

    if (loading) {
        return (<Loading/>)
    }

    return (<Container>
        <iframe src={fileUrl} style={{
                width: "100%",
                height: window.innerHeight - 200,
                marginTop: "20px"
            }}/>
        <Container>
            <div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <FormControlLabel
                  name="accept"
                  control={<Checkbox />}
                  label="Aceito os termos descritos na tela atual"
                  inputRef={register}
                  labelPlacement="end"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  "Enviar"
                </Button>
            </form>
            </div>
        </Container>
    </Container>)

}
