import React from 'react';
import RecoverPasswordCard from '../app/components/organisms/RecoverPasswordCard';
import LoginTemplate from '../app/components/templates/LoginTemplate';

// import { Container } from './styles';

function RecoverPassword() {
  return (
    <LoginTemplate >
      <RecoverPasswordCard />
    </LoginTemplate>
  );
}

export default RecoverPassword;