import React from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button           from '@material-ui/core/Button';
// import CardActions      from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 140,
  },
});


function ProductInfo({ product }) {
  const classes = useStyles();

  return !product ? (
    <div />
  ) : (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={product.props?.imageUrl}
          alt={product.props?.name}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {product.props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {product.props.description}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            R$ {parseFloat(product.props.price).toFixed(2).replace(".", ",")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ProductInfo;
