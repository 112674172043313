import {  Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


import { useFirestore } from 'react-redux-firebase';

import Driver from '../../services/db/Driver';

import { useHistory }    from 'react-router-dom';
import { ArrowForwardIos } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  background:{
    backgroundColor: "#EBEBEB"
  },
  hText:  {
    margin          : theme.spacing(2),
    backgroundColor: "#EBEBEB"
  },
}));

export default function DriverIndex() {
  const db      = useFirestore();
  const classes = useStyles();
  const history = useHistory();
  let [query, setQuery] = useState("");

  const [drivers, setDrivers] = useState([])
  const [driversQ, setDriversQ] = useState([])
  useEffect(()=>{
    (async () =>{
      const newDriverList = await Driver.getDriversListInfo(db);
      setDrivers(newDriverList)
      setDriversQ(newDriverList)
    })()
  },[db])

  const handleChange = (evt) => {
    let newValue = evt.target.value;
    setQuery(newValue);
    setDriversQ(
      drivers.filter((driver) =>
        driver.primary.toLowerCase().startsWith(newValue.toLowerCase())
      )
    );
  };

  return <div>
    <TextField
      id="filled-secondary"
      label="Procurar"
      variant="filled"
      fullWidth={true}
      value={query}
      onChange={handleChange}
      color="primary"
    />
    <List>
      {driversQ.map((driver) => {
        return (
          <div key={driver.id}>
            <ListItem
              onClick={() => {}}
            >
              <ListItemText
                primary={driver.primary}
                secondary={driver.secondary}
                secondaryTypographyProps={{ noWrap: true }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    history.push("/admin/driver/deliveries/"+driver.id);
                  }}
                >
                  <ArrowForwardIos />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })}
    </List>
  </div>;
}
