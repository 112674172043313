import AppConfigs from "../../../entities/AppConfigs";
import { getAllAppConfigs, updateConfigLocally, updateConfigs } from "../../../interactors/AppConfigsInteractor";
import PresenterInterface from "../../PresenterInterface";

export type PresenterData = {
  entry_tax: number | undefined
}


export default class EditAppConfigsPresenter implements PresenterInterface{
  storage_path:string
  constructor(private associated_id:string){
    this.storage_path = "/admin/configs/app_config/";
  }

  async getData(): Promise<PresenterData>{
    let configs = await getAllAppConfigs() || {};
    return {
      entry_tax: configs.entry_tax || 0
    }
  }

  static async updateAppConfig(data: PresenterData){
    updateConfigs(data as AppConfigs);
  }
}