import Associated from "../../entities/Associated";
import { addDays } from "date-fns";
import { subDays } from "date-fns/esm";
import SuspendedDaysInteractor from "../SuspendendDaysInteractor";
import {
  compareDeliveryDates,
  daysBetween,
  normalizeDeliveryDate,
} from "../../../utils/date_manipulation";
import AssociatedInteractor from "../AssociatedInteractor";

export default class DeliveryDaysInteractor {
  static shouldAssoicatedReceiveOnDate(date: Date, associated: Associated) {
    if (
      DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(date, associated)
    ) {
      return date.getDay() === associated.deliveryInfo.weekDay;
    } else return false;
  }

  /**
   *  Funcao para verificar se associado tem algum item em sua entrega em data
   *  especifica.
   */
  static shouldAssociatedReceiveOnWeek(date: Date, associated: Associated) {
    let individual_items = associated.individualPurchases || [];
    let payment_plan = associated.paymentPlan || [];

    if (associated.deleted === true) return false;
    if (AssociatedInteractor.weekIsSuspended(associated, date)) return false;

    // Se nenhum item precisar ser entregue
    if (payment_plan.length === 0 && individual_items.length === 0)
      return false;

    // Caso tenha somente itens avulsos
    if (payment_plan.length === 0 && individual_items.length !== 0) {
      let last_delivery_date = associated.deliveryInfo.lastDeliveryDate;
      let next_delivery_date = new Date();
      if (last_delivery_date) {
        if (compareDeliveryDates(new Date(), last_delivery_date)) {
          next_delivery_date = addDays(next_delivery_date, 7);
        }
      }
      return compareDeliveryDates(date, next_delivery_date);
    }

    // Se o plano tiver item semanal ele com certeza ira receber
    if (DeliveryDaysInteractor.planHasFrequency(payment_plan, "weekly"))
      return true;

    return DeliveryDaysInteractor.shouldReceiveBiweeklyProductOnWeek(
      date,
      associated
    );
  }

  static shouldReceiveBiweeklyProductOnWeek(
    date: Date,
    associated: Associated
  ) {
    let baseDay = DeliveryDaysInteractor.findBaseDeliveryDateOf(
      date,
      associated
    );
    const normalized_date = normalizeDeliveryDate(date);
    let normalized_base_delivery_date = normalizeDeliveryDate(baseDay);

    let days_diference = daysBetween(
      normalized_date,
      normalized_base_delivery_date
    );

    if (days_diference % 14 === 0) return true;
    return false;
  }

  static planHasFrequency(paymentPlan: any, frequecy: string) {
    return paymentPlan.some((item: any) => {
      return item.frequency === frequecy;
    });
  }

  static findBaseDeliveryDateOf(date: Date, associated: Associated) {
    let baseDay;
    if (associated.deliveryInfo.baseDeliveryDate === undefined) {
      baseDay = new Date();
    } else {
      baseDay = associated.deliveryInfo.baseDeliveryDate;
    }
    const normalized_base_date = normalizeDeliveryDate(baseDay);
    while (date > normalized_base_date) {
      if (SuspendedDaysInteractor.weekIsSuspended(date, associated)) {
        return addDays(date, 7);
      }
      date = subDays(date, 7);
    }
    return normalized_base_date;
  }

  static getNextDeliveryDate(associated: Associated): Date {
    let today = new Date();
    let skip_week = false;
    if (associated.deliveryInfo.lastDeliveryDate) {
      skip_week = compareDeliveryDates(
        associated.deliveryInfo.lastDeliveryDate,
        today
      );
    }

    if (!skip_week) {
      // Pula essa semana se o dia de entrega já passou
      skip_week = associated.deliveryInfo.weekDay < today.getDay();
    }

    let deliveryWeekDay = associated.deliveryInfo.weekDay;

    let nextPossibleDeliveryDate = normalizeDeliveryDate(today);
    let weekpassed = 0;
    nextPossibleDeliveryDate = addDays(
      nextPossibleDeliveryDate,
      deliveryWeekDay
    );

    if (skip_week)
      nextPossibleDeliveryDate = addDays(nextPossibleDeliveryDate, 7);

    while (
      !DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(
        nextPossibleDeliveryDate,
        associated
      )
    ) {
      nextPossibleDeliveryDate = addDays(nextPossibleDeliveryDate, 7);

      weekpassed += 1;
      if (weekpassed > 6) {
        throw new Error("DateError: Assciado nunca ira receber entrega");
      }
    }
    return nextPossibleDeliveryDate;
  }
}
