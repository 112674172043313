import database from "../database/DataBaseConfig";
import Associated from "../entities/Associated";
import AssociatedInteractor from "./AssociatedInteractor";

export default class DriverInteractor{

  static async getDriverAssociatedsForWeekDay(driver_id:string, day_number:number):Promise<Associated[]>{
    // Receber informacao
    let driver = await database.driver_db.find(driver_id);
    let associateds = await database.associated_db.getWeekDayAssociatedsForDriver(day_number, driver_id);
    if(!driver) return [];
  
    // Ordenar corretamente associados
    let deliveryData = driver.deliveries.find((delivery) => delivery.weekDay === day_number);
    let order:string[] = []
    if(deliveryData) order = deliveryData.order || [];
    associateds.sort((a,b)=>{
      let aIndex = order.indexOf(a.id);
      let bIndex = order.indexOf(b.id);
      return aIndex - bIndex;
    })

    // Filtragens de associados que nao devem receber
    return associateds.filter((associated)=>{
      return AssociatedInteractor.associatedShouldReceiveDeliveryThisWeek(associated);
    })

  }

}