import {
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export type PaymentsIndexListItemProps = {
  sent_date: string;
  value: string;
  status: string;
  raw_status: string;
};

export default function PaymentsIndexListItem(
  props: PaymentsIndexListItemProps
) {
  const classes = useStyles();

  const receiptStatus = (status: String) => {
    if (status === "accepted") {
      return classes.acceptedText;
    } else {
      return classes.refusedText;
    }
  };

  return (
    <div key={props.sent_date}>
      <ListItem>
        <ListItemText
          primary={"Enviado em " + props.sent_date}
          secondary={props.status}
          className={receiptStatus(props.raw_status)}
        />
        <ListItemSecondaryAction>{"R$ " + props.value}</ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  acceptedText: {
    color: "green",
  },
  refusedText: {
    color: "red",
  },
}));
