import { Divider, List } from "@material-ui/core";
import React from "react";
import ReceiptBillItem, { ReceiptBillItemProps } from "../../atoms/shared/receipt/ReceiptBillItem";
import ReceiptBillList from "../../molecules/receipt/ReceiptBillList";

export type BillsProps = {
  list_name: string,
  bills: ReceiptBillItemProps[],
}
export type ShowReceiptInfoProps = {
  month_name: string,
  base_receipt_value: string,
  bills: BillsProps[],
  credits: string
}

export default function ShowReceiptInfo(props:ShowReceiptInfoProps&{idx:string}) {
  
  let subtotal = Number(props.base_receipt_value);
  for(let bill of props.bills){
    subtotal += Number(getTotalFromList(bill.bills))
  }

  return (<div>
    {/* Base Value */}
    <List>
      <ReceiptBillItem 
        forceShow={true}
        description={"Mensalidade(com frete) de "+ props.month_name}
        value={props.base_receipt_value}
        idx={"0"}   
      />
    </List>
    {props.bills.map((bill)=>(
      <div>
        <ReceiptBillList 
          items={bill.bills}
          list_name={bill.list_name}
          total_value={getTotalFromList(bill.bills)}
        />
        <Divider/>
      </div>
    ))}
    <br></br>
    <List dense={true} disablePadding={true}>
      <ReceiptBillItem 
        forceShow={true}
        description={"Subtotal"}
        value={String(subtotal)}
        idx={"0"}   
      />
    </List>
    <List dense={true} disablePadding={true}>
      <ReceiptBillItem 
        description={"Saldo Disponível"}
        value={props.credits}
        idx={"0"}   
      />
    </List>
    <Divider/>
    <List>
      <ReceiptBillItem 
        forceShow={true}
        description={"Total"}
        value={String(subtotal - Number(props.credits))}
        idx={"0"}   
      />
    </List>
  </div>)
}

function getTotalFromList(list: ReceiptBillItemProps[]){
  let total = 0;
  for(let item of list){
    total += Number(item.value);
  }
  return String(total);
}