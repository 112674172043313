import React, {useState} from "react"
import { IndexAssociatedReceiptsList } from "../../../../presenters/admin/associated/IndexAssociatedReceiptPresenter"
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  Box,
  Badge,
  makeStyles,
} from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  shape: {
    width: 20,
    height: "1em",
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse"
  },
}));

function a11yProps(index:any) {
  return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

function ReceiptList({receipts}: IndexAssociatedReceiptsList){
  receipts = receipts || [];
  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = useState(0);

  const handleTabChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  const pend_count = receipts.filter((r)=> r.status === "pending").length
  const notpend_count = receipts.filter((r)=> r.status !== "pending").length
  return <div>
    <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="simple tabs full width">
      <Tab label="Pendentes"
        icon={(
          <Badge badgeContent={pend_count} color="primary">
            <div className={classes.shape} />
          </Badge>
        )}
        classes={{
          wrapper: classes.iconLabelWrapper,
        }}
        {...a11yProps(0)}
      />
      <Tab label="Recebidos" 
        icon={(
          <Badge badgeContent={notpend_count} color="primary">
            <div className={classes.shape} />
          </Badge>
        )}
        classes={{
          wrapper: classes.iconLabelWrapper,
        }}
        {...a11yProps(1)}
      />
    </Tabs>
    <TabPanel value={value} index={0}>
      <div>
        <List>
          { receipts.filter((r)=> r.status === "pending").map((receipt:any, index:any) => {
            return (<div key={index}>
              <ListItem>
                <ListItemText 
                  primary={receipt.associatedName + " - " + receipt.paidValue} 
                  secondary={"Recebido em " + getFormatedDate(receipt.date)}
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" 
                    onClick={() => {
                      history.push({
                          pathname: '/admin/receipt/accept',
                          state: {
                            id: receipt.id,
                            props: receipt
                          }
                      })}
                    }
                  >
                    <ArrowForwardIos/>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li"/>
            </div>)
          })}
        </List>
      </div>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <div>
        <List>
          { receipts.filter((r)=> r.status !== "pending").map((receipt:any, index:any) => {
            return (<div key={index}>
              <ListItem>
                <ListItemText 
                  primary={receipt.associatedName + ": R$ " + receipt.paidValue} 
                  secondary={receipt.type === "CORRECAO" ?
                    "Criado por Administrador em " +  getFormatedDate(receipt.date) : 
                    "Recebido em " + getFormatedDate(receipt.date)
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" 
                    onClick={() => {
                      history.push({
                          pathname: '/admin/receipt/accept',
                          state: {
                            id: receipt.id,
                            props: receipt
                          }
                      })}
                    }
                  >
                    <ArrowForwardIos/>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li"/>
            </div>)
          })}
        </List>
      </div>
    </TabPanel>
  </div>
}

export default ReceiptList

const getFormatedDate = (date:any) => {
  let month = date.getUTCMonth() + 1; //months from 1-12
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let newdate = day + "/" + month + "/" + year
  return newdate
}

function TabPanel(props:any) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {
      value === index && (<Box p={3}>
        {children}
      </Box>)
    }
  </div>);
}


