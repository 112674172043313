import React from "react";
import {TextField} from "@material-ui/core";
import {Control, DeepMap, FieldError} from "react-hook-form";

type form_params  = {
    control: Control<Record<string, any>>,
    errors: DeepMap<Record<string, any>, FieldError>,
    register: any,
    defaultValues?: {
        name: string
    }
}


const FlavorFormBody = ({control, errors, defaultValues,register}:form_params) => {
    return (
        <div>
            <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoComplete="name"
                defaultValue=''
                type="text"
                error={!!errors.name}
                helperText={errors.name?.message}
            />

        </div>
    )
}

export default FlavorFormBody