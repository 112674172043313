import React from 'react';

import Card             from '@material-ui/core/Card';
import CardActionArea   from '@material-ui/core/CardActionArea';
import CardContent      from '@material-ui/core/CardContent';
import CardMedia        from '@material-ui/core/CardMedia';
import Typography       from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory }    from 'react-router-dom';
import { CardActions } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 140,
  },
});

function dateToString(firebase_date, type){
  let today = new Date();
  let date = new Date(firebase_date.toDate());
  let color = "green";

  if(type === "initial"){
    if(today < date) color = "red";
  }
  if(type === "end"){
    if(today > date) color = "red";
  }
  return (<span style={{color}}>
    {date.toLocaleDateString()}
  </span>)
}


function CarouselItem({item, onClick, showDate}){
  const classes = useStyles();
  const history = useHistory();

  const redirect = () => {
    history.push({
      pathname:"/associated/aviso/view/"+item.docRef.id, 
      state: {aviso:item.props}
    })
  }

  return (
    <Card className={classes.root} onClick={() => {onClick ? onClick():redirect()}}>
    <CardActionArea>
      <CardMedia
        className={classes.media}
        image={item.props.imageUrl}
        title="Contemplative Reptile"
      />
      <CardContent>
        <Typography gutterBottom color="textSecondary" variant="body2" component="p">
          {item.props.name}
        </Typography>
      </CardContent>
      {showDate && (
        <CardActions>
          {dateToString(item.props.initialDate, "initial")}<span> - </span> {dateToString(item.props.endDate, "end")} 
        </CardActions>
      )}
    </CardActionArea>
  </Card>
  )
}

export default CarouselItem;