import { addDays } from "date-fns";
import { subDays } from "date-fns/esm";
import { dayIsSuspended } from "./associated_suspended_days";
import { daysBetween, normalizeDeliveryDate } from "./date_manipulation";

// TODO: Ajustar todas as chamadas da funcao para passarem last_delivery corretamente
export function shouldAssociatedReceiveDay(date, associated){
  if(shouldAssociatedReceiveOnWeek(date, associated)){
    return date.getDay() === associated.deliveryInfo.weekDay
  }
  else return false;
}

export function shouldAssociatedReceiveOnWeek(date, associated){
  let individual_items  = associated.individualPurchases || [];
  let payment_plan      = associated.paymentPlan || [];

  // Se nenhum item precisar ser entregue
  if(payment_plan.length === 0 && individual_items.length === 0) return false;

  // Se o plano tiver item semanal ele com certeza ira receber
  if(planHasFrequency(payment_plan, "weekly")) return true;

  return shouldReceiveBiweeklyProductOnWeek(date, associated);
}

export function shouldReceiveBiweeklyProductOnWeek(date, associated){
  let baseDay = findBaseDeliveryDateOf(date, associated);
  const normalized_date = normalizeDeliveryDate(date);
  let normalized_base_delivery_date = normalizeDeliveryDate(baseDay);

  let days_diference = daysBetween(normalized_date, normalized_base_delivery_date);

  if(days_diference % 14 === 0 ) return true;
  return false;
}

function planHasFrequency(paymentPlan, frequecy){
  return paymentPlan.some( (item) =>{
    return item.frequency === frequecy
  })
}

function findBaseDeliveryDateOf(date, associated){
  let baseDay;
  if(associated.deliveryInfo.baseDeliveryDate === undefined){
    baseDay = new Date();
  }else{
    baseDay = associated.deliveryInfo.baseDeliveryDate.toDate()
  }
  const normalized_base_date = normalizeDeliveryDate(baseDay);
  while( date > normalized_base_date ){
    if( dayIsSuspended(date, associated) ){
      return addDays(date, 7);
    }
    date = subDays(date, 7)
  }
  return normalized_base_date;
}