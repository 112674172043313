import React, { useCallback, useState } from "react";

import { useSelector } from "react-redux";
import { isLoaded, useFirebase } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
  Avatar,
  Divider,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneMaskedInput from "../../components/inputMasks/PhoneMaskedInput";
import CepMaskedInput from "../../components/inputMasks/CepMaskedInput";
import firebase from "../../services/Firebase";
import AssociatedFormBody from "../../app/components/organisms/admin/associated/AssociatedFormBody";
import FormField from "../../app/components/atoms/FormField";
import database from "../../app/database/DataBaseConfig";

const schema = yup.object().shape({
  displayName: yup.string().required("Nome é obrigatório"),
  email: yup.string().email().required("Email é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  // .transform(value => value.replace(/[^\d]/g, ''))
  // password: yup.string().min(6, "Senha deve ter pelo menos 6 caracteres"),
  address: yup.string().required("Endereço deve existir"),
  cep: yup.string().required("CEP deve existir"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));



function Profile() {
  const firebase = useFirebase();
  const associatedProfile = useSelector((state) => state.firebase.profile);
  const [loading, setLoading] = React.useState(false);
  const associatedAuth = useSelector((state) => state.firebase.auth);
  const [profilePicturePreview, setProfilePicturePreview] = useState();

  const classes = useStyles();

  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  const changePassword = useCallback(
    async (newPassword) => {
      const user = firebase.auth().currentUser;
      try {
        await user.updatePassword(newPassword);
        console.log("Password Updated!");
      } catch (err) {
        console.log(err);
      }
    },
    [firebase]
  );

  if (!isLoaded(associatedProfile)) {
    return <div>Loading...</div>;
  }

  async function updateAssociatedProfile(profile) {
    setLoading(true);

    try {
      if (profile.email !== associatedProfile.email) {
        await firebase.updateEmail(profile.email, true);
        console.log("Email Updated!");
        //console.log("Error updating email", error);
      }

      if (profile.password && profile.password.trim() !== "") {
        await changePassword(profile.password);
        //console.log("Error updating password", error);
      }

      if (
        profile.displayName !== associatedProfile.displayName ||
        profile.phone !== associatedProfile.phone ||
        profile.image.length ||
        profile.cep !== associatedProfile.cep ||
        profile.address !== associatedProfile.address ||
        profile.delivery_observation !== associatedProfile.delivery_observation
      ) {
        const NewProfile = {
          displayName: profile.displayName,
          phone: profile.phone,
          cep: profile.cep,
          address: profile.address,
          delivery_observation: profile.delivery_observation
        };

        if (profile.image.length) {
          const profileImage = profile.image[0];

          const newProfilePic = await firebase.uploadFile(
            `${associatedAuth.uid}/profilePicture/`,
            profileImage
          );

          const photoURL = await newProfilePic.uploadTaskSnapshot.ref.getDownloadURL();

          NewProfile.photoURL = photoURL;
        }

        // await firebase.updateAuth(NewProfile, true);
        await database.associated_db.update(associatedAuth.uid, {
          ...NewProfile
        })
        console.log("Profile Updated!");
      }
      console.log(associatedAuth.uid)
      alert("Perfil atualizado com sucesso!");
    } catch (error) {
      console.log("Error updating profile", error);
      alert(`Atualização do perfil falhou:, ${error}`);
    }

    setLoading(false);
  }

  const onProfileImageChange = (event) => {
    if (!event.target.files) return;

    console.log(event.target.files[0]);
    const selectedImage = event.target.files[0];

    const imageURL = URL.createObjectURL(selectedImage);

    setProfilePicturePreview(imageURL);
  };

  const {
    email,
    phone,
    displayName,
    photoURL,
    address,
    cep,
    delivery_observation
  } = associatedProfile;

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <form
          onSubmit={handleSubmit(updateAssociatedProfile)}
          className={classes.form}
        >
          <div className={classes.avatarWrapper}>
            <Avatar
              variant="square"
              alt={displayName}
              src={profilePicturePreview || photoURL}
              className={classes.avatar}
            />
            <div className={classes.imageInputWrapper}>
              <label htmlFor="image"> Alterar foto do perfil</label>
              <input
                accept="image/*"
                className={classes.imageInput}
                id="image"
                name="image"
                type="file"
                onChange={onProfileImageChange}
                ref={register}
              />
            </div>
          </div>
          <Divider />
          <h3>Informações Pessoais</h3>
          <TextField
            inputRef={register}
            defaultValue={displayName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome"
            name="displayName"
            autoComplete="name"
            type="text"
            error={!!errors.displayName}
            helperText={errors.displayName?.message}
          />
          <TextField
            inputRef={register}
            defaultValue={phone}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            autoComplete="phone"
            type="text"
            InputProps={{
              inputComponent: PhoneMaskedInput,
            }}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />
          <TextField
            inputRef={register}
            defaultValue={email}
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <Divider />
          <h3>Informações de entrega</h3>
          
          <TextField
            inputRef={register}
            defaultValue={address}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="address"
            label="Endereço"
            name="address"
            autoComplete="address"
            error={!!errors.address}
            helperText={errors.address?.message}
          />

          <FormField
            control={control} 
            errors={errors} 
            label={"Observações de entrega"}
            field_name={"delivery_observation"} 
            defaultValue={delivery_observation}
            multiline
            required={false}
          />

          <TextField
            inputRef={register}
            defaultValue={cep}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cep"
            label="CEP"
            name="cep"
            autoComplete="cep"
            type="text"
            InputProps={{
              inputComponent: CepMaskedInput,
            }}
            error={!!errors.cep}
            helperText={errors.cep?.message}
          />

          <TextField
            inputRef={register}
            defaultValue={address}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="address"
            label="Endereço"
            name="address"
            autoComplete="address"
            error={!!errors.address}
            helperText={errors.address?.message}
          />

  
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Atualizar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Profile;
