import Alert from "../../entities/Alert";
import AlertDBInterface from "../interfaces/AlertDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";
import {Timestamp} from "@firebase/firestore-types";


export default class AlertFirebase implements AlertDBInterface{
  
  async find(id:string):Promise<Alert | undefined>{
    let alert = await findWithRedux(id, "alerts", Alert)
    return this.correctConstructor(alert);
  }

  async all():Promise<Alert[]>{
    let alerts = await allWithRedux("alerts", Alert)
    return alerts.map((a:Alert) => this.correctConstructor(a));
  }


  private correctConstructor(alert: Alert):Alert{
    alert.initialDate = AlertFirebase.transformFirebaseDate(alert.initialDate);
    alert.endDate = AlertFirebase.transformFirebaseDate(alert.endDate);
    return alert;   
  }

  static transformFirebaseDate(date:Timestamp|Date):Date{
    if(date instanceof Date)
      return date
    return date.toDate()
  }
}