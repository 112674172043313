import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "6rem",
    width: "6rem",
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
}));


type AvatarFormInputPropsType = {
  name: string,
  register: any,
  defaultSrc?: any
}

export default function AvatarFormInput({
  name,
  register,
  defaultSrc
}: AvatarFormInputPropsType) {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = React.useState("");

  const onImageChange = (event:any) => {
    if (!event.target.files) return;

    console.log(event.target.files[0]);
    const selectedImage = event.target.files[0];

    const imageURL = URL.createObjectURL(selectedImage);

    setImagePreview(imageURL);
  };

  return (
    <div className={classes.avatarWrapper}>
        <Avatar
          variant="circular"
          alt={"<Sem foto>"}
          src={imagePreview || defaultSrc || ""}
          className={classes.avatar}
          onClick={()=>{
            console.log("click");
            const input_el = document.getElementById("image");
            input_el?.click();
          }}
        />
        <input
          style={{display: "none"}}
          accept="image/*"
          id="image"
          name={name}
          type="file"
          onChange={onImageChange}
          ref={register}
        />
    </div>
  );
}
