import React from "react";

import {
  Card,
  CardContent,
  Link,
  makeStyles,
} from "@material-ui/core";
import LoginForm from "../molecules/LoginForm";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(5),
  },
  smallerFont: {
    fontSize: "0.65rem"
  }
}));

export default function LoginCard(){
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.card}>
      <CardContent>
        <LoginForm />
        <Link href="#" onClick={()=> history.push("/recover_password")}>
          Recuperar Senha
        </Link>
      </CardContent>
    </Card>
  )
}