import PresenterInterface from "../../PresenterInterface";
import { ShowReceiptInfoProps } from "../../../components/organisms/receipt/ShowReceiptInfo";

import database from "../../../database/DataBaseConfig";
import { getInfoForShowReceipt } from "../../utils/receipt";
import Receipt from "../../../entities/Receipt";

export default class ShowReceiptPresenter implements PresenterInterface{
  storage_path:string
  constructor(public receipt_id:string){
    this.storage_path = "/show_receipt/"+this.receipt_id;
  }

  async getData():Promise<ShowReceiptInfoProps&{paidValue:string}>{
    let receipt = await database.receipt_db.find(this.receipt_id) as Receipt;
    return {
      ...getInfoForShowReceipt(receipt),
      paidValue: receipt.paidValue
    };
  } 
}