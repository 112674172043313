import ApplicationEntity from "./ApplicationEntity";

export default class User extends ApplicationEntity{
  role: "ADMIN" | "ASSOCIATED" | "DRIVER"
  
  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }

}