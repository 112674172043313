import { endOfMonth, isBefore, startOfMonth } from "date-fns";
import database from "../../../database/DataBaseConfig";
import Receipt from "../../../entities/Receipt";
import PresenterInterface from "../../PresenterInterface";


export type IndexAssociatedReceiptsList = {
  receipts: {
    status: string,
    associatedName: string, 
    paidValue: string | number,
    date: Date,
    type?: "CORRECAO" | "associated_creation"
    id: string
  }[],
}

export type IndexAssociatedReceiptProps = {
  receipt_list_props: IndexAssociatedReceiptsList,
  credits: {
    total: number,
    paid: number,
    utilized: number
  }
}


export default class IndexAssociatedReceiptPresenter implements PresenterInterface{
  storage_path:string
  constructor(private associated_id:string){
    this.storage_path = "/admin/associated/recepits/" + associated_id;
  }

  async getData(): Promise<IndexAssociatedReceiptProps>{
    let associated = await database.associated_db.find(this.associated_id);
    if(!associated) return {
      receipt_list_props: { receipts: []},
      credits: {
        total: 0, paid: 0, utilized: 0
      },
    }
    //Caculate monthly used credits
    let monthly_deliveries = await database.associated_delivery_db.getDeliveriesOnTimeRange(
      associated.id, 
      startOfMonth(new Date()), 
      endOfMonth(new Date())
    );
    let monthly_total = monthly_deliveries.reduce((acc, cv)=> acc+(cv.totalPrice || 0), 0);
    
    let receipts = await database.receipt_db.searchAssociated(this.associated_id);
    let mosRecentReceipt = receipts.reduce((acc, cv)=> (isBefore(acc.date, cv.date) && cv.type !== "CORRECAO")? cv : acc, receipts[0]) || {
      paidValue: 0
    }
    receipts = receipts.sort( (a, b) => {
      return isBefore(a.date, b.date) ? 1 : -1;
    })
    let credits = isNaN(Number(associated.credits)) ? 0 : Number(associated.credits);
    let paid = isNaN(parseFloat(mosRecentReceipt.paidValue)) ? 0 : mosRecentReceipt.paidValue;
    
    return {
      receipt_list_props: {
        receipts
      },
      credits: {
        total: credits,
        paid: Number(paid) || 0,
        utilized: monthly_total
      },
    }
  }

  static async createCorrection(id: string, addValue: number | undefined){

    
    addValue = addValue || 0;
    let associated = await database.associated_db.find(id);
    if(!associated) return
    
    let new_receipt = new Receipt("", {});
    new_receipt.associatedId = id;
    new_receipt.paidValue = String(addValue) || "0";
    new_receipt.associatedName = associated.displayName;
    new_receipt.status = "accepted";
    new_receipt.type = "CORRECAO";
    new_receipt.date = new Date();

    await database.associated_db.updateCredits(id, new_receipt);
  }
}