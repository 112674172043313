import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useHistory }    from 'react-router-dom';
import { useSelector }              from 'react-redux';

import { getDay, isSameWeek } from 'date-fns'
import { DatePickerCalendar } from 'react-nice-dates'
import {  ptBR } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import "../../styles/calendar.css"
import usePresenter from '../../app/redux/usePresenter';
import CalendarioMuralPresenter from '../../app/presenters/associated/Mural/MuralCalendarPresenter';

const useStyles = makeStyles((theme) => ({
  container:{
    width: "320px",
    margin: "auto"
  },
  spacing:  {
    paddingTop      : "10px"
  },
  alingCenter:{
    margin          : "10px auto",
  }
}));


const modifiersClassNames = {
  highlight: '-highlight',
  nextDelivery: '-nextDelivery',
  suspended: '-suspended'
}

function CalendarioMural() {
  const history     = useHistory();
  
  const [calendarDate, setCalendarDate] = useState();

  const user          = useSelector((state) => state.firebase.auth);
  const associated_id = user.uid;
  let data = usePresenter( new CalendarioMuralPresenter(associated_id) );

  const onDateChange = (date) => {
    setCalendarDate(date);
    if(date.getDay() === data.delivery_day_number)
      history.push(`/associated/entrega/dia/${date.toISOString().slice(0, 10)}`);
  }

  const classes     = useStyles();
  if(data === undefined){
    return (<div className="loading-container"><CircularProgress /></div>)
  }
  return <div className={classes.spacing}>
    <div className={classes.container}>
      <DatePickerCalendar 
        className={classes.alingCenter}
        onDateChange={onDateChange} 
        date={calendarDate}
        locale={ptBR}
        modifiersClassNames={modifiersClassNames}
        modifiers={{
          nextDelivery: date => data.should_receive_date(date) && getDay(date) === data.delivery_day_number,
          highlight: date => getDay(date) === data.delivery_day_number, // Highlights Tuesdays
          suspended: date => data.suspended_dates.some((suspended_date) => isSameWeek(suspended_date, date) && getDay(date) === data.delivery_day_number)
        }}
      />
    </div>
  </div>;
}

export default CalendarioMural;