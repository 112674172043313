import React, { useState } from "react";

import { IMaskInput } from "react-imask";

function PhoneMaskedInput(props) {
  const { inputRef, defaultValue, ...other } = props;
  const [value, setValue] = useState(defaultValue);

  return (
    <IMaskInput
      {...other}
      inputRef={(ref) => inputRef(ref ? ref : null)}
      mask="(00) 00000-0000"
      unmask
      value={value}
      onAccept={(value, mask) => setValue(value)}
    />
  );
}

export default PhoneMaskedInput;
