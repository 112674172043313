import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import { getDay, isSameWeek } from 'date-fns'
import { DatePickerCalendar } from 'react-nice-dates'
import {  ptBR } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import "../../../../styles/calendar.css"
import usePresenter from '../../../redux/usePresenter';
import CalendarioMuralPresenter from '../../../presenters/associated/Mural/MuralCalendarPresenter';

const useStyles = makeStyles((theme) => ({
  container:{
    width: "320px",
    margin: "auto"
  },
  spacing:  {
    paddingTop      : "10px"
  },
  alingCenter:{
    margin          : "10px auto",
  }
}));


const modifiersClassNames = {
  highlight: '-highlight',
  nextDelivery: '-nextDelivery',
  suspended: '-suspended'
}

function AssociatedCalendar({associatedId, onChange}:{associatedId:string, onChange:(date:Date)=>any}) {
  const [calendarDate, setCalendarDate] = useState();
  let data = usePresenter( new CalendarioMuralPresenter(associatedId) );
  
  onChange = onChange || [];

  const onDateChange = (date:Date|null) => {
    if(date === null) return;
    setCalendarDate(date as any);
    onChange(date);
  }

  const classes     = useStyles();
  if(data === undefined){
    return (<div className="loading-container"><CircularProgress /></div>)
  }
  return <div className={classes.spacing}>
    <div className={classes.container}>
      <DatePickerCalendar 
        onDateChange={onDateChange} 
        date={calendarDate}
        locale={ptBR}
        modifiersClassNames={modifiersClassNames}
        modifiers={{
          nextDelivery: date => data.should_receive_date(date) && getDay(date) === data.delivery_day_number,
          highlight: date => getDay(date) === data.delivery_day_number, // Highlights Tuesdays
          suspended: date => data.suspended_dates.some((suspended_date:Date) => isSameWeek(suspended_date, date) && getDay(date) === data.delivery_day_number)
        }}
      />
    </div>
  </div>;
}

export default AssociatedCalendar;