import SystemAlert from "../../entities/SystemAlert";
import SystemAlertDBInterface from "../interfaces/SystemAlertDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";

export default class SystemAlertFirebase implements SystemAlertDBInterface{
  
  async find(id:string):Promise<SystemAlert | undefined>{
    return findWithRedux(id, "systemAlerts", SystemAlert);
  }

  async all():Promise<SystemAlert[]>{
    return allWithRedux("systemAlerts", SystemAlert);
  }
}