import React, { useState } from "react";

import { useSelector } from "react-redux";
import { isLoaded, useFirebase } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneMaskedInput from "../../components/inputMasks/PhoneMaskedInput";
import Driver from "../../services/db/Driver";
import AvatarInput from "../../components/inputs/AvatarInput";

const schema = yup.object().shape({
  displayName: yup.string().required("Nome é obrigatório"),
  email: yup.string().email().required("Email é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  //password: yup.string().optional().min(6, "Senha deve ter pelo menos 6 caracteres"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

function Home() {
  const firebase = useFirebase();
  const driverProfile = useSelector((state) => state.firebase.profile);
  const [loading, setLoading] = React.useState(false);
  const driverAuth = useSelector((state) => state.firebase.auth);

  const classes = useStyles();

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  if (!isLoaded(driverProfile)) {
    return <div>Loading...</div>;
  }

  async function updateDriverProfile(newProfile) {
    setLoading(true);
    try {
      await Driver.updateProfile(
        firebase,
        driverProfile,
        newProfile,
        driverAuth.uid
      );
      alert("Perfil atualizado com sucesso!");
    } catch (error) {
      console.log(error);
      alert(`Atualização do perfil falhou:, ${error}`);
    }

    setLoading(false);
  }

  const { email, phone, displayName, photoURL } = driverProfile;

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form
          onSubmit={handleSubmit(updateDriverProfile)}
          className={classes.form}
        >
          <AvatarInput
            required
            name="image"
            inputRef={register}
            alt={displayName}
            src={photoURL}
            label="Alterar foto do perfil"
          />

          <TextField
            inputRef={register}
            defaultValue={displayName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome"
            name="displayName"
            autoComplete="name"
            type="text"
            error={!!errors.displayName}
            helperText={errors.displayName?.message}
          />

          <TextField
            inputRef={register}
            defaultValue={phone}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            autoComplete="phone"
            type="text"
            InputProps={{
              inputComponent: PhoneMaskedInput,
            }}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />

          <TextField
            inputRef={register}
            defaultValue={email}
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Atualizar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Home;
