import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";

import usePresenter from "../../../redux/usePresenter";
import DeliveryOnDatePresenter from "../../../presenters/associated/DeliveryOnDatePresenter";

const useStyles = makeStyles((theme) => ({
  hText: {
    margin: theme.spacing(2),
  },
}));

function convertDateToUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

function dataFormatada(data: Date) {
  var dia = data.getDate().toString(),
    diaF = dia.length === 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length === 1 ? "0" + mes : mes,
    anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
}

function AsscoaitedDeliveryInfoList({
  date,
  associated_id,
}: {
  date: Date;
  associated_id: string;
}) {
  const classes = useStyles();
  let deliverDate = convertDateToUTC(new Date(date));

  const [loading, setLoading] = useState(false);
  let data = usePresenter(
    new DeliveryOnDatePresenter(associated_id, deliverDate)
  );
  const [deliveryObs, setDeliveryObs] = useState("");

  const onClickSuspend = async () => {
    if (!window.confirm("Deseja realmente suspender entrega?")) return;
    await DeliveryOnDatePresenter.addSuspendedDay(
      associated_id,
      new Date(deliverDate.getTime())
    );
    // history.goBack();
  };

  const onClickRemoveSuspend = async () => {
    await DeliveryOnDatePresenter.removeSuspendedDay(
      associated_id,
      new Date(deliverDate.getTime())
    );
    // history.goBack();
  };

  const updateDeliveryObs = async () => {
    setLoading(true);
    await DeliveryOnDatePresenter.updateObservation(
      associated_id,
      date,
      deliveryObs
    );
    setLoading(false);
  };

  useEffect(() => {
    let obs = data ? data.observation : "";
    setDeliveryObs(obs);
  }, [data]);

  if (data === undefined || loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Divider />
        <div className={classes.hText}>
          <h3>
            {"Entrega - Dia: "}
            {dataFormatada(date)}
            <div>{data.type}</div>
          </h3>
        </div>
        <Divider />
        <List style={{ marginBottom: 10 }}>
          {data.products.map((item: any, index: any) => (
            <div key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt={item.name} src={item.imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                  secondaryTypographyProps={{ noWrap: true }}
                />
                <ListItemSecondaryAction>
                  {item.total}
                  {" uni"}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant={"inset"} />
            </div>
          ))}
        </List>
        {data.returnables.length !== 0 && (
          <div className={classes.hText}>
            {" "}
            <Divider />
            <h3>{"Itens retornados"}</h3>
            <List style={{ marginBottom: 50 }}>
              {data.returnables.map((item: any, index: any) => (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={item.name}
                      secondaryTypographyProps={{ noWrap: true }}
                    />
                    <ListItemSecondaryAction>
                      {item.quantity}
                      {" uni"}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant={"inset"} />
                </div>
              ))}
            </List>
          </div>
        )}
        {data.broken.length !== 0 && (
          <div className={classes.hText}>
            {" "}
            <Divider />
            <h3>{"Itens Quebrados"}</h3>
            <List style={{ marginBottom: 50 }}>
              {data.broken.map((item: any, index: any) => (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={item.name}
                      secondaryTypographyProps={{ noWrap: true }}
                    />
                    <ListItemSecondaryAction>
                      {item.quantity}
                      {" uni"}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant={"inset"} />
                </div>
              ))}
            </List>
          </div>
        )}

        <Container>
          <TextField
            value={deliveryObs}
            onChange={(e) => setDeliveryObs(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Observações de entrega"
            name="email"
          />
          <Button
            variant="contained"
            style={{ fontSize: 10 }}
            color="primary"
            aria-multiline
            onClick={updateDeliveryObs}
          >
            Atualizar observação
          </Button>
        </Container>
        <div style={{ height: "20px" }}></div>
        <Divider />
        <div style={{ height: "20px" }}></div>

        {["futureDelivery", "nextDelivery"].includes(data.literal_type) && (
          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{ fontSize: 12 }}
              color="primary"
              onClick={onClickSuspend}
            >
              Suspender entrega
            </Button>
          </div>
        )}
        {["suspended", "nextSuspended"].includes(data.literal_type) && (
          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{ fontSize: 12 }}
              color="primary"
              onClick={onClickRemoveSuspend}
            >
              Manter entrega
            </Button>
          </div>
        )}
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  );
}

export default AsscoaitedDeliveryInfoList;
