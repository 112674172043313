import database from "../../../database/DataBaseConfig";
import AssociatedInteractor from "../../../interactors/AssociatedInteractor";
import { ProductOnDelivery } from "../../../interactors/delivery/DeliveryInfoInteractor";
import DeliveryInteractor from "../../../interactors/delivery/DeliveryInteractor";
import PresenterInterface from "../../PresenterInterface";
import { formatProductListInfoArray } from "../../utils/product";

export class MuralTodayPresenter implements PresenterInterface{
  storage_path:string
  constructor(){
    this.storage_path = "/mural_hj"
  }

  async getData(){
    let associateds = await database.associated_db.allActiveAssociateds();
    let today_number = (new Date()).getDay()
    associateds = AssociatedInteractor.getWeekDayAssociateds(associateds, today_number);
    associateds = associateds.filter((associated)=>{
      return AssociatedInteractor.associatedShouldReceiveDeliveryThisWeek(associated);
    })

    let ret_arr:ProductOnDelivery[] = []
    let allergics: {[key:string]:{name:string, quantity:number}[]} = {}
    for(let ass of associateds){
      let products = await DeliveryInteractor.shouldReceiveToday(ass.id);
      products.forEach((prod) => {
        if(!allergics[prod.name]){allergics[prod.name] = []}
        if(prod.intolerant){
          allergics[prod.name].push({name:ass.displayName, quantity: prod.intolerants})
        }
      }) 
      ret_arr= await DeliveryInteractor.combineProductOnDelivery(products, ret_arr);
    }

    let products = await formatProductListInfoArray(ret_arr);
    return {products, allergics}
  }
}