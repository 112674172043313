import ApplicationEntity from "../../../entities/ApplicationEntity";
import {db} from "../../../../services/Firebase"
import { injectReduxStore } from "../../../redux/backendStorage";

type modelConstructorT = new(id:string, data:any) => ApplicationEntity
type customConstructor = (id:string, data:any)=> ApplicationEntity

// FIND:
export async function findIdFirebaseDb(id:string, collection_path:string, model_constructor?:modelConstructorT, custom_constructor?:customConstructor){
  let query = db.collection(collection_path).doc(id);
  let response = await query.get();
  let data = response.data()
  if(data === undefined) return null
  if(model_constructor){
    return new model_constructor(response.id, data);
  }
  else if(custom_constructor){
    return custom_constructor(response.id, data);
  }
  else return null
}

export async function findWithRedux(id:string, collection_path:string, model_constructor?:modelConstructorT, custom_constructor?:customConstructor){
  return injectReduxStore(()=>{
    return findIdFirebaseDb(id, collection_path, model_constructor, custom_constructor)
  }, collection_path + "/" + id);
}