import {Button, CircularProgress, Container, makeStyles, TextField, Theme} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import FlavorFormBody from "../../../organisms/admin/flavor/FlavorFormBody";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useHistory, useLocation} from "react-router-dom";
import {green} from "@material-ui/core/colors";
import * as yup from "yup";
import CreateFlavorPresenter, {FlavorFormValues} from "../../../../presenters/admin/flavor/CreateFlavorPresenter";

const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório").trim(),
});


type CreateFormProps={
    name?: string,
    id?:string,
    deleted?: boolean
}

export default function CreateForm({name, id, deleted}: CreateFormProps) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [defaultName, setDefaultName] = useState(name);
    const history = useHistory()

    const createNewFlavor = (name:string) => {
        if (defaultName && id) {
            CreateFlavorPresenter.updateFlavor(id,{name});
        } else {
            CreateFlavorPresenter.createFlavor({name});
        }
    }

    const deleteHandler = async()=>{
        if(id) CreateFlavorPresenter.deleteFlavor(id)
        history.push("/admin/flavor");
    }

    const unDeleteHandler = async()=>{
        if(id) CreateFlavorPresenter.unDeleteFlavor(id)
        history.push("/admin/flavor");
    }

    const submitHandler = async (form:any) => {
        setLoading(true);
        try {
            await createNewFlavor(form.name as string);
            history.push("/admin/flavor");
        } catch (error) {
            console.log(error);
            alert("Erro ao criar sabor.");
        }
        setLoading(false);
    };

    const { handleSubmit, reset, control,register, errors, watch, setValue, trigger, getValues } =
        useForm<FlavorFormValues>({
            resolver: yupResolver(schema),
            defaultValues: {
                name: defaultName
            }
        });

    return (
        <Container maxWidth="xs">
            <div className={classes.paper}>
                <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
                    <FlavorFormBody   control={control} errors={errors} register={register}/>

                    <div className={classes.submitWrapper}>
                        {defaultName ? (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={loading}
                            >
                                Atualizar Sabor
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={loading}
                            >
                                Criar Sabor
                            </Button>
                        )}

                        {loading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </div>
                </form>
                {(!deleted || (deleted != undefined && !deleted)) && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgb(222 111 111)",
                          margin: "2px auto",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={deleteHandler}
                      >
                        Excluir Sabor
                      </div>
                )}
                {(deleted === true) && (
                    <div
                        style={{
                            fontSize: "14px",
                            color: "rgb(222 111 111)",
                            margin: "2px auto",
                            fontWeight: 600,
                            cursor: "pointer",
                        }}
                        onClick={unDeleteHandler}
                    >
                        Reativar Sabor
                    </div>
                )}
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
        paper: {
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
        },
        avatarWrapper: {
            margin: theme.spacing(2),
        },
        avatar: {
            height: "100%",
            width: "100%",
        },
        imageInputWrapper: {
            marginTop: theme.spacing(2),
            textAlign: "center",
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            // marginTop: theme.spacing(3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        submitWrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9, // Center spinner
            marginLeft: -12,
        },
    }))