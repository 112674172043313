import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import "firebase/remote-config";
import firebaseConfig from "../config/firebase-config";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const functions = firebase.functions();
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

const remoteConfig = firebase.remoteConfig()

//  db.useEmulator('localhost', 8080);
// auth.useEmulator('http://localhost:9099/', { disableWarnings: true });

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

remoteConfig.defaultConfig = {}
// remoteConfig.settings.minimumFetchIntervalMillis = 1000;
/**
 * Configurações disponiveis:
 *  entry_tax_abled: boolean
 * 
 */


export { auth, db, storage, serverTimestamp, functions, messaging, remoteConfig};
export default firebase;
