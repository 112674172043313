import React from "react";

import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function LogOutButton() {
    const firebase = useFirebase();
    const history = useHistory();

    const onSubmit = async  () => {
        await firebase.logout()
        history.replace("/")
    }

  return (
    <div onClick={onSubmit}>
      <ExitToAppIcon />
    </div>
  );
}

export default LogOutButton;
