import { createStore, combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import backendReducer from "./backendRedux";
import frontEndReducer from "./frontendRedux";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  backend: backendReducer,
  frontend: frontEndReducer
});

const initialState = {}

const store = createStore(rootReducer, initialState);

export default store;
