import DriverDBInterface        from "./interfaces/DriverDBInterface"
import AssociatedDBInterface    from "./interfaces/AssociatedDBInterface"
import NeighborhoodDBInterface  from "./interfaces/NeighborhoodDBInterface"
import FlavorDBInterface        from "./interfaces/FlavorDBInterface"
import ProductDBInterface       from "./interfaces/ProductDBInterface"
import Associated_DeliveryDBInterface       from "./interfaces/Associated_DeliveryDBInterface"

import DriverFirebase       from "./firebase/DriverFirebase"
import AssociatedFirebase   from "./firebase/AssociatedFirebase"
import NeighborhoodFirebase from "./firebase/NeighborhoodFirebase"
import ProductFirebase      from "./firebase/ProductFirebase"
import FlavorFirebase       from "./firebase/FlavorFirebase"
import Associated_DeliveryFirebase from "./firebase/Asssociated_DeliveryFirebase"
import UserDBInterface from "./interfaces/UserDBInterface"
import UserFirebase from "./firebase/UserFirebase"
import ReceiptDBInterface from "./interfaces/ReceiptDBInterface"
import ReceiptFirebase from "./firebase/ReceiptFirebase"
import AlertDBInterface from "./interfaces/AlertDBInterface"
import SystemAlertDBInterface from "./interfaces/SystemAlertDBInterface"
import AlertFirebase from "./firebase/AlertFirebase"
import SystemAlertFirebase from "./firebase/SystemAlertFirebase"
import AppConfigsDBInterface from "./interfaces/AppConfigsDBInterface"
import AppConfigsFirebase from "./firebase/AppConfigsFirebase"

interface database_interface  {
  driver_db:        DriverDBInterface
  associated_db:    AssociatedDBInterface
  neighborhood_db:  NeighborhoodDBInterface
  product_db:       ProductDBInterface
  flavor_db:        FlavorDBInterface
  associated_delivery_db: Associated_DeliveryDBInterface
  users_db:         UserDBInterface
  receipt_db:       ReceiptDBInterface

  alert_db:         AlertDBInterface
  systemalert_db:   SystemAlertDBInterface
  appconfigs_db:    AppConfigsDBInterface
}

const database:database_interface = {
  driver_db:        new DriverFirebase(),
  associated_db:    new AssociatedFirebase(),
  neighborhood_db:  new NeighborhoodFirebase(),
  product_db:       new ProductFirebase(),
  flavor_db:        new FlavorFirebase(),
  associated_delivery_db: new Associated_DeliveryFirebase(),
  users_db:         new UserFirebase(),
  receipt_db:       new ReceiptFirebase(),
  alert_db:         new AlertFirebase(),
  systemalert_db:   new SystemAlertFirebase(),
  appconfigs_db:    new AppConfigsFirebase()
}

export default database;