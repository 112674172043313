import { ExtendedFirestoreInstance } from "react-redux-firebase";
import Associated from "../../Associated";
import { format as formatDate, subDays, isSameDay, addDays } from "date-fns";
import { compareDeliveryDates, normalizeDeliveryDate } from "../../../../utils/date_manipulation";
import { dayIsSuspended, getSuspendedDates } from "../../../../utils/associated_suspended_days";

/**
 * Dias suspensos sao salvos sempre como o dia 0 da semana que foi suspensa
 * Isso eh feito para facilitar calculos de mudanca de dias de entrega sem necessitar mudanca do banco
 */
export default class SuspendedDays{
  suspendedDaysCache?:Date[];
  constructor(public db:ExtendedFirestoreInstance, public associated:Associated){}

	async add(date:Date){
		let savedDay =	normalizeDeliveryDate(date);
		let newsuspendedDatesList = this.getSuspendedDays()
		newsuspendedDatesList.push(savedDay);
		await Associated.update(this.db, this.associated.docRef.id, {
			suspendedDates: newsuspendedDatesList
		})
		this.associated.props.suspendedDates = newsuspendedDatesList
	}

  async remove(date:Date){
		let savedDay =	SuspendedDays.normalizeDate(date);
		let newsuspendedDatesList = this.getSuspendedDays();
		newsuspendedDatesList = newsuspendedDatesList.filter( (suspended:any)=>{
			return !compareDeliveryDates(suspended, savedDay);
		});
		await Associated.update(this.db, this.associated.docRef.id, {
			suspendedDates: newsuspendedDatesList
		})
		this.associated.props.suspendedDates = newsuspendedDatesList
  }

	async dayIsSuspended(date: Date):Promise<boolean>{
		return dayIsSuspended(date, this.associated.props);
  }
  
	getSuspendedDays(forceUpdate=false){
		return getSuspendedDates(this.associated.props);
	}


	static normalizeDate(date: Date){
		return subDays(date, date.getDay());
	}

}