import React, { useEffect, useState } from "react";

import {
  Container,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ChatIcon from "@material-ui/icons/Chat";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";

import usePresenter from "../../app/redux/usePresenter";
import MuralDeliveriesPresenter from "../../app/presenters/admin/AdminHome/MuralDeliveriesPresenter";
import { MuralTodayPresenter } from "../../app/presenters/admin/AdminHome/MuralTodayPresenter";
import ProductInfoList from "../../app/components/molecules/product/ProductInfoList";

const useStyles = makeStyles((theme) => ({
  hText: {
    margin: theme.spacing(2),
    // backgroundColor: "#EBEBEB"
  },
  tabContent: {
    width: "100%",
  },
}));

export default function AdminHome() {
  let weeks_data = usePresenter(new MuralDeliveriesPresenter(), true) || { this_week_prods: [], next_week_prods : []}
  let today_data = usePresenter(new MuralTodayPresenter(), true) || {products: [], allergics: {}}

  const db = useFirestore();
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");

  const firebase = useFirebase();
  const user = useSelector((state) => state.firebase.auth);
  const realtime = firebase.database();

  useEffect(() => {
    getMessages();
  }, [db]);

  const getMessages = () => {
    realtime.ref("/chats").once("value").then((snapshot)=>{
      let messages = Object.values(snapshot.val());
      setMessages(messages);
    }).catch((err)=>{
      console.log(err);
    })
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (evt) => {
  };

  return (
    <div>
      <Container>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="simple tabs full width"
        >
          <Tab label="Hoje" {...a11yProps(0)} />
          <Tab label="Essa Semana" {...a11yProps(1)} />
          <Tab label="Semana Seguinte" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className={classes.tabContent}>
            <ProductInfoList product_list={today_data.products} showAvatar={true} allergics={today_data.allergics} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.tabContent}>
            <ProductInfoList product_list={weeks_data.this_week_prods} showAvatar={true} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.tabContent}>
            <ProductInfoList product_list={weeks_data.next_week_prods} showAvatar={true} />
          </div>
        </TabPanel>

        <Container>
          <TextField
            id="filled-secondary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ChatIcon />
                </InputAdornment>
              ),
            }}
            label="Procurar"
            variant="filled"
            fullWidth={true}
            value={query}
            onChange={handleChange}
            color="primary"
          />
          <List>
            <div>
              {messages.map((chat) => {
                let messages = Object.values(chat)

                if (messages[messages.length - 1].from !== user.uid &&
                    (messages[messages.length - 1].read === undefined ||
                        !messages[messages.length - 1].read) ) {
                  return (
                    <>
                      <ListItem>
                        <ListItemText
                          primary={messages[messages.length - 1].user2Name}
                          secondary={messages[messages.length - 1].content}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => {
                              history.push({
                                pathname: "/admin/chat",
                                state: {
                                  id: messages[messages.length - 1].from,
                                },
                              });
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                } else {
                  return;
                }
              })}
            </div>
          </List>
        </Container>
      </Container>
    </div>
  )

}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
