import { CarrouselCardDataType } from "../../../components/atoms/associated/CarrousselCard";
import PresenterInterface from "../../PresenterInterface";
import Alert from "../../../entities/Alert";
import SystemAlert from "../../../entities/SystemAlert";
import getSystemAlertsFor, { getActiveAlerts } from "../../../interactors/AlertInteractor";

export default class CarouselPresenter implements PresenterInterface{
  storage_path:string
  constructor(public associated_id:string){
    this.storage_path = "/carousel_mural/"+this.associated_id;
  }

  async getData():Promise<CarrouselCardDataType[]>{
    let avisos = await getActiveAlerts();
    let systemalerts = await getSystemAlertsFor(this.associated_id);

    return avisos.map(this.formatAviso).concat(systemalerts.map(this.formatAviso));
  } 

  private formatAviso(aviso: Alert|SystemAlert):CarrouselCardDataType{
    return {
      body: aviso.description,
      id: aviso.id, 
      imageUrl: aviso.imageUrl,
      title: aviso.name
    }
  }
}