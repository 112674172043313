import React, { useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import {
	Switch,
	Route,
	useRouteMatch,
  Redirect,
    useHistory
} from "react-router-dom";
import routes from '../routes/associated'

import AppBottomNavigation from './AppBottomNavigation'

import { useSelector, useDispatch } from "react-redux";
import Associated from "../services/db/Associated";
import { CircularProgress, ThemeProvider, makeStyles } from "@material-ui/core";
import { associatedTheme } from "../utils/themes";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export default function AssociatedLayout(){
  let { path } = useRouteMatch();

  const [loading, setLoading] = React.useState(true);

  const classes     = useStyles();
  const db        = useFirestore();
  const dispatch  = useDispatch();
  const auth      = useSelector((state) => state.firebase.auth);
  const associated = useSelector((state) => state.backend.associated);
  const history = useHistory()

  useEffect( () =>{
    const fetchData = async () =>{
      if( !associated  ){
        const associated_firestore = await Associated.find(db, auth.uid)
        dispatch({type: "app/associated/update", payload: associated_firestore})

      }

      setLoading(false);
    }
    fetchData();
  }, [auth.uid, dispatch, db, associated])

  const redirectTerm = () => {
    if (associated && !associated.props.acceptedTerm)
      history.replace("/termo")
  }

  return (
    <ThemeProvider theme={associatedTheme}>
    <div>
      <Switch>
        {redirectTerm()}
        {
          routes.map((route, index)=>{
            let new_path = `${path}${route.path}`;
            if(route.param) new_path += route.param;
            return (
            <Route 
              key={index}
              exact 
              path={new_path} 
            >
              <div className={classes.content}>
                <div className={classes.drawerHeader} />
                {loading ? (<div className="loading-container"><CircularProgress /></div>) : <route.component /> }
              </div>
              <AppBottomNavigation route={route} model="associated" routesList={routes} />      
            </Route>
          )})
        }
        <Route path={path} >
          <Redirect to={"/associated/mural"} />
        </Route>
      </Switch>
    </div>
    </ThemeProvider>
  )
}