import PresenterInterface from "../../PresenterInterface";
import firebase from "firebase";
import database from "../../../database/DataBaseConfig";
import Associated from "../../../entities/Associated";
import Flavor from "../../../entities/Flavor";
import formField from "../../../components/atoms/FormField";
import {FlavorCreateParams} from "../../../database/interfaces/FlavorDBInterface";

type FlavorFormProps ={
    id?: string,
    name?: string,
    deleted?: boolean
}

export type FlavorFormValues ={
    name: string
}

type FormFields = {
    name: string,
}

export default class CreateFlavorPresenter implements PresenterInterface {
    storage_path: string;

    constructor(private flavor_id:string){
        this.storage_path = "/admin/flavor/edit/" + flavor_id;
    }

    async getData(): Promise<FlavorFormProps> {
        let flavor = await database.flavor_db.find(this.flavor_id)
        return {id:flavor?.id, name: flavor?.name, deleted: flavor?.deleted};
    }

    static async createFlavor(params: FormFields) {
        await database.flavor_db.create(params as FlavorCreateParams)
    }

    static async deleteFlavor(id:string){
        await database.flavor_db.delete(id)
    }

    static async unDeleteFlavor(id:string){
        await database.flavor_db.update(id, {deleted:false} as FlavorCreateParams)
    }

    static async updateFlavor(id:string, params: FormFields){
        await database.flavor_db.update(id, params)
    }

}