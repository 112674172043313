import React from "react";

import {
  Card,
  CardContent,
  makeStyles,
  Link,
  Typography,
} from "@material-ui/core";
import RecoverPasswordForm from "../molecules/RecoverPasswordForm";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(5),
    width: "100%"
  },
  smallerFont: {
    fontSize: "0.65rem"
  },
  title: {
    fontSize: 14,
  },
}));

export default function RecoverPasswordCard(){
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}  gutterBottom>
          Recuperar Senha
        </Typography>
        <RecoverPasswordForm />
        <Link href="#" onClick={()=> history.push("/")}>
          Fazer Login
        </Link>
      </CardContent>
    </Card>
  )
}