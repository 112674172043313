import {
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import { useHistory } from "react-router-dom";
import Receipt from "../../../entities/Receipt";

export type ReceiptIndexListItemType = {
  associatedName: string;
  raw_status: string;

  value: string;
  sent_date: string;
  id: string;
  receipt_info: Receipt;
  status: string;
};

export default function ReceiptIndexListItem(props: ReceiptIndexListItemType) {
  const history = useHistory();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={props.associatedName + " - " + props.value}
          secondary={"Recebido em " + props.sent_date}
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={() => {
              history.push({
                pathname: "/admin/receipt/accept",
                state: {
                  id: props.id,
                  props: props.receipt_info,
                },
              });
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}
