import React from "react";
import LoginCard from "../app/components/organisms/LoginCard";
import LoginTemplate from "../app/components/templates/LoginTemplate";

function Login() {
  return (
    <LoginTemplate >
      <LoginCard />
    </LoginTemplate>
  );
}

export default Login;
