


export default function backendReducer(state={}, action){
  switch(action.type){
    case "app/updateStorage":
      return{
        ...state,
        [action.path]: action.payload
      }
    case "app/associated/update":
      return {
        ...state,
        associated: action.payload
      }
    default:
      return state;
  }
}