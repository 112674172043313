import { createTheme } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";
import configs from "../config/colors";

const navTheme = createTheme(
  {
    palette: {
      primary: {
        main: configs.theme.mainColor,
      },
    },
  },
  ptBR
);

const driverTheme = createTheme(
  {
    palette: {
      primary: {
        main: configs.theme.driver.themeColor,
      },
    },
  },
  ptBR
);

const associatedTheme = createTheme(
  {
    palette: {
      primary: {
        main: configs.theme.associated.themeColor,
      },
    },
  },
  ptBR
);

const adminTheme = createTheme(
  {
    palette: {
      primary: {
        main: configs.theme.admin.themeColor,
      },
    },
  },
  ptBR
);

export { adminTheme, associatedTheme, driverTheme, navTheme };
