import { useEffect } from "react";
import { useSelector } from "react-redux";
import updateState from "./frontendStorage";

export default function usePresenter(presenter, boost_performance=false){
  const state = useSelector((state)=> state.frontend.presenter[presenter.storage_path])
  const state_loaded = useSelector((state)=> state.frontend.state_loaded[presenter.storage_path])

  useEffect(()=>{
    (async () => {
      if(!state_loaded){
        await updateState(presenter);
      }
    })()
    // else if(!boost_performance) updateState(presenter);
  }, [state_loaded, presenter])

  return state
}