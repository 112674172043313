import database from "../../database/DataBaseConfig";
import Associated from "../../entities/Associated";
import Associated_Delivery, { DeliveredProduct } from "../../entities/Associated_Delivery";
import Flavor from "../../entities/Flavor";
import AssociatedInteractor from "../../interactors/AssociatedInteractor";
import DeliveryHistoryInteractor from "../../interactors/delivery/DeliveryHistoryInteractor";
import { ProductOnDelivery } from "../../interactors/delivery/DeliveryInfoInteractor";
import DeliveryInteractor, { ProductToReturn } from "../../interactors/delivery/DeliveryInteractor";
import DeliverySubmitInteractor from "../../interactors/delivery/DeliverySubmitInteractor";
import PresenterInterface from "../PresenterInterface";
import neighborhoodOnListInformation from "../utils/neighborhood";

type delivery_product_view_item = {
  id:string, 
  primary: string,
  secondary: string,
  max_delivery: number,
  quantity_already_received: number
}

type screen_info = {
  associated: {
    avatar: {
      color: string,
      letter: string
    },
    name: string,
    cep: string,
    id:string,
    neighborhood_name: string,
    delivery_address: string,    
    observations:string
  },
  delivery:{
    already_received: boolean,
    total: number,
    products: delivery_product_view_item[],
    return_products: delivery_product_view_item[],
    broken_products: delivery_product_view_item[],
    total_return: number,
    any_to_return: boolean
  }
}

type form_data = {
  observations: "",
  delivered: {[id:string]:number}
  returned: {[id:string]:number}
  broken: {[id:string]:number}
}
export default class DriverDeliveryPresenter implements PresenterInterface{
  storage_path:string
  constructor(public associated_id:string){
    this.storage_path = "driver_delviery/"+associated_id;
  }

  async getData(): Promise<screen_info>{
    let associated = await database.associated_db.find(this.associated_id);
    if(!associated) return this.defaultInformation();

    return {
      associated: await this.createAssociatedInformation(associated),
      delivery: await this.createDeliveryInformation(associated)
    }
  }

  static async formSubmit(data:form_data, associated_id:string){
    const deliveryData = {
      associatedId    : associated_id,
      observation     : data.observations,
      products   : Object.keys(data.delivered).map((id)=>({
        productId   : id,
        quantity      : data.delivered[id]
      })).filter((p)=> p.quantity > 0),
      returnedProducts: Object.keys(data.returned).map((id)=>({
        productId   : id,
        quantity      : data.returned[id]
      })).filter((p)=> p.quantity > 0),
      broken: Object.keys(data.broken).map((id)=>({
        productId     : id,
        quantity      : data.broken[id]
      })).filter((p)=> p.quantity > 0),
    }
    return DeliverySubmitInteractor.submitNewDelivery(deliveryData);
  }


  private defaultInformation():screen_info{
    return {
      associated: {
        avatar: {
          color: "#f0f0f0",
          letter: "S"
        },
        id: "<Inexistente>",
        name: "<Não Encontrado>",
        cep: "000000-00",
        neighborhood_name: "<Não Encontrado>",
        delivery_address: "<Não Encontrado>",    
        observations: "<Não encontrado>"
      },
      delivery:{
        already_received: false,
        total: 0,
        products: [],
        broken_products: [],
        return_products: [],
        total_return: 0,
        any_to_return: false
      }
    }
  }

  private async createDeliveryInformation(associated: Associated){
    let delivery_products = await DeliveryInteractor.shouldReceiveToday(associated.id);
    let return_prods = await DeliveryInteractor.shouldReturnToday(associated.id);

    let delivery_products_items = this.createToDeliverProductsInformation(delivery_products);
    let return_products_items   = this.createToReturnProductsInformation(return_prods);
    let broken_products_items   = this.createToReturnProductsInformation(return_prods);

    let history = await DeliveryHistoryInteractor.deliveryHistoryOnWeek(associated.id, new Date());
    if(history){
      delivery_products_items = await this.addHistoryInfoToDeliveryViewItems(delivery_products_items, history, "products");
      return_products_items   = await this.addHistoryInfoToDeliveryViewItems(return_products_items, history, "returnedProducts");
      broken_products_items   = await this.addHistoryInfoToDeliveryViewItems(broken_products_items, history, "broken");
    }
    
    return {
      products:         delivery_products_items,
      return_products:  return_products_items,
      broken_products:  broken_products_items,
      already_received: history !== null,
      total:         delivery_products_items.reduce((acc, product)=>acc+product.max_delivery, 0),
      total_return:  return_products_items.reduce((acc, product)=>acc+product.max_delivery, 0),
      any_to_return: return_products_items.length !== 0
    }
  }

  private async createAssociatedInformation(associated: Associated){
    let neighborhood = await associated.getNeighborhood();
    let neighborhood_info = neighborhoodOnListInformation(neighborhood);
    let observations:string = ""
    if(associated.delivery_observation) observations += associated.delivery_observation;
    let day_obs = await AssociatedInteractor.getDeliveryObservationOnDate(associated.id, new Date());
    if(day_obs) observations += " - " + day_obs;

    return {
      id: associated.id,
      avatar: neighborhood_info.avatar,
      name: associated.displayName,
      cep: associated.cep,
      neighborhood_name: neighborhood_info.name,
      delivery_address: associated.deliveryAddress,
      observations
    }
  }

  private createToDeliverProductsInformation(products:ProductOnDelivery[]):delivery_product_view_item[]{
    return products.map((product)=>{
      return {
        id: product.id,
        primary: product.name,
        secondary: product.flavorName,
        max_delivery: product.total,
        quantity_already_received: 0
      }
    })
  }

  private createToReturnProductsInformation(products: ProductToReturn[]):delivery_product_view_item[]{
    return products.map((product)=>{
      return{
        id: product.id,
        primary: product.returnableName,
        secondary: product.name + (product.required ? " (Obrigatorio)" : " (Opcional)"),
        max_delivery: product.total,
        quantity_already_received: 0
      }
    })
  }

  private async addHistoryInfoToDeliveryViewItems(products_to_deliver:delivery_product_view_item[], history: Associated_Delivery, type: "returnedProducts" | "broken" | "products"){
    let create_function = this.returnProductItem
    if(type === "products") create_function = this.deliveryProductItem;

    for(let product of Object.values(history[type]) ){
      products_to_deliver = await this.updateAlreadyDeliveredProduct(
        products_to_deliver, product,
        create_function
      );
    }
    return products_to_deliver;
  }

  private async updateAlreadyDeliveredProduct(product_list:delivery_product_view_item[], product:DeliveredProduct, createItemF:any){
    let index = product_list.findIndex( (p) => p.id === product.productId);
    if(index !== -1){
      product_list[index].quantity_already_received = product.quantity;
    }
    else {
      let new_item = await createItemF(product.productId, product.quantity, product.quantity);
      if(new_item) product_list.push(new_item)
    }
    return product_list;
  }

  private async returnProductItem(productId:string, max_quantity:number, delivered:number){
    let product = await database.product_db.find(productId);
    if(!product) return null;
    return {
      id: product.id,
      primary: product.returnableName,
      secondary: product.name,
      max_delivery: max_quantity,
      quantity_already_received: delivered
    }
  }

  private async deliveryProductItem(productId:string, max_quantity:number, delivered:number){
    let product = await database.product_db.find(productId);
    if(!product) return null;
    let flavor = await database.flavor_db.find(product.flavorId);
    if(!flavor) flavor = Flavor.defaultFlavor();
    return {
      id: product.id,
      primary: product.name,
      secondary: flavor.name,
      max_delivery: max_quantity,
      quantity_already_received: delivered
    }
  }

}