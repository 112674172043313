import { ProductOnDelivery } from "../../interactors/delivery/DeliveryInfoInteractor";
import ProductInteractor from "../../interactors/ProductInteractor";

export async function formatProductListInfoArray(products:ProductOnDelivery[]){
  let retArray = []
  for(let product of products){
    retArray.push(await formatProductListInfo(product))
  }
  return retArray;
}

export async function formatProductListInfo(product:ProductOnDelivery){
  return {
    id: product.id,
    name: product.name,
    total: product.total,
    intolerant_count: product.intolerants,
    have_any_intolerant: product.intolerants !== 0,
    flavor_name: await ProductInteractor.getProductFlavorName(product),
    image_url: product.imageUrl
  }
}