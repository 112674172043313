import Neighborhood from "../../entities/Neighborhood";
import NeighborhoodDBInterface from "../interfaces/NeighborhoodDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";

export default class NeighborhoodFirebase implements NeighborhoodDBInterface{
  
  async find(id:string):Promise<Neighborhood | undefined>{
    return findWithRedux(id, "neighborhood", Neighborhood);
  }

  async all():Promise<Neighborhood[]>{
    return allWithRedux("neighborhood", Neighborhood);
  }
}