import database from "../database/DataBaseConfig";
import AppConfigs from "../entities/AppConfigs";

export async function getAppConfigValue(config_name: string){
  let app_configs:any = await database.appconfigs_db.get();
  let config = app_configs[config_name] || "";
  return config;
}

export async function getAllAppConfigs():Promise<AppConfigs>{
  return database.appconfigs_db.get(); 
}

export function updateConfigLocally(config_name: string, new_value: string, configs: AppConfigs): AppConfigs{
  let cf:any = configs;
  cf[config_name] = new_value;
  return cf;
}

export async function updateConfigs(configs: AppConfigs){
  return database.appconfigs_db.update(configs);
}