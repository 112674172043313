import { subDays } from "date-fns";

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});
}


export function compareStringName(a,b){
    return compareString(a.props.name, b.props.name)
}

export function compareStringDisplayName(a,b){
    return compareString(a.props.displayName, b.props.displayName)
}

export function compareString(a,b){
    if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
    }
    return 0;
}

export function normalizeDate(date){
    return subDays(date, date.getDay());
}
export function compareNormalizedDays(date1, date2){
    return normalizeDate(date1).toDateString() === normalizeDate(date2).toDateString()
}
