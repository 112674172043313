import { addMonths, addWeeks, getMonth, isSameMonth, setDay, setMonth, startOfMonth } from "date-fns";
import database from "../database/DataBaseConfig";
import Associated, { IndividualPurchase } from "../entities/Associated";
import Product from "../entities/Product";
import Receipt, { Bill } from "../entities/Receipt";
import DeliveryInfoInteractor from "./delivery/DeliveryInfoInteractor";


export async function calculateNewReceipt(associated_id:string):Promise<Receipt>{
  let associated = await database.associated_db.find(associated_id);
  if(!associated) throw new Error("Associado não encontrado");
  let base_price = await calculatePlanPrice(associated);
  let bills = await getNewBills(associated);
  // Setter
  let new_receipt = new Receipt("<null>", {});

  new_receipt.base_value = String(base_price);
  new_receipt.bills = bills;

  new_receipt.associatedId = associated_id;
  new_receipt.date = new Date();
  // credits
  new_receipt.credits_before_confirmation = associated.credits || "0";
  new_receipt.credits_after_confirmation = "<null>";
  
  return new_receipt;
}

async function getNewBills(associated:Associated):Promise<Bill[]>{
  let bills:Bill[] = []
  
  let next_month_suspended_days = associated.suspendedDates? associated.suspendedDates.filter((date)=>{
    return isSameMonth(date, setMonth(new Date(), getMonth(new Date()) + 1))
  }) : [];
  
  for(let suspended_date of next_month_suspended_days){
    let single_delivery_value = await singleDeliveryPrice(associated, suspended_date);
    bills.push(
      new Bill("<null>",{
        date: suspended_date.toISOString().slice(0,10), 
        price: {
          total: "-"+String(single_delivery_value),
          individual: String(single_delivery_value),
          quantity: 1
        },
        type: "suspended_days",
        name: "Dia suspenso " + suspended_date.toISOString().slice(0,10)
      })
    )
  }

  bills = bills.concat(
    await database.receipt_db.getAllBills(associated.id)
  );
  
  for(let prod of associated.individualPurchases){
    bills.push(
      await individualProductToBill(prod)
    )
  }

  return bills;
}
async function singleDeliveryPrice(associated:Associated, date: Date) {
  let delivery = await DeliveryInfoInteractor.planDeliveryInfo(associated, date)
  let products = Object.values(delivery.products);
  let total = 0;

  for(let prod of products){
    total += Number(prod.price) * Number(prod.total)
  }

  let neigh = await database.neighborhood_db.find(associated.neighborhoodId);

  total += Number(neigh?.taxPlan);

  return total;
}

async function calculatePlanPrice(associated:Associated):Promise<number>{
  let start_date = startOfMonth(new Date());
  let end_date = startOfMonth((new Date()).setMonth((new Date()).getMonth() + 1));

  let total = 0;
  
  start_date = setDay(start_date, associated.deliveryInfo.weekDay);
  if(!isSameMonth(start_date, new Date())){
    start_date = addWeeks(start_date, 1);
  }

  while( start_date < end_date ){
    total += await singleDeliveryPrice(associated, start_date);
    start_date = addWeeks(start_date, 1);
  }

  return total;
}

export async function calculatePlanPriceWithId(id:string):Promise<number>{
  return await calculatePlanPrice( await database.associated_db.find(id) as Associated);
}

async function individualProductToBill(prod:IndividualPurchase):Promise<Bill>{
  let product = await database.product_db.find(prod.productId) as Product;

  return new Bill("<null>",{
    date: (new Date()).toISOString().slice(0,10), 
    price: {
      total: String(prod.quantity * product.price),
      individual: String(product.price),
      quantity: prod.quantity
    },
    type: "individual_future_delivery",
    name: String(prod.quantity) + " - " + product.name
  })
}