import React from 'react';
import usePresenter from "../../app/redux/usePresenter";
import IndexFlavorPresenter from "../../app/presenters/admin/flavor/IndexFlavorPresenter";
import FlavorList from "../../app/components/templates/admin/flavor/FlavorList";
import LoadingCircle from "../../app/components/atoms/LoadingCircle";



function FlavorIndex() {
  let list = usePresenter(new IndexFlavorPresenter())

  if(!list){
    return <LoadingCircle show/>
  }

  return <FlavorList flavors={list} />
}

export default FlavorIndex;
