import {ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import React from "react";

export type ReceiptBillItemProps = {
  description: string,
  value: string,
  forceShow?: boolean,
}

export default function ReceiptBillItem(props:ReceiptBillItemProps&{idx:string}) {
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  if(Number(props.value) === 0 && !props.forceShow) return <></>;

  return (<div key={props.idx}>
    <ListItem>
      <ListItemText primary={props.description}/>
      <ListItemSecondaryAction>{formatter.format(Number(String(props.value).replace(",",".")))}</ListItemSecondaryAction>
    </ListItem>
  </div>)
}
