import React from "react";
import { Control, Controller, DeepMap, FieldError, useFieldArray } from "react-hook-form";
import { Button, IconButton, ListItem } from "@material-ui/core";
import { Divider, ListItemSecondaryAction, ListItemText, List } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FormSelect from "../../../atoms/FormSelect";

type flavor_fields = {
  name: string,
  id: string
}

type form_params  = { 
  control: Control<Record<string, any>>, 
  errors: DeepMap<Record<string, any>, FieldError>,
  register: any,
  watch: any,
  setValue: any,
  trigger:any,
  getValues: any,
  all_flavors: flavor_fields[],
}

const AssociatedAlergiesForm = ({control, all_flavors, getValues, watch, setValue, trigger, errors, register}:form_params) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: "intolerances",
  });

  const paymentPlan = watch("intolerances", fields);

  const flavor_options = all_flavors.map( (product) => {
    return {
      label: product.name, value: product.id
    }
  })
  return (
    <div>
      <List disablePadding>
        {paymentPlan.map((item:any, idx:any) => {
          if(!(item.confirmed === true || item.confirmed === "true")) return <></>
          let prod =  all_flavors.reduce((acc, prod)=> prod.id === item.flavorId ? prod : acc, {} as any)
          return (
            <ConfirmedProduct 
              id={"intolerances/"+idx} 
              primary={prod.name}
              secondary={""}
              index={idx}
              remove={remove}
            />
          )
        })}
      </List>

      {fields.map((item, index)=> {
        const confirmed = getValues(`${"intolerances"}[${index}].confirmed`);
        return (
          <SingleIntoleranceForm 
            key={`${"intolerances"}[${index}].productId`}
            show={!(confirmed === true || confirmed === "true")}
            index={index}
            control={control}
            errors={errors}  
            remove={remove}
            setValue={setValue}
            register={register}
            trigger={trigger}
            item={item}
            flavor_options={flavor_options}
          />
        )
      })}
      <IconButton
        size={"small"}
        onClick={() => {
          append({
            confirmed: false,
            flavorId: flavor_options[0].value || "", // Hardcoded 'kombucha plano'
          });
        }}
      >
        <AddCircleRoundedIcon style={{ fontSize: 30 }} />
      </IconButton>
    </div>
  )
}

export default AssociatedAlergiesForm

type SingleIntoleranceFormType = {
  show: boolean,
  index: number,
  control: any,
  errors: any,
  remove: any,
  setValue: any,
  item: any,
  flavor_options: any,
  register: any,
  key: any,
  trigger: any
}

const SingleIntoleranceForm = (props:SingleIntoleranceFormType) => {

  return (
    <div key={props.key} style={{display: !props.show ? "none" : "inline"}}>
      <FormSelect
        options={props.flavor_options}
        label="Produto"
        name={`${"intolerances"}[${props.index}].flavorId`}
        control={props.control}
        errors={props.errors}
      />
      
      <Controller
        render={()=> (<div></div>)}
        control={props.control}
        id="confirmed"
        name={`${"intolerances"}[${props.index}].confirmed`}
        required={false}
        fullWidth
        defaultValue={`${props.item.confirmed}`}
      />

      <Button
        variant="contained"
        color="secondary"
        onClick={() => props.remove(props.index)}
      >
        Delete
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          props.setValue(`${"intolerances"}[${props.index}].confirmed`, true)
          props.trigger("intolerances");
        }}
      >
        Confirm
      </Button>
    </div>
  )
}


type confirmed_prod_type = {
  id:string,
  primary: string,
  secondary: string,
  index: number,
  remove: any
}
const ConfirmedProduct = ({id, primary, secondary, index, remove}:confirmed_prod_type) => {
  return (
    <div id={id}>
      <ListItem>
        <ListItemText primary={primary} secondary={secondary} />
        <ListItemSecondaryAction>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={() => {
              remove(index)
            }}
          >
            <DeleteIcon  />
          </IconButton>

        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}