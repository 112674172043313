import {Divider, IconButton, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import {useHistory} from "react-router-dom";

export type FlavorListItemProps = {
    name: string,
    index: number,
    id: string,
    deleted: boolean|undefined
}

export default function FlavorListItem(props:FlavorListItemProps) {
    const history = useHistory()

    return (<div key={props.index}>
        <ListItem
            disabled={(props.deleted !== undefined && props.deleted)}
        >

            <ListItemText primary={props.name} />
            <ListItemSecondaryAction>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={()=>
                        history.push({
                            pathname: '/admin/flavor/create',
                            state: {id: props.id, name: props.name}
                        })
                    }
                >
                    <ArrowForwardIos />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
    </div>)

}
