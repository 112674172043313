


export default function frontEndReducer(state={presenter:{}, state_loaded: {}}, action){
  switch(action.type){
    case "app/title/update":
      return {
        ...state,
        title: action.payload
      }
    case "app/menuOptions/update":
      return {
        ...state,
        menuOptions: action.payload
      }
    case "app/updateState":
      return{
        ...state,
        presenter: {
          ...state.presenter,
          [action.path]: action.payload
        },
        state_loaded: {
          ...state.state_loaded,
          [action.path]: true
        }
      }
    case "app/resetState":
      return {
        ...state,
        presenter: {},
        state_loaded: {}
      }
    default:
      return state;
  }
}