import ReceiptIndexListItem, {ReceiptIndexListItemType} from "../atoms/admin/ReceiptIndexListItem";
import {List} from "@material-ui/core";
import React from "react";
import DeliveryHistoryListItem, {DeliveryHistoryListItemType} from "../atoms/shared/associated/DeliveryHistoryListItem";



export default function DeliveryHistoryList(props: {
    list: DeliveryHistoryListItemType[];
}) {
    return (
        <List>
            {props.list.map((listItem, index) => {
                return (
                    <div key={index}>
                        <DeliveryHistoryListItem {...listItem} />
                    </div>
                );
            })}
        </List>
    );
}