import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { isSameDay, isToday, isTomorrow } from "date-fns";
import { ExtendedFirebaseInstance, ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel, { ModelDict } from "./Application"
import Associated from "./Associated";
import Users from "./User";

type SystemAlertFields = {
  name: string,
  description: string, 
  imageUrl: string, 
  systemDescription: string,
  type: "BIRTHDAY" | "DELIVERY"
}

export default class SystemAlert extends ApplicationModel {

  public static modelName = "systemAlerts";

  constructor(
    public db: ExtendedFirestoreInstance,
    public props: SystemAlertFields,
    public docRef: DocumentReference<DocumentData>
  ) {
    super(db, props, docRef)
  }

  static async updateWithImage(db: ExtendedFirestoreInstance, firebase:ExtendedFirebaseInstance ,id:string, props:any, image:File){
    let alert = await SystemAlert.find(db, id);
    if(alert){
      try{
        // Delete storage image
        let oldImageUrl = alert.props.imageUrl
        if(oldImageUrl){
          let imageRef = firebase.storage().refFromURL(oldImageUrl);
          if(imageRef) await imageRef.delete();
        }
      }catch(e){}

      // Create new File on storage and change its name to avoid conflict
      const newFile = new File([image], Math.random().toString(36).substring(7) + image.name, {type: image.type});
      const uploadedImage = await firebase.uploadFile("avisos", newFile);
      const newImageUrl = await uploadedImage.uploadTaskSnapshot.ref.getDownloadURL();  

      await SystemAlert.update(db, id, {
        ...props,
        imageUrl: newImageUrl
      })
    }
  }

  static async getAlertsFor(db:ExtendedFirestoreInstance, associated:Associated):Promise<SystemAlert[]>{
    let nextDeliveryDate;
    let user = await Users.find(db, associated.docRef.id);
    let returnArray:SystemAlert[] = []


    try {
      nextDeliveryDate = associated.delivery.getNextDeliveryDate();  
      console.log(nextDeliveryDate);
      if(isTomorrow(nextDeliveryDate)){
        let alert = await SystemAlert.search(db, "type", "DELIVERY")
        if(Object.values(alert).length !== 0){
          returnArray.push(Object.values(alert)[0])
        }
      }
    } catch (error) { }
    
    if(user){
      let birthday = user.props.birthdate.toDate()
      let today = new Date()
      today.setFullYear(0);
      birthday.setFullYear(0);
      if(isSameDay(birthday, today)){
        console.log("HAPPY BIRTHDAY");
        let alert = await SystemAlert.search(db, "type", "BIRTHDAY")
        if(Object.values(alert).length !== 0){
          returnArray.push(Object.values(alert)[0])
        }
      }
    }

    return returnArray
  }
}
