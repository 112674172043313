import { ExtendedFirestoreInstance } from "react-redux-firebase";
import Associated, { IndividualPurchase, DeliveryFields, PaymentPlanItem } from "../../Associated";
import Products from "../../Products";
import {ModelDict} from "../../Application";
import {
	DocumentData
} from "@firebase/firestore-types";
import { format as formatDate, addDays, isSameDay, isBefore } from "date-fns";
import SuspendedDays from "./SuspendedDays";

import {db} from "../../../Firebase"
import { shouldAssociatedReceiveDay, shouldAssociatedReceiveOnWeek, shouldReceiveBiweeklyProductOnWeek } from "../../../../utils/associated_delivery_days";
import { compareDeliveryDates, normalizeDeliveryDate } from "../../../../utils/date_manipulation";
import { compareNormalizedDays } from "../../../../utils";

function searchObjectArray < T extends { [id: string]: any } > (array: T[], key: string, query: string): T | undefined {
	for (let i = 0; i < array.length; i += 1) {
		if (array[i][key] === query) {
			return array[i]
		}
	}
}

type DeliveryTypes =  "nextDelivery" | "futureDelivery" | "suspended" | "alreadyReceived" | "nextSuspended" | "notReceive"

export type deliveryInfo = {
	totalProducts: number,
	deliveryType: DeliveryTypes,
	products: {
		[id: string]: {
			flavorName?: string,
			intolerants: number,
			total: number,
			intolerant: boolean,
		} &
		Products
	}
}

export default class DeliveryHelper {
  constructor(public db:ExtendedFirestoreInstance, public associated:Associated){}

	async lastDeliveryInfo() {
		const lastDeliveryRef = await this.db.collection("associated/" + this.associated.docRef.id + "/delivery").orderBy('date', "desc").limit(1).get()
		if (lastDeliveryRef.docs[0])
			return lastDeliveryRef.docs[0].data()
	}

	async lastDeliveryDate() : Promise<Date | undefined> {
		if(this.associated.props.deliveryInfo.lastDeliveryDate){
			return this.associated.props.deliveryInfo.lastDeliveryDate.toDate();
		}
		else{
			let lastDelivery = await this.lastDeliveryInfo();
			if(lastDelivery){
				return lastDelivery.date.toDate();
			}
		}
	}

	async getAssociatedFullDeliveryInfo(date: Date, products?:any){
		// Define types for this function
		let deliveryInfo: deliveryInfo = {
			deliveryType: "nextDelivery",
			totalProducts: 0,
			products: {}
		}
		if (!products)
			products = await Products.all(this.db);
		deliveryInfo = await this.addItemsFromPlan(date, deliveryInfo, products);
		deliveryInfo = await this.addItemsFromIndividualPurchases(deliveryInfo, products);
		return deliveryInfo;
	}

	async getDeliveryInfo(date: Date, products?:any): Promise<deliveryInfo>{

		// Define types for this function
		let deliveryInfo: deliveryInfo = {
			deliveryType: "futureDelivery",
			totalProducts: 0,
			products: {}
		}

		if (!products)
			products = await Products.all(this.db);

		let deliveryType = await this.generateDeliveryType(date);
		if(deliveryType === "suspended" || deliveryType === "notReceive"){}
		else if(deliveryType === "alreadyReceived"){
			let pastDelivery = await this.getPastDelivery(date)
			deliveryInfo = this.transformPastDeliveryToDeliveryInfo(pastDelivery, products)
		}
		else if(deliveryType === "nextDelivery"){
			deliveryInfo = await this.addItemsFromPlan(date, deliveryInfo, products);
			deliveryInfo = await this.addItemsFromIndividualPurchases(deliveryInfo, products);
		}
		else if(deliveryType === "futureDelivery"){
			deliveryInfo = await this.addItemsFromPlan(date, deliveryInfo, products);
		}

		deliveryInfo.deliveryType = deliveryType;
		return deliveryInfo
	}

	async receiveDelivery(deliveryData: DeliveryFields) {
		// Create delivery doc
		deliveryData.date = SuspendedDays.normalizeDate(deliveryData.date);
		await this.associated.docRef.collection("delivery").add(deliveryData);

		// Update pending items
		let pendingItems: IndividualPurchase[] = [];

		if (this.associated.props.deliveryInfo.pendingItems) {

			this.associated.props.deliveryInfo.pendingItems.forEach(item => {
				let id = item.productId;
				let returned = searchObjectArray(deliveryData.returnedProducts, "productId", id)
				if (!returned) {
					pendingItems.push(item)
				} else if (item.quantity - returned.quantity > 0) {
					pendingItems.push({
						productId: id,
						quantity: item.quantity - returned.quantity
					})
				}
			})

		} else {
			pendingItems = deliveryData.products
		}

		Associated.update(this.db, this.associated.docRef.id, {
			deliveryInfo: {
				...this.associated.props.deliveryInfo,
				pendingItems
			}
		})
	}

	getNextDeliveryDate(skipWeek=false):Date{
		let today = new Date()
		let deliveryWeekDay = this.associated.props.deliveryInfo.weekDay;

		let nextPossibleDeliveryDate = normalizeDeliveryDate(today);
		let weekpassed = 0
		nextPossibleDeliveryDate = addDays(nextPossibleDeliveryDate, deliveryWeekDay);

		if(skipWeek) nextPossibleDeliveryDate = addDays(nextPossibleDeliveryDate, 7);
		
		while(! shouldAssociatedReceiveOnWeek(nextPossibleDeliveryDate, this.associated.props)){
			nextPossibleDeliveryDate = addDays(nextPossibleDeliveryDate, 7);
			weekpassed += 1;
			if(weekpassed > 6) {
				throw new Error("DateError: Assciado nunca ira receber entrega");
				
			}
		}
		return nextPossibleDeliveryDate;
	}

	/**** PRIVATE ****/
	/**  100% estavel e testada.
	*		retorna tipo de entrega no dia especificado
	*/
	private async generateDeliveryType(date:Date):Promise<DeliveryTypes>{
		if (await this.associated.suspendedDays.dayIsSuspended(date)){
			return "suspended"
		};
		if (this.associated.props.paymentPlan.length === 0){
			if (this.associated.props.individualPurchases.length === 0){
				return "suspended"
			}else{
				return "nextDelivery"
			}
		}

		let today = new Date();
		today.setHours(0,0,0,0)
		if(isBefore(date, today)) return "alreadyReceived";

		// Se o dia questionado for a ultima entrega, a pessoa ja recebe
		let last_delivery_date = await this.lastDeliveryDate()
		if(last_delivery_date){
			if(compareDeliveryDates(last_delivery_date, date)) return "alreadyReceived";
		}

		try {
			let skip_week = false
			if(last_delivery_date){
				skip_week = compareNormalizedDays(last_delivery_date, today)
			}
			let nextDeliveryDate = this.getNextDeliveryDate(skip_week);
			if(compareDeliveryDates(nextDeliveryDate, date))
				return "nextDelivery"
		} catch (error) {}

		let todayNumber = today.getDay();

		let last_delivery_was_not_today:boolean = true
		if(last_delivery_date) last_delivery_was_not_today = !compareDeliveryDates(last_delivery_date, today) 
		
		if(this.associated.props.deliveryInfo.weekDay < todayNumber 	 && 
			last_delivery_was_not_today		   &&
			shouldAssociatedReceiveOnWeek(date, this.associated.props) &&
			compareDeliveryDates(date, addDays(today, 7)) 									// date eh semana que vem
		) return "nextDelivery";

		if(shouldAssociatedReceiveOnWeek(date, this.associated.props)) return "futureDelivery";

		return "notReceive"
	}

	private wasLastDeliveryLessThanWeek(date: Date, lastDeliveryData:DocumentData | undefined): boolean{
		let menosDeUmaSemana = false;

			if (lastDeliveryData ) {
				let lastDeliveryDate = new Date(lastDeliveryData.date)
				let oneWeekLater = addDays(lastDeliveryDate.getDate(), 7)

				// Se for menos de uma semana apos ultima entrega levanta flag da semana
				if (date <= oneWeekLater) {
					menosDeUmaSemana = true;
				}
			}
			
		return menosDeUmaSemana
	}

	public async addItemsFromPlan(date:Date, toAdd:deliveryInfo, products: ModelDict<Products>):Promise<deliveryInfo>{
		// Adiciona itens do plano
		this.associated.props.paymentPlan.forEach((PP) => {
			const productId = PP.productId
			let prodData = products[productId];

			if (PP.frequency === "weekly" || 
				 (PP.frequency === "biweekly" && shouldReceiveBiweeklyProductOnWeek(date, this.associated.props))
				) {
				toAdd.totalProducts += Number(PP.quantity)
				if(toAdd.products[productId]){
					toAdd.products[productId].total += Number(PP.quantity)
				}else{
					toAdd.products[productId] = {
						total: Number(PP.quantity),
						intolerants: 0,
						intolerant: this.isIntolerantTo(prodData.props.flavorId),
						...prodData
					}
				}
				if(this.isIntolerantTo(prodData.props.flavorId)){
					toAdd.products[productId].intolerants +=  Number(PP.quantity)
				}
			}
		})
		return toAdd;
	}

	public addItemsFromIndividualPurchases(toAdd : deliveryInfo, products:ModelDict<Products>):deliveryInfo{
		if (this.associated.props.individualPurchases) {
			this.associated.props.individualPurchases.forEach((individual_purch) => {
				let id = individual_purch["productId"]
				// Se o item adicional ja estiver na lista, basta aumentar a quantidade
				let prodData = products[id];
				toAdd.totalProducts += Number(individual_purch["quantity"])

				if (toAdd.products[id]) {
					toAdd.products[id].total += Number(individual_purch["quantity"])
				}
				else {
					// Caso contrario adicionar as informacoes desse produto na lista
					toAdd.products[id] = {
						total: Number(individual_purch["quantity"]),
						intolerant: this.isIntolerantTo(prodData.props.flavorId),
						intolerants: 0,
						...prodData
					}
				}

				if(this.isIntolerantTo(prodData.props.flavorId)){
					toAdd.products[id].intolerants +=  Number(individual_purch["quantity"])
				}
			})
		}
		return toAdd;
	}

	private async getPastDelivery(date:Date):Promise<DeliveryFields|undefined>{
		let associatedId = this.associated.docRef.id
		let collectionPath = "associated/"+associatedId+"/delivery"
		let normalizedDate = normalizeDeliveryDate(date);
		let query = this.associated.db.collection(collectionPath).where("date", "==", normalizedDate);
		let data = await query.get();

		if(!data.docs[0])
			return undefined;
		let delivery:DeliveryFields = {
			date,
			observation: "",
			products: [],
			returnedProducts: []
		}
		let accProducts:any = {};
		for(let i=0;i<data.docs.length;i+=1){
			let doc = data.docs[i].data();

			for(let p of doc.products){
				if(accProducts[p.productId]) 
					accProducts[p.productId].quantity += p.quantity;
				else 
					accProducts[p.productId] = p;
			}

			delivery.returnedProducts = delivery.returnedProducts.concat(doc.returnedProducts);
		}
		delivery.products = Object.values(accProducts);
		return delivery;
	}

	private transformPastDeliveryToDeliveryInfo(pastDelivery={} as DeliveryFields,  products: ModelDict<Products>):deliveryInfo{
		// Define types for this function
		let deliveryInfo: deliveryInfo = {
			deliveryType: "alreadyReceived",
			totalProducts: 0,
			products: {}
		}
		if(! pastDelivery.products) return deliveryInfo;

		for(let product of pastDelivery.products){
			deliveryInfo.totalProducts += product.quantity;
			const productId = product.productId
			let prodData = products[productId];
			deliveryInfo.products[productId] = {
				...products[product.productId],
				intolerants: this.isIntolerantTo(prodData.props.flavorId) ?  Number(product.quantity) : 0,
				intolerant: this.isIntolerantTo(prodData.props.flavorId),
				total: Number(product.quantity)
			}
		}

		return deliveryInfo
	}

	private isIntolerantTo(flavor:string){
		if(this.associated.props.intolerances === undefined)
			return false

		for(let intolerance of this.associated.props.intolerances){
			if(intolerance.flavorId === flavor) return true;
		}
		return false;
	}
}
