import React from "react";
import { Divider, makeStyles, Modal } from '@material-ui/core';
import ModalProductList from "../../molecules/ModalProductList";
import usePresenter from "../../../redux/usePresenter";
import { ProductsModalPresenter } from "../../../presenters/admin/drivers/ProductsModalPresenter";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: "10px",
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
}));

export type ModalProductsProps = {
  open: boolean
  setOpen: (x:boolean) => {},
  driverId:string, weekDay:number,
}

export default function ModalProductsOnDelivery({open, setOpen, driverId, weekDay}:ModalProductsProps) {
  const classes = useStyles();
  const presenter_data = usePresenter(new ProductsModalPresenter(driverId, weekDay));

  const handleClose = () =>{
    setOpen(false)
  }
  if(!presenter_data) return <div></div>
  return (    
    <Modal 
      open={open}
      onClose={handleClose}
      >
      <div className={classes.paper}>
        <h3>Total de entregas: {presenter_data.total_deliveries} </h3>
        <Divider />
        <ModalProductList 
          title={presenter_data.delivered.title}
          items={presenter_data.delivered.items}
        />
        <ModalProductList 
          title={presenter_data.returned.title}
          items={presenter_data.returned.items}
        />
      </div>
    </Modal>
  );
}
