import React from "react";
import {
  IconButton,
  Divider,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  withStyles,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
const StyledListItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "rgba(131,137,73,0.52)",
    },
  },
})(ListItem);

function AssociatedItem({ item, removeRedirect }) {
  const history = useHistory();
  return (
    <div
      key={item.id}
      onClick={() =>{ if(!removeRedirect) history.push("/driver/delivery/" + item.id) }}
    >
      <StyledListItem button selected={item.already_received}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: item.avatar.color || 0 }}>
            {item.avatar.letter}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.neighborhood + " - " + item.deliveryAddress} />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
          >
            <>
              { (item.delivery_complete === false || item.activate_warning) && (
                  <WarningRoundedIcon  style={{fontSize: 15}} />
                )}
              <ArrowForwardIos /> 
            </>
          </IconButton>
        </ListItemSecondaryAction>
      </StyledListItem>
      <Divider variant="inset" />
    </div>
  );
}


export default AssociatedItem;
