import React, { useEffect, useState } from 'react';

import {
  Divider,
  List,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import {
	useHistory
} from "react-router-dom";

import { useFirestore } from 'react-redux-firebase';
import Alert from '../../services/db/Alert';
import CarouselItem from '../../components/mural/CarouselItem';
import Carousel from 'react-material-ui-carousel';
import SystemAlert from '../../services/db/SystemAlert';

const useStyles = makeStyles((theme) => ({
  hText:  {
    margin          : theme.spacing(2),
    // backgroundColor: "#EBEBEB"
  },
}));

function AlertIndex() {
  const db = useFirestore();
  const classes = useStyles();
  const history = useHistory();
  let [loading, setLoading] = useState(true);
  let [systemAlerts, setSystemAlerts] = useState([]);
  let [alerts, setAlerts] = useState([]);

  useEffect(()=>{
    const fetchData = async () =>{
      const alertsFetched  = await Alert.all(db)
      const systemAlertFetched = await SystemAlert.all(db)
      
      setSystemAlerts(Object.values(systemAlertFetched));
      setAlerts(Object.values(alertsFetched));
      setLoading(false);
    }
    fetchData();
  },[db])

  return <div>
    {loading ? (<div className="loading-container"><CircularProgress /></div>) : (<>
    <div className={classes.hText}>
      <h3>{"Avisos do Sistema"}</h3>
    </div>
    <Carousel
      animation={"slide"}
    >
      {systemAlerts.map( (item, i) => (
        <div key={i} style={{margin:"10px"}}>
          <CarouselItem 
            onClick={()=>{history.push({pathname:'/admin/system_alert/edit/'+item.docRef.id, state:{ systemAlert: item.props }})}}
            item={item} 
          />
        </div>
      ))}
    </Carousel>
    <div className={classes.hText}>
      <h3>{"Avisos Gerais"}</h3>
    </div>
    <List>
      {
        alerts.map( (aviso, index) =>{
          return (<div key={index} style={{margin:"10px"}}>
              <CarouselItem 
                showDate
                item={aviso} 
                onClick={()=>{history.push({pathname:'/admin/aviso/create', state:{ aviso: aviso.props, id: aviso.docRef.id}})}}
              />
            <Divider variant="inset" component="li" />
          </div>)
        })
      }
      </List>
    </>)}
  </div>;
}

export default AlertIndex;
