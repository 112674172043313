import { ModalListItemProps } from "../../../components/atoms/admin/ModalListItem";
import database from "../../../database/DataBaseConfig";
import AssociatedInteractor from "../../../interactors/AssociatedInteractor";
import { ProductOnDelivery } from "../../../interactors/delivery/DeliveryInfoInteractor";
import DeliveryInteractor, { ProductToReturn } from "../../../interactors/delivery/DeliveryInteractor";
import PresenterInterface from "../../PresenterInterface";

type presenter_data = {
  total_deliveries: number,
  delivered: {
    title:string,
    items: ModalListItemProps[]
  },
  returned: {
    title:string,
    items: ModalListItemProps[]
  }
}

export class ProductsModalPresenter implements PresenterInterface{
  storage_path:string
  constructor(private driverId:string, private dayNumber: number){
    this.storage_path = "/modal_entrega#"+driverId+"##"+dayNumber;
  }

  defaultData():presenter_data{
    return {
      total_deliveries: 0,
      delivered:{
        title: "<Nao encontrado>",
        items: []
      },
      returned: {
        title: "<Nao encontrado>",
        items: []
      }
    }
  }


  async getData(): Promise<presenter_data>{
    let driver = await database.driver_db.find(this.driverId);
    if(!driver) return this.defaultData();
    let associateds = await database.associated_db.getWeekDayAssociatedsForDriver(this.dayNumber, this.driverId);

    associateds = associateds.filter((associated)=>{
      return AssociatedInteractor.associatedShouldReceiveDeliveryThisWeek(associated);
    })

    let reducer:ProductOnDelivery[] = [];
    let total_deliveries = associateds.length ;
    for(let associated of associateds){
      let products = await DeliveryInteractor.shouldReceiveToday(associated.id);

      reducer = DeliveryInteractor.combineProductOnDelivery(products, reducer);
    }

    let reducer_returned: ProductToReturn[] = [];
    for(let associated of associateds){
      let products = await DeliveryInteractor.getReceivedToday(associated.id);
      reducer_returned = DeliveryInteractor.combineProductToReturn(products, reducer_returned);
    }

    return {
      total_deliveries,
      delivered: {
        title: "Produtos a entregar por " + driver.displayName,
        items: reducer.map( (product, index) => {return {
          primary: product.name,
          alternative: product.intolerants,
          secondary: product.total,
          index
        }}),
      },
      returned: {
        title: "Produtos a devolver por " + driver.displayName,
        items: reducer_returned.map( (product, index) => {return {
          primary: product.name,
          secondary: product.total,
          index
        }}),
      }
    }
  }
}