import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container,CircularProgress, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@material-ui/core';

import NumericInput from 'react-numeric-input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import usePresenter from '../../app/redux/usePresenter';
import DriverDeliveryPresenter from '../../app/presenters/driver/DriverDeliveryPresenter';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  align:{
    verticalAlign: "middle",
    margin: "auto 10px"
  },
  adressContainer: {
    margin: theme.spacing(2),
    display: "flex"
  },
  adressLabels:{
    width: "30%",
    margin: "0 !important"
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    flexBasis: '80%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  hText:{
    margin: theme.spacing(2),
  },
  list:{
    width:"100%",
  },
  listItem:{
    paddingLeft: "0px !important"
  },
  acordeao:{
    paddingRight: "0px !important"
  },
  inputNumber:{
    width: "80px"
  }
}));

const getColor = (v1, v2) => {
  if(v1 === v2) return "green"
  return "red"
}

export default function DriverDelivery(){
  const classes   = useStyles();
  const history   = useHistory();

  let [loading, setLoading] = useState(false)
  const [form, setForm]     = useState( {delivered: {}, returned: {}, broken: {}, observations: ""})

  let params          = useParams();
  let {associated_id} = params;
  const data = usePresenter(new DriverDeliveryPresenter(associated_id))
  
  useEffect(()=>{
    if(data !== undefined){
      const getInitialFormValue = (list) => list.reduce((acc, prod)=> {acc[prod.id] = prod.quantity_already_received;return acc}, {})
      setForm({
        ...form,
        delivered: getInitialFormValue(data.delivery.products),
        returned: getInitialFormValue(data.delivery.return_products),
        broken: getInitialFormValue(data.delivery.broken_products)
      })
    }
  }, [data])

  const formSubmit = async () =>{
    if(data.delivery.already_received){
      if(!window.confirm("Entrega ja feita hoje, deseja atualizar a entrega?")) return;
    }
    let totalDelivered = Object.values(form.delivered).reduce((acc, cv) => acc+cv, 0)
    if(totalDelivered !== data.delivery.total ){
      if(!window.confirm("Faltam produtos na entrega, deseja prosseguir?")) return;
    }
    // Create delviery data
    if(Object.values(form.broken).reduce((acc, cv) => acc+cv, 0)){
      if(!window.confirm("Foram adicionados produtos quebrados, deseja confirmar?")) return;
    }
    setLoading(true);
    let resp = await DriverDeliveryPresenter.formSubmit(form, data.associated.id); 
    // window.confirm(resp);
    history.goBack();
  }

  
  const updateForm = (type,id, newValue) => {
    setForm({
      ...form,
      [type]: {
        ...form[type],
        [id]: newValue
      }
    })
  }

  if(data === undefined || loading){
    return <div className="loading-container"><CircularProgress /></div>
  }

  return(
    <div>
        { data.delivery.already_received && (
          <><Divider />
          <div className={classes.hText}>
            <h3 style={{color:"#838949"}}>{"Entrega Feita"}</h3>
          </div> <Divider /></> 
        )}
        <div className={classes.root}>
          <Avatar style={{backgroundColor: data.associated.avatar.color }}>
            { data.associated.avatar.letter}
          </Avatar>
          <span className={classes.align}>
            { data.associated.name}
          </span>
        </div>
        <Divider />
        <div className={classes.adressContainer} >
          <div className={classes.adressLabels}>
            <div> {"CEP:"}      </div>
            <div> {"Bairro:"} </div>
            <div> {"Endereço:"} </div>
            {data.associated.observations && (
              <div> {"Observações:"} </div>
            )}
          </div>
          <div  className={classes.adressInfos}>
            <div> { data.associated.cep}      </div>
            <div> {data.associated.neighborhood_name} </div>
            <div> {data.associated.delivery_address} </div>
            {data.associated.observations && (
              <b>  {data.associated.observations} </b>
            )}
          </div>
        </div>
        <Divider />
        {/******** ENTREGAS **********/}
        < DeliveryList
          type="delivered"
          products={data.delivery.products}
          title="Entregar"
          formUpdater={updateForm}
          formState={form}
        />
      
        {/******** FIM-ENTREGAS **********/}
        { data.delivery.any_to_return &&  (<>
          < DeliveryList
            type="returned"
            products={data.delivery.return_products}
            title="Recolher"
            formUpdater={updateForm}
            formState={form}
          />
          <DeliveryList 
            type="broken"
            products={data.delivery.return_products}
            title="Itens quebrados"
            formUpdater={updateForm}
            formState={form}
            notShowHeaderNumber={true}
          />
        </>)}

        <div style={{display:"flex", margin:"20px", justifyContent:"center"}}>
          <TextField
            id="outlined-multiline-static"
            label="Observações"
            multiline
            style={{width:"100%"}}
            rows={4}
            defaultValue=""
            variant="outlined"
            onChange={(event)=>{
              setForm({...form, observations: event.target.value})
            }}
          />
        </div>

        <div style={{display:"flex", margin:"auto", justifyContent:"center"}}>
          <Button variant="contained" color="primary" onClick={formSubmit}>
            {data.delivery.already_received ? "Atualizar Entrega" : "Confirmar"}
          </Button>
        </div>
    </div>
  )
}


function DeliveryList({title, type, products, formUpdater, formState, notShowHeaderNumber}){
  const classes   = useStyles();

  const total_header = products.reduce((acc, product)=>{
    return acc + product.max_delivery
  }, 0)

  const total_deliverd = Object.values(formState[type]).reduce((acc, delivered)=>acc+delivered, 0)

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <Typography className={classes.heading}>{title}</Typography>
        { !notShowHeaderNumber && 
          <Typography className={classes.secondaryHeading} style={{color: getColor(total_deliverd, total_header)}}>
            {total_deliverd}/{total_header}
          </Typography>
        }
      </AccordionSummary>
      <AccordionDetails className={classes.acordeao}>
        <List dense={false} className={classes.list}>
          {products.map( (product) => (<>
            <DeliveryListItem 
              
              type={type}
              product={product} 
              onChange={(valueAsNumber)=>{
                formUpdater(type, product.id, valueAsNumber)
              }}
              formState={formState}
            />
          </>))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

function DeliveryListItem({type, product, onChange, formState}){
  const classes   = useStyles();
  return (<div key={type + product.id}>
    <ListItem className={classes.listItem}>
      <ListItemText
        primary={product.primary}
        secondary={product.secondary}
      />
      <ListItemSecondaryAction>
        <div style={{display:"flex"}}>
          <div style={{margin:"auto 10px"}}>
            <span>{product.max_delivery} uni</span>
          </div>
          <div style={{margin:"auto", display:"flex", flexDirection:"column"}}>
            <NumericInput
              mobile
              style={numberInputStyle}
              max={product.max_delivery}
              min={0}
              onChange={onChange}
              value={formState[type][product.id] || 0}
              step={ 1 }
              title={"Quantidade"}
              className={classes.inputNumber + " form-control"}
            />
            <span style={{margin:"auto"}}>Recolhido</span>
          </div>
        </div>
      </ListItemSecondaryAction>
    </ListItem>
    <Divider variant="inset" />
  </div>)
}

const numberInputStyle = {
  wrap: {
      padding: '0px 8px',
      borderRadius: '6px',
      fontSize: 16
  },
  input: {
      height: "35px",
      padding: "auto",
      border: "0px",
  },
  'input:focus' : {
      border: '0px',
      outline: 'none'
  },
  btn:{
    background: "white",
    width: "1000px"
  },
}
