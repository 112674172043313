import React, { useEffect, useState } from "react";

import {
  IconButton,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";

import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { compareStringName } from "../../utils/index";
import Products from "../../services/db/Products";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

function ProductsIndex() {
  const db = useFirestore();
  let [loading, setLoading] = useState(true);
  let [allProducts, setAllProducts] = useState([]);
  let [products, setProducts] = useState([]);
  let [products_deleted, setProductsDeleted] = useState([]);
  let [query, setQuery] = useState("");
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const productsFetched = await Products.all(db);
      
      let all_products = Object.values(productsFetched).sort(compareStringName);
      setProducts(all_products.filter((product)=> product.props.deleted === false));
      setAllProducts(all_products);
      setProductsDeleted(all_products.filter((product)=> product.props.deleted === true));

      setLoading(false);
    };
    fetchData();
  }, [db]);

  const handleChange = (evt) => {
    let newValue = evt.target.value;
    setQuery(newValue);
    setProducts(
      allProducts.filter((product) =>
        product.props.name.toLowerCase().startsWith(newValue.toLowerCase()) && product.props.deleted === false
      )
    );

    setProductsDeleted(
      allProducts.filter((product)=>
        product.props.name.toLowerCase().startsWith(newValue.toLowerCase()) && product.props.deleted === true
      )
    )
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="simple tabs full width">
            <Tab label="Ativos" {...a11yProps(0)}/>
            <Tab label="Inativos" {...a11yProps(1)}/>
          </Tabs>
          <TextField
            id="filled-secondary"
            label="Procurar"
            variant="filled"
            fullWidth={true}
            value={query}
            onChange={handleChange}
            color="primary"
          />
          <TabPanel value={value} index={0}>
            <List>
              {products.map((product) => {
                return (
                  <ProductOnList 
                    product_props={product} 
                    key={"product/index/" + product.docRef.id}
                  />
                );
              })}
            </List>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <List>
              {products_deleted.map((product) => {
                return (
                  <ProductOnList 
                    product_props={product} 
                    key={"product/index/" + product.docRef.id}
                  />
                );
              })}
            </List>
          </TabPanel>
        </>
      )}
    </div>
  );
}

export default ProductsIndex;


function ProductOnList(props){
  const history = useHistory();
  const {product_props} = props;
  const product = product_props;

  return (
    <div>
      <ListItem
        onClick={() => {
          history.push({
            pathname: `/admin/product/edit/${product.docRef.id}`,
            state: { product: { ...product.props } },
          });
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt={product.props?.name}
            src={product.props?.imageUrl}
          />
        </ListItemAvatar>
        <ListItemText
          primary={product.props.name}
          secondary={product.props.description}
          primaryTypographyProps={{style:{color: product.props.deleted ? 'red' : 'black'}}}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={() => {
              history.push({
                pathname: `/admin/product/edit/${product.docRef.id}`,
                state: { product: { ...product.props } },
              });
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}

function a11yProps(index) {
  return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

function TabPanel(props) {
  const {
      children,
      value,
      index,
      ...other
  } = props;

  return (<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {
          value === index && (<div>
              {children}
          </div>)
      }
  </div>);
}
