import AppConfigs from "../../entities/AppConfigs";
import AppConfigsDBInterface from "../interfaces/AppConfigsDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";
import {db} from "../../../services/Firebase";
import { deleteStorage } from "../../redux/backendStorage";

const COLLECTION_NAME = "configs"
const CONFIG_ID = "app"

export default class AppConfigsFirebase implements AppConfigsDBInterface{
  
  async get(): Promise<AppConfigs> {
    return findWithRedux(CONFIG_ID, COLLECTION_NAME, AppConfigs);
  }

  async update(configs: AppConfigs): Promise<boolean> {
    await db.collection(COLLECTION_NAME).doc(CONFIG_ID).update(configs);
    deleteStorage(COLLECTION_NAME + "/" + CONFIG_ID);
    return true;
  }
}