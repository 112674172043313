import {
  ExtendedFirestoreInstance
} from "react-redux-firebase";
import {
  DocumentData,
  DocumentReference,
  Query
} from "@firebase/firestore-types";

export type ModelDict < T > = {
  [id: string]: T
}
export type StaticThis < T > = {
  new(...x: any): T, modelName:string
};

export default class ApplicationModel {

  public static modelName: string;

  constructor(public db: ExtendedFirestoreInstance, public props: DocumentData, public docRef: DocumentReference < DocumentData > ) {
  }

  static async find < T extends ApplicationModel > (this: StaticThis < T > ,
    db: ExtendedFirestoreInstance,
    id: string
  ): Promise < T | undefined > {
    const modelName = this.modelName;

    let doc = db.collection(modelName).doc(id);
    let item = await doc.get();

    return new this(db, item.data(), doc);
  }

  static async create < T extends ApplicationModel > (this: StaticThis < T > , db: ExtendedFirestoreInstance,
    data: DocumentData): Promise < T > {
    const modelName = this.modelName;
    let newModel = await db.collection(modelName).add(data);
    let savedModel = await newModel.get();
    return new this(db, savedModel.data(), newModel);
  }

  static async delete < T extends ApplicationModel > (this: StaticThis < T > , db: ExtendedFirestoreInstance,
    id: string) {
    const modelName = this.modelName;
    let doc = db.collection(modelName).doc(id);
    await doc.delete()
  }

  static async update < T extends ApplicationModel > (this: StaticThis < T > , db: ExtendedFirestoreInstance,
    id: string, data: DocumentData) {
    const modelName = this.modelName;
    let doc = db.collection(modelName).doc(id);
    await doc.set(data, {
      merge: true
    });
  }

  static async search < T extends ApplicationModel > (this: StaticThis < T > , db: ExtendedFirestoreInstance,
    field: string, q: any): Promise < ModelDict < T >> {
    const modelName = this.modelName;
    let query = db
      .collection(modelName)
      .where(field, "==", q);

    const data = await query.get();
    let ret: ModelDict < T > = {}

    await Promise.all(data.docs.map(async obj => {
      ret[obj.id] = new this(db, obj.data(), obj)
    }))

    return ret;
  }

  static async all < T extends ApplicationModel > (this: StaticThis < T > , db: ExtendedFirestoreInstance): Promise < ModelDict < T >> {
    const modelName = this.modelName;
    let query = db
      .collection(modelName)

    const data = await query.get();
    let ret: ModelDict < T > = {}
    await Promise.all(data.docs.map(async obj => {
      ret[obj.id] = new this(db, obj.data(), obj)
    }))

    return ret;
  }

  static async searchWithQuery < T extends ApplicationModel > (this: StaticThis < T > , 
      db: ExtendedFirestoreInstance, 
      query: Query<DocumentData>
      ): Promise < ModelDict < T >> {
    const data = await query.get();
    let ret: ModelDict < T > = {}
    await Promise.all(data.docs.map(async obj => {
      ret[obj.id] = new this(db, obj.data(), obj)
    }))
    return ret;
  }
}