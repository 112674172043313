import React from 'react';
import { ListItem, ListItemText, Grid} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';
import { getDate, getMonth, getYear, getMinutes, getHours } from 'date-fns'

const useStyles = makeStyles({


    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },


});

export default function ChatMessage({alignment, text,time, index}){
    const classes = useStyles()

    const returnFormatedDate = (time) =>{
        const day = getDate(time)
        const month = getMonth(time) + 1
        const year = getYear(time)
        const minutes = getMinutes(time) < 10 ? "0"+ getMinutes(time): getMinutes(time)
        const hours = getHours(time)
        return hours + ":" + minutes + " - " + day + "/" + month + "/" + year
    }

    return (<ListItem key={index} >
        <Grid container >
            <Grid item xs={12} >
                <ListItemText align={alignment} primary={text}></ListItemText>
            </Grid>
            <Grid item xs={12}>
                <ListItemText  align={alignment} secondary={returnFormatedDate(time)}></ListItemText>
            </Grid>
        </Grid>
    </ListItem>)
}
