import {Control, DeepMap, FieldError} from "react-hook-form";
import FormField from "../../../atoms/FormField";
import React from "react";


type form_params  = { 
  control: Control<Record<string, any>>, 
  errors: DeepMap<Record<string, any>, FieldError>,
  register: any,
  defaultValues?: {
    cpf: string,
    cep: string,
    phone: string,
    email: string,
    address: string,
    birthdate: string,
    observation: string,
      baseDeliveryDate:string
  }
}

const AssociatedFormBody = ({control, errors, defaultValues,register}:form_params) => {
  return (
    <div>
      <FormField
        control={control} 
        errors={errors} 
        label={"CPF/CNPJ"}
        field_name={"cpf"} 
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"CEP"}
        field_name={"cep"} 
        mask={"99999-999"}
      />
       <FormField
        control={control} 
        errors={errors} 
        label={"Telefone"}
        field_name={"phone"} 
        mask={"(99) 99999-9999"}
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Email"}
        field_name={"email"} 
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Endereço"}
        field_name={"address"} 
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Data de nascimento"}
        field_name={"birthdate"} 
        type={"date"}
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Data de entrada"}
        field_name={"entry_date"} 
        type={"date"}
      />

        <FormField
        control={control}
        errors={errors}
        label={"Data da proxima entrega de quinzenal"}
        field_name={"deliveryInfo.baseDeliveryDate"}
        type={"date"}
      />
      
      <FormField
        control={control} 
        errors={errors} 
        required={false}
        label={"Senha"}
        field_name={"password"} 
        type={"password"}
      />
      <FormField
        required={false}
        control={control} 
        errors={errors} 
        label={"Observação"}
        field_name={"observation"}
      /> 
      {/** TODO: Faltando alergia */}
    </div>
  )
}

export default AssociatedFormBody