import database from "../../database/DataBaseConfig";
import Associated_Delivery from "../../entities/Associated_Delivery";

export default class DeliverySubmitInteractor{

  static async submitNewDelivery(data:any):Promise<boolean>{
    let delivery = new Associated_Delivery(data.associatedId, data);

    return database.associated_delivery_db.handleDelivery(delivery);
  }


}