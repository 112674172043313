import {Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import React from "react";
import {DailyShippingProductData} from "../../../presenters/driver/DailyShippingPresenter";


export type DailyShippingListItemType = {
    index: number,
    associateds_with_allergie: {name:string, quantity:number}[]
} & DailyShippingProductData


export default function DailyShippingListItem(props : DailyShippingListItemType){

    return (<div key={props.index}>
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    src={props.image_url}
                    alt={props.name}
                />
            </ListItemAvatar>
            <ListItemText 
                primary={props.name} 
                secondary={(
                    <>
                        <>{props.total - props.intolerant_count} - {props.flavor_name}</><br />
                        {props.intolerant_count !== 0 ? (<>{props.intolerant_count} - Sabor Alternativo</>) : 0 }<br />
                        {props.associateds_with_allergie.map((allergic)=>
                            <><span style={{marginLeft: "20px"}}>{allergic["quantity"]}{" - "}{allergic["name"]}</span><br /></>
                        )}
                    </>
                )} 
            />
            <ListItemSecondaryAction>{props.total}un</ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
    </div>)

}