import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

/**
 * Recebe "options" no formato:
 * options = [
 * { value: 'chocolate', label: 'Chocolate' },
 * { value: 'strawberry', label: 'Strawberry' },
 * ]
 */

export default function SelectInput({
  name,
  options,
  label,
  error,
  helperText,
  required,
  control,
  defaultValue = "",
  shrink,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <FormControl
          // variant="outlined"
          margin="none"
          required={required}
          fullWidth
          error={error}
        >
          <InputLabel shrink={shrink} htmlFor="native-simple">
            {label}
          </InputLabel>
          <Select
            native
            label={label}
            {...props}
            {...rest}
            //value={state}
            //onChange={handleChange}
            inputProps={{
              name,
              id: "native-simple",
            }}
          >
            <option aria-label="None" value="" />
            {options.map((option) => {
              const { value, label } = option;
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            })}
          </Select>
          <FormHelperText>{error && helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
