import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import {
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";

import Products from "../../services/db/Products";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  loading: {
    display: "flex",
    marginTop: "100px",
    justifyContent: "center",
  },
  align: {
    verticalAlign: "middle",
    margin: "auto 10px",
  },
  hText: {
    margin: theme.spacing(2),
  },
  adressContainer: {
    margin: theme.spacing(2),
    display: "flex",
  },
}));

function IndividualPurchaseIndex() {
  const db = useFirestore();
  const history = useHistory();
  const classes = useStyles();

  let [loading, setLoading] = useState(true);
  let [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const productsFetched = await Products.search(db, "individual", true);

      setProducts(Object.values(productsFetched).filter((item)=>{return !item.props.tastable}));
      setLoading(false);
    };
    fetchData();
  }, [db]);

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Divider />
      <div className={classes.hText}>
        <h3>{"Todos os produtos"}</h3>
      </div>
      <Divider />
      <div>
        <List>
          {products.map((product, index) => (
            <div key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={product.props?.name}
                    src={product.props?.imageUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.props.name}
                  secondaryTypographyProps={{ noWrap: true }}
                  secondary={product.props.description}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      history.push(
                        "/associated/avulso/buy/" + product.docRef.id
                      );
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" />
            </div>
          ))}
        </List>
      </div>
    </div>
  );
}

export default IndividualPurchaseIndex;
