import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirebaseInstance, ExtendedFirestoreInstance } from "react-redux-firebase"
import DriverDeliveries from "./helpers/driver/DriverDeliveries"
import ApplicationModel from "./Application"
import Users from "./User"
import Neighborhood from "./Neighborhood"
import Associated from "./Associated"
import { getDay } from "date-fns"
import {compareNormalizedDays, compareStringDisplayName} from '../../utils/index'

type DriverFields = {
    image: string,
    name: string,
    cellphone: string,
    email: string,
    deliveries : Delivery[]
}

type Delivery = {
    weekDay : number,
    order : Array<string>
}

export default class Driver extends ApplicationModel {

  public static modelName = "driver";
  public deliveries:DriverDeliveries;
  public user:Users;

  constructor(
      public db: ExtendedFirestoreInstance,
      public props: DriverFields,
      public docRef: DocumentReference<DocumentData>
  ) {
      super(db, props, docRef)
      this.deliveries = new DriverDeliveries(this.db, this);

      this.updateAssociations();
  }

  async refresh(){
    let doc = this.db.collection("driver").doc(this.docRef.id);
    let item = await doc.get();
    this.props = item.data() as DriverFields;
    return this.props;
  }

  async updateAssociations(){
		// this.user 						= await Users.find(this.db, this.docRef.id) as Users;
	}

  static async getDriversListInfo(db: ExtendedFirestoreInstance){
    const driversFetched = await Driver.all(db);
    type ListDnd = {
      id: string,
      primary: string,
      secondary: string
    }
    const newDriverList:ListDnd[] = [];

    for(let driver of Object.values(driversFetched).sort(compareStringDisplayName)){
      driver.user 	= await Users.find(db, driver.docRef.id) as Users;
      newDriverList.push({
        id: driver.docRef.id,
        primary: driver.user.props.displayName,
        secondary: driver.getNumberOfDelivery()+" associados alocados"
      })
    }

    return newDriverList;
  }

    static async GetDriverDeliveries(db : ExtendedFirestoreInstance , driverRef : any) {
      const todayNumber = getDay(new Date())
      const query = db.collection("associated")
        .where("deliveryInfo.driverId", "==", driverRef)
        .where("deliveryInfo.weekDay", "==", todayNumber)

      const associateds = await Associated.searchWithQuery(db, query);
      const driver = await Driver.find(db, driverRef);

      let deliveryFromDriver = driver?.props.deliveries.find((delivery)=>delivery.weekDay === todayNumber);
      let order  = deliveryFromDriver ? deliveryFromDriver.order : [];

      let associatedsFiltered = await Associated.filterEmptyDelivery(Object.values(associateds), new Date());

      associatedsFiltered.sort((a, b)=>{
        let aIndex = order.indexOf(a.docRef.id);
        let bIndex = order.indexOf(b.docRef.id);
        return aIndex - bIndex;
      });


      return await Promise.all(associatedsFiltered.map( async associated => {
        const neighborhood = await Neighborhood.find(db, associated.props.neighborhoodId)
        let highlight = false;
        let last_delivery = await associated.delivery.lastDeliveryInfo();
        if(last_delivery){
          highlight = compareNormalizedDays(new Date(last_delivery.date), new Date())
        }
        return{
          secondary: neighborhood?.props.name + " - " + associated.props.deliveryAddress,
          primary: associated.props.displayName,
          color: neighborhood?.props.color,
          letter:  neighborhood?.props.name[0],
          highlight,
          sent: false,
          id: associated.docRef.id
        }
      }))
    }

    static async  SumDeliveryInfos(db : any , info_arr : any) {

        interface toRetInterface {
            total : number,
            products : {[index: string]:any}
        }

        let toRet : toRetInterface = {
            total : 0,
           products : {}
        };
        info_arr.forEach((info : any ) => {
            Object.keys(info.products).forEach((productId : any )  => {
                const product = info.products[productId];
                let intolerantAdd = 0;
                if (product.intolerant) {
                    intolerantAdd = product.total;
                }

                toRet.total += product.total;
                if(toRet.products[product.id]){
                    toRet.products[product.id].total += product.total;
                    toRet.products[product.id].intolerants += intolerantAdd;
                }
                else{
                    toRet.products[product.id] = {
                        total: product.total,
                        intolerants: intolerantAdd,
                        name: product.props.name,
                        flavor_ref: product.props.flavor,
                    };
                }
            });
        });

        for (let key of Object.keys(toRet.products)) {
            if(toRet.products[key].flavor_ref)
            toRet.products[key].flavor_name = (
                await db
                    .collection("flavors")
                    .doc(toRet.products[key].flavor_ref)
                    .get()
            ).data().name;
        }
        return toRet;
    }


    private getNumberOfDelivery(){
      let ndeliveries = 0;
      if(this.props.deliveries)
      for(let delivery of this.props.deliveries){
        ndeliveries += delivery.order.length
      }
      return ndeliveries;
    }
    private static async changePassword(firebase:ExtendedFirebaseInstance, newPassword:string) {
        const user = firebase.auth().currentUser;
        try {
          if(user) {
            await user.updatePassword(newPassword);
          }
          console.log("Password Updated!");
        } catch (err) {
          console.log(err);
        }
      }

      private static async updateProfile(firebase:ExtendedFirebaseInstance, oldProfile: any, newProfile: any, uid: string) {

        if (newProfile.email !== oldProfile.email) {
            await firebase.updateEmail(newProfile.email, true);
            console.log("Email Updated!");
            //console.log("Error updating email", error);
        }

        if (newProfile.password && newProfile.password.trim() !== "") {
            await this.changePassword(firebase, newProfile.password);
            //console.log("Error updating password", error);
        }

        if (
          newProfile.displayName !== oldProfile.displayName ||
          newProfile.phone !== oldProfile.phone ||
          newProfile.image.length
        )
         {
            const NewProfile: any = {
              displayName: newProfile.displayName,
              phone: newProfile.phone,
            };

            if (newProfile.image.length) {
              let oldImageUrl = oldProfile.photoURL
              if(oldImageUrl) {
                  let imageRef = firebase.storage().refFromURL(oldImageUrl);
                  await imageRef.delete()
                };

              const profileImage = newProfile.image[0];
              const newProfilePic = await firebase.uploadFile(
                `${uid}/profilePicture/`,
                profileImage
              );

              const photoURL = await newProfilePic.uploadTaskSnapshot.ref.getDownloadURL();

              NewProfile.photoURL = photoURL;
            }

            await firebase.updateAuth(NewProfile, true);
            console.log("Profile Updated!");
          }
            //console.log("Error updating profile", error);

      }

}
