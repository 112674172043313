import Flavor from "../../entities/Flavor";
import FlavorDBInterface, {FlavorCreateParams} from "../interfaces/FlavorDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";
import {injectReduxStore} from "../../redux/backendStorage";
import {db} from "../../../services/Firebase";



const COLLECTION_NAME = "flavor"
export default class FlavorFirebase implements FlavorDBInterface{

  async find(id:string):Promise<Flavor | undefined>{
    return findWithRedux(id, COLLECTION_NAME, Flavor);
  }

  async all():Promise<Flavor[]>{
    return allWithRedux("flavor", Flavor);
  }

  async create(data:FlavorCreateParams):Promise<void>{
    await db.collection(COLLECTION_NAME).add({name: data.name, deleted: false})
  }

  async delete(id: string): Promise<void> {
    await db.collection(COLLECTION_NAME).doc(id).update({deleted: true})
  }

  async update(id: string, params:FlavorCreateParams): Promise<void> {
    console.log(params)
    await db.collection(COLLECTION_NAME).doc(id).update(params)
  }

  async getAllActiveFlavors():Promise<Flavor[]>{
    return injectReduxStore(()=>this.queryOnFirebaseActiveFlavors(), "/active_flavors");
  }

  private async queryOnFirebaseActiveFlavors() {
    let query = db.collection(COLLECTION_NAME)

    let response = await query.where("deleted", "!=", true).get();

    let ret_list:Flavor[] = []
    response.docs.forEach(obj => {
      ret_list.push(new Flavor(obj.ref.id, obj.data()))
    });

    return ret_list;
  }



}