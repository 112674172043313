import ApplicationEntity from "./ApplicationEntity"



export default class Alert extends ApplicationEntity{
  name: string
  description: string
  imageUrl: string
  initialDate: Date
  endDate: Date

  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }
}
