import Flavor from "../../../../entities/Flavor";
import { List, TextField } from "@material-ui/core";
import React, { useState } from "react";
import FlavorListItem from "../../../atoms/admin/FlavorListItem";

export default function FlavorList(props: { flavors: Flavor[] }) {
  let flavors = props.flavors || [];

  let [query, setQuery] = useState("");
  let [list, setList] = useState(flavors);

  const handleChange = (evt: { target: { value: any } }) => {
    let newValue = evt.target.value;
    setQuery(newValue);
    setList(
      flavors.filter((flavor) =>
        flavor.name.toLowerCase().startsWith(newValue.toLowerCase())
      )
    );
  };

  return (
    <div>
      <TextField
        id="filled-secondary"
        label="Procurar"
        variant="filled"
        fullWidth={true}
        value={query}
        onChange={handleChange}
        color="primary"
      />
      <List>
        {list.map((flavor, index) => {
          return (
            <FlavorListItem
              name={flavor.name}
              index={index}
              id={flavor.id}
              deleted={flavor.deleted}
            />
          );
        })}
      </List>
    </div>
  );
}
