import { Toolbar, Typography } from "@material-ui/core";
import React from "react";

export default function DailyShippingInformation(props: {
  information: string;
  label: string;
}) {
  return (
    <Toolbar>
      <Typography variant="h6">
        {props.label}
        <span style={{ right: 16, position: "absolute" }}>
          {props.information}
        </span>
      </Typography>
    </Toolbar>
  );
}
