import React, { useState } from "react";
import { useSelector } from "react-redux";
import "react-nice-dates/build/style.css";

import usePresenter from "../../app/redux/usePresenter";
import PaymentsIndexPresenter from "../../app/presenters/associated/PaymentsIndexPresenter";
import PaymentsIndexTemplate from "../../app/components/templates/associated/PaymentsIndex";

export default function PaymentsIndex() {
  const [date, setDate] = useState(new Date());
  const associatedAuth = useSelector((state) => state.firebase.auth);
  let list = usePresenter(new PaymentsIndexPresenter(associatedAuth.uid, date));

  return <PaymentsIndexTemplate list={list} date={date} setDate={setDate} />;
}
