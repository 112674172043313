import React from "react";
import { Control, DeepMap, FieldError } from "react-hook-form";
import FormField from "../../../atoms/FormField";

import AvatarFormInput from "../../../atoms/AvatarFormInput";
import FormSelect from "../../../atoms/FormSelect";

type neighborhoodsForm = {
  label: string,
  value: string
}

type form_params  = { 
  control: Control<Record<string, any>>, 
  errors: DeepMap<Record<string, any>, FieldError>,
  register: any,
  neighborhoods: neighborhoodsForm[],
  defaultValues?: {
    image_url: string
  },
}

const AssociatedFormHeader = ({control, defaultValues, errors, neighborhoods,register}:form_params) => {

  return (
    <div>
      <AvatarFormInput 
        register={register}
        name={"profile_image"}
        defaultSrc={defaultValues?.image_url}
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Nome"}
        field_name={"displayName"} 
      />
      <FormSelect 
        label="Bairro"
        name="neighborhoodId"
        options={neighborhoods}
        control={control}
        errors={errors}
      />
      <FormField
        control={control} 
        errors={errors} 
        label={"Endereço de entrega"}
        field_name={"deliveryAddress"}
      />

      <FormField 
        label="Observações para motorista"
        field_name={"delivery_observation"}
        control={control}
        errors={errors}
        required={false}
      />
    </div>
  )
}

export default AssociatedFormHeader