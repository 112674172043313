import firebase from "firebase";
import { db } from "../../../services/Firebase";
import Receipt, { Bill } from "../../entities/Receipt";
import { deleteStorage, injectReduxStore } from "../../redux/backendStorage";
import ReceiptDBInterface, {
  ReceiptForm,
} from "../interfaces/ReceiptDBInterface";
import { findWithRedux } from "./utils/find_firebase";
import { addMonths, setDate, setHours, setMinutes } from "date-fns";

export default class ReceiptFirebase implements ReceiptDBInterface {
  async find(id: string): Promise<Receipt | undefined> {
    let receipt = await findWithRedux(id, "receipt", Receipt);
    receipt.date = receipt.date.toDate();
    return receipt;
  }

  async createReceiptForAssociated(form: ReceiptForm): Promise<any> {
    const createReceiptForAssociated = firebase
      .functions()
      .httpsCallable("createReceiptForAssociated");
    let response: any = await createReceiptForAssociated(form);
    if (response.data["error"]) throw new Error(response.data["error"]);
    deleteStorage("associated/" + form.associatedId);
  }

  async getAllBills(associated_id: string): Promise<Bill[]> {
    return injectReduxStore(async () => {
      let query = db.collection("associated/" + associated_id + "/bill");
      let data = await query.get();

      let bills: any[] = [];
      data.docs.forEach((obj) => {
        let b = new Bill(obj.ref.id, obj.data());
        if (!(obj.data().date instanceof String)) {
          b.date = obj.data().date.toDate().toISOString().slice(0, 10);
        }
        bills.push(b);
      });
      return bills;
    }, "associated/" + associated_id + "/bill");
  }

  async searchAssociated(associated_id: string): Promise<Receipt[]> {
    let query = db
      .collection("receipt")
      .where("associatedId", "==", associated_id);
    let data = await query.get();
    return ReceiptFirebase.constructDataArray(data);
  }

  async searchStatusAndDate(status: string, date: Date): Promise<Receipt[]> {
    let first_day_month = ReceiptFirebase.resetDateToFirstDayMonth(date);
    let query = db
      .collection("receipt")
      .where("status", "==", status)
      .where("date", ">=", first_day_month)
      .where("date", "<=", addMonths(first_day_month, 1));

    let data = await query.get();
    return ReceiptFirebase.constructDataArray(data);
  }

  private static constructDataArray(
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ): Receipt[] {
    let receipts: any[] = [];
    data.docs.forEach((obj) => {
      receipts.push(new Receipt(obj.ref.id, obj.data()));
    });

    return receipts.map((r: any) => {
      r.date = new Date(r.date.seconds * 1000);
      return r;
    });
  }

  private static resetDateToFirstDayMonth(date: Date): Date {
    let new_date: Date = setMinutes(date, 0);
    new_date = setHours(new_date, 0);
    return setDate(new_date, 1);
  }
}
