import { normalizeDeliveryDate } from "../../../utils/date_manipulation";
import database from "../../database/DataBaseConfig";
import DeliveryInfoInteractor, {
  ProductOnDelivery,
} from "../../interactors/delivery/DeliveryInfoInteractor";
import PresenterInterface from "../PresenterInterface";
import Associated_Delivery, {
  DeliveredProduct,
} from "../../entities/Associated_Delivery";
import Product from "../../entities/Product";
import AssociatedInteractor from "../../interactors/AssociatedInteractor";

type data_format = {
  products: ProductOnDelivery[];
  returnables: returnable_data_format[];
  broken: returnable_data_format[];
  type: string;
  literal_type: string;
  observation: string;
};

type returnable_data_format = {
  name: string;
  quantity: number;
};

const translateDeliveryTypes: { [id: string]: string } = {
  nextDelivery: "Próxima entrega",
  futureDelivery: "A receber",
  suspended: "Entrega Suspensa",
  nextSuspended: "Entrega Suspensa",
  alreadyReceived: "Entrega passada",
};

export default class DeliveryOnDatePresenter implements PresenterInterface {
  storage_path: string;
  constructor(public associated_id: string, public date: Date) {
    let normalized_date = normalizeDeliveryDate(this.date);
    this.storage_path =
      "/calendandar/" +
      this.associated_id +
      "/" +
      normalized_date.toISOString();
  }

  async getData(): Promise<data_format> {
    let associated = await database.associated_db.find(this.associated_id);
    if (!associated) return this.defaultData();

    let info = await DeliveryInfoInteractor.deliveryInfoOnDate(
      this.date,
      associated
    );
    console.log(info);
    let returnedData: Associated_Delivery[] =
      await database.associated_delivery_db.findDeliveriesOnDate(
        this.associated_id,
        this.date
      );
    let formattedReturnableList: returnable_data_format[] =
      await DeliveryOnDatePresenter.returnFormattedList(
        returnedData,
        "returnedProducts"
      );
    let brokenList: returnable_data_format[] =
      await DeliveryOnDatePresenter.returnFormattedList(returnedData, "broken");

    let delivery_obs = await AssociatedInteractor.getDeliveryObservationOnDate(
      this.associated_id,
      this.date
    );

    return {
      products: Object.values(info.products),
      returnables: formattedReturnableList,
      broken: brokenList,
      type: translateDeliveryTypes[info.deliveryType],
      literal_type: info.deliveryType,
      observation: delivery_obs,
    };
  }

  private defaultData(): data_format {
    return {
      products: [],
      returnables: [],
      broken: [],
      type: "Não irá receber",
      literal_type: "",
      observation: "",
    };
  }

  static async returnFormattedList(
    associatedDeliveries: Associated_Delivery[],
    type: "returnedProducts" | "broken"
  ) {
    let formattedReturnableList: returnable_data_format[] = [];
    let returnableList: DeliveredProduct[] =
      DeliveryOnDatePresenter.formatAssociatedDeliveries(
        associatedDeliveries,
        type
      );

    for (let i = 0; i < returnableList.length; i++) {
      let returnableName = await DeliveryOnDatePresenter.getReturnableName(
        returnableList[i].productId
      );
      formattedReturnableList.push({
        name: returnableName,
        quantity: returnableList[i].quantity,
      });
    }
    return formattedReturnableList;
  }

  static formatAssociatedDeliveries(
    associatedDeliveries: Associated_Delivery[],
    type: "returnedProducts" | "broken"
  ): DeliveredProduct[] {
    let new_list: DeliveredProduct[] = [];
    associatedDeliveries.forEach((delivery) => {
      delivery[type].forEach((product) => {
        let index = this.findProductInList(product.productId, new_list);
        index === -1
          ? new_list.push(product)
          : (new_list[index].quantity += product.quantity);
      });
    });
    return new_list;
  }

  static findProductInList(
    productId: string,
    list: DeliveredProduct[]
  ): number {
    return list.findIndex((product) => product.productId === productId);
  }

  static async getReturnableName(productId: string) {
    let product: Product | undefined = await database.product_db.find(
      productId
    );
    if (product) return product.returnableName;
    return "";
  }

  static async addSuspendedDay(associated_id: string, date: Date) {
    return database.associated_db.addSuspendedDay(associated_id, date);
  }

  static async removeSuspendedDay(associated_id: string, date: Date) {
    return database.associated_db.removeSuspendedDay(associated_id, date);
  }

  static async updateObservation(id: string, date: Date, obs: string) {
    return await AssociatedInteractor.updateDeliveryObservationOnDate(
      id,
      date,
      obs
    );
  }
}
