import React, { useState} from 'react'
import {useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {useFirebase} from "react-redux-firebase";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Container,
    TextField,
    Button,
    InputAdornment,
    CircularProgress
} from '@material-ui/core'

import ShowReceiptInfo from '../../app/components/organisms/receipt/ShowReceiptInfo';
import usePresenter from '../../app/redux/usePresenter';
import CreateReceiptPresenter from '../../app/presenters/associated/CreateReceiptPresenter';
import PriceMaskedInput from '../../components/inputMasks/PriceMaskedInput';

const schema = yup.object().shape({paidValue: yup.string().required("Valor é obrigatório")});

export default function SendReceipt() {
    const firebase = useFirebase();
    
    const history = useHistory();
    const [receiptURL, setReceiptURL] = useState()
    const [loading, setLoading] = useState(false);
    const associatedAuth = useSelector((state) => state.firebase.auth);
    const {register, handleSubmit, errors} = useForm({resolver: yupResolver(schema)});
    let receipt_data = usePresenter( new CreateReceiptPresenter(associatedAuth.uid));

    async function sendReceipt(values) {

        const image = values.image[0]
        const paidValue = values.paidValue
        if (Number(paidValue) <= 0 || isNaN(Number(paidValue))) {
            alert("Valor inválido")
            return
        }
        const date = new Date()
        let receiptURL;
        let save_path = `${associatedAuth.uid}/paidReceipt/${date.toISOString()}/${String(date.getTime())}`;
        
        setLoading(true);
        try{
            const newReceiptImage = await firebase.uploadFile(
                save_path, 
                image
            );
            receiptURL = await newReceiptImage.uploadTaskSnapshot.ref.getDownloadURL();
        }
        catch(e){
            alert("Problemas no envio da imagem, recarregue a página e tente novamente");
            setLoading(false);
            return;
        }
        await CreateReceiptPresenter.createReceipt(associatedAuth.uid, paidValue, receiptURL)
        setLoading(false);
        history.push("/associated/pagamento")
    }

    const imageUpload = (event) => {
        if (!event.target.files)
            return;

        const selectedImage = event.target.files[0];

        const imageURL = URL.createObjectURL(selectedImage);

        setReceiptURL(imageURL)
    }

    if(!receipt_data || loading) return <div className="loading-container"><CircularProgress/></div>;
    
    return (<div>
        <Container> 
            <ShowReceiptInfo
                {...receipt_data}
            />
            <form onSubmit={handleSubmit(sendReceipt)}>
                <Container>
                    <TextField 
                        id="standard-basic" 
                        label="Valor Pago" 
                        inputRef={register} 
                        name='paidValue' 
                        type="number"
                        error={!!errors.paidValue} 
                        helperText={errors.paidValue?.message}
                        variant="filled"
                        fullWidth={true}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            ),
                            inputComponent: PriceMaskedInput,
                            defaultValue: "0.00",
                        }}
                    />
                </Container>
                <Button variant="contained" component="label" style={{
                    marginTop: '20px'
                }}>
                    Enviar Recibo
                    <input type="file" accept="*" hidden="hidden" id="image" name="image" onChange={imageUpload}
                            ref={register}/>
                </Button>
                <Button variant="contained" color="primary" size="large" style={{
                    fontSize: "12px",
                    float: 'right',
                    marginTop: '20px'
                }} type="submit">
                    Confirmar
                </Button>
            </form>
            {
                receiptURL && <div style={{marginTop: 10, width: "100%", overflow: "hidden"}}>
                    <iframe 
                        style={iframe_style}
                        src={receiptURL} 
                        title="Comprovante"
                    />    
                </div>
            }
        </Container>
    </div>)
}


let iframe_style = {
    width: '300px',
    height: '600px',
    border: '0px',
}