import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";

import Products from "../../services/db/Products";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

// import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  hText: {
    margin: theme.spacing(2),
  },
}));

function PlanIndex() {
  const db = useFirestore();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [userProducts, setUserProducts] = useState([]);

  const associated = useSelector((state) => state.backend.associated);

  useEffect(() => {
    const fetchData = async () => {
      let [userProducts, otherProducts] = await Products.separateProducts(
        db,
        associated
      );

      console.log(userProducts);

      setUserProducts(userProducts);
      setAllProducts(
        otherProducts.filter((item) => {
          return !item.props.tastable;
        })
      );
      setLoading(false);
    };
    fetchData();
  }, [db, associated]);

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Divider />
          <div className={classes.hText}>
            <h3>{"Seus Produtos"}</h3>
          </div>
          <Divider />
          <ProductList productList={userProducts} />

          <Divider />
          <div className={classes.hText}>
            <h3>{"Adquirir outros Produtos"}</h3>
          </div>
          <Divider />
          <ProductList productList={allProducts} />
        </div>
      )}
    </div>
  );
}

export default PlanIndex;

function ProductList({ productList }) {
  const history = useHistory();
  return (
    <List>
      {productList.map((product, index) => (
        <div key={index}>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={product.props?.name} src={product.props?.imageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={product.props.name}
              secondary={product.props.description}
              secondaryTypographyProps={{ noWrap: true }}
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => {
                  history.push("/associated/plan/buy/" + product.docRef.id);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {index !== productList.length - 1 && <Divider variant="inset" />}
        </div>
      ))}
    </List>
  );
}
