import {Container, List, Divider } from "@material-ui/core";
import React from "react";
import ReceiptBillItem, { ReceiptBillItemProps } from "../../atoms/shared/receipt/ReceiptBillItem";

export type ReceiptBillListProps = {
  items: ReceiptBillItemProps[],
  list_name: string, 
  total_value: string,
  forceShow?: boolean,
}

export default function ReceiptBillList(props:ReceiptBillListProps) {
  if(Number(props.total_value) === 0 && !props.forceShow) return <></>
  
  return (<div>
    <List dense={true} disablePadding={true}>
      <ReceiptBillItem 
        description={props.list_name}
        value={props.total_value}
        idx="0"
      />
    </List>
    <Container maxWidth="sm">
      <List dense={true} disablePadding={true}>
          {
            props.items.map((item, idx)=>{
              return (
                <div>
                  <ReceiptBillItem 
                    {...item}
                    idx={String(idx)}
                  />
                  <Divider variant="inset" component="li" />
                </div>
              )
            })
          }
      </List>
    </Container>
  </div>)
}
