import React, {useEffect, useState} from 'react'
import {useFirestore}   from 'react-redux-firebase';
import { useSelector }  from 'react-redux';
import {
	useHistory
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

import Menu           from '@material-ui/core/Menu';
import MoreVertIcon   from '@material-ui/icons/MoreVert';
import IconButton     from '@material-ui/core/IconButton';
import MenuItem       from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
  root: {
    display:        'flex',
    '& > *': {
      margin:       theme.spacing(2),
    },
  },
  loading:{
    display:        "flex",
    marginTop:      "100px",
    justifyContent: "center"
  },
  align:{
    verticalAlign:  "middle",
    margin:         "auto 10px"
  },
  hText:{
    margin:         theme.spacing(2),
  },
  adressContainer: {
    margin:         theme.spacing(2),
    display: "      flex"
  }
}));


function IndividualPurchase() {
  const db            = useFirestore();
  const history       = useHistory();
  const associated    = useSelector((state) => state.backend.associated);

  let [loading, setLoading]     = useState(true);
  let [purchases, setPurchases] = useState([])
  // Menu (tres pontinhos)
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexOpen, setIndexOpen] = useState(0);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, i ) => {
    setIndexOpen(i);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const getProduct = () => {
    return purchases[indexOpen];
  }

  const handleMenuClose = (option) => {
    return () => {
      let purchase = getProduct();
      let productId = purchase.productId;
      if(associated){
        if(option === "Retirar"){
          associated.individualPurchases.removePurchase(productId, purchase.date);
          setLoading(true);
        }
        if(option === "Alterar"){
          history.push("/associated/avulso/buy/"+productId, {date: purchase.date})
        }
      }
      setAnchorEl(null);
    }
  };

  useEffect(()=>{

    const fetchData = async () =>{
      setPurchases(await associated.individualPurchases.getAll())
      setLoading(false)
    }

    fetchData();
  },[db, associated, loading])

  const classes = useStyles();

  return loading ? <div className={classes.loading}><CircularProgress /></div> : (
    <div >
      <Divider />
      <div className={classes.hText}>
        <h3>{"Próxima Entrega"}</h3>
      </div>
      <Divider />
      <div>
        <List>
        {purchases.map((purchase, index)=>(<div key={index}>
          <ListItem >
            <ListItemText
              primary={purchase.product.props.name}
              secondary={"Quantidade: " + purchase.quantity + (purchase.date ? " Data: " + purchase.date : "")}
            />
            <ListItemSecondaryAction><>
              {"R$"} {
                parseFloat(
                  purchase.quantity * (purchase.product.props.price || 0)
                ).toFixed(2).replace(".", ",")
                }
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(ev)=>handleMenuClick(ev, index)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={closeMenu}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {["Retirar", "Alterar"].map((option) => (
                  <MenuItem key={option} onClick={handleMenuClose(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu></>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" />
          </div>
        ))}
        </List>
      </div>

      <div style={{display:"flex", flexDirection:"column",margin:"10px 20px", justifyContent:"center"}}>
        <Button variant="contained" color="primary" size="large" style={{fontSize: "12px"}} onClick={()=>{history.push("/associated/avulso/index")}}>
          Adquirir mais produtos
        </Button>
        <div style={{fontSize: "12px", color: "rgb(111 111 111)", margin:"2px auto"}}>Itens individuais serão entregues na próxima semana</div>
      </div>
    </div>
  );
}

export default IndividualPurchase;
