import React from "react";
import CreateForm from "../../app/components/templates/admin/flavor/CreateForm";
import {useLocation} from "react-router-dom";
import usePresenter from "../../app/redux/usePresenter";
import CreateFlavorPresenter from "../../app/presenters/admin/flavor/CreateFlavorPresenter";
import LoadingCircle from "../../app/components/atoms/LoadingCircle";


export default function CreateFlavor() {

  const location = useLocation();
  const { id, name } = location.state ? location.state : {};
  const presenter_data = usePresenter(new CreateFlavorPresenter(id));
  let deleted

  if(id && name && !presenter_data){
    return <LoadingCircle show/>
  }
  if(id && name && presenter_data){
    deleted = presenter_data.deleted
  }

  return <CreateForm name={name} id={id} deleted={deleted}/>
}
