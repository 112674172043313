import ApplicationEntity from "./ApplicationEntity"



export default class AppConfigs extends ApplicationEntity{
  entry_tax?: number
  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }
}
