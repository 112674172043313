import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import ShoppingCartIcon         from '@material-ui/icons/ShoppingCart';
import HomeIcon                 from '@material-ui/icons/Home';
import ChatIcon                 from '@material-ui/icons/Chat';
import IndividualPurchase       from '../pages/associated/IndividualPurchase';
import IndividualPurchaseIndex  from '../pages/associated/IndividualPurchaseIndex';
import BuyIndividualProduct     from '../pages/associated/BuyIndividualProduct';
import PlanIndex from '../pages/associated/PlanIndex';
import PlanProduct from '../pages/associated/PlanProduct';
import PaymentsIndex from '../pages/associated/PaymentsIndex'
import SendReceipt from '../pages/associated/SendReceipt'


import Mural from '../pages/associated/Mural';
import InformacaoDeEntrega from '../pages/associated/InformacaoDeEntrega';
import MyPlan from '../pages/associated/MyPlan';

import AssociatedChat from '../pages/associated/AssociatedChat'

import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import Profile from "../pages/associated/Profile";
import AvisoView from "../pages/associated/AvisoView";
import DeliveryHistory from "../pages/associated/DeliveryHistory";



export default [
    {
        path:       "/mural",
        name:       "Mural",
        title:      'Mural',
        icon:       HomeIcon,
        component:  Mural
    },
    {
        path:       "/plan/view",
        name:       "Assinatura",
        title:      'Meu Plano',
        icon:       EmojiFoodBeverageIcon,
        component:  MyPlan
    },
    {
        path:       "/avulsos",
        name:       "Avulsos",
        title:      'Avulsos',
        icon:       ShoppingCartIcon,
        component:  IndividualPurchase
    },
    {
        path:       "/pagamento",
        name:       "Pagamento",
        title:      'Meus Pagamentos',
        icon:       AccountBalanceWalletIcon,
        component:  PaymentsIndex
    },
    {
      path: "/perfil",
      name: "Perfil",
      title: "Meu Perfil",
      icon: PersonIcon,
      component: Profile,
    },
    {
        path: "/delivery_history",
        name: "Histórico de Entregas",
        title: "Histórico de Entregas",
        component: DeliveryHistory,
        backButtom: true,
        redirectNavigation: true
    },
    {
        path:       "/chat",
        name:       "Chat",
        title:      "Fale com o Administrador",
        component:  AssociatedChat,
        redirectNavigation: true,
        backButtom: true
    },
    {
        path:       "/avulso/index",
        name:       "Comprar outros produtos",
        title:      "Comprar outros produtos",
        component:  IndividualPurchaseIndex,
        redirectNavigation: true,
        backButtom: true
    },
    {
        name:       "Nova compra",
        title:      'Nova compra',
        path:       '/avulso/buy',
        param:      '/:id',
        component:  BuyIndividualProduct ,
        redirectNavigation: true,
        backButtom: true
    },
    {
        path: "/plan/index",
        name: "Adicionar items ao plano",
        title: "Adicionar items ao plano",
        icon: PersonIcon,
        component: PlanIndex,
        redirectNavigation: true,
        backButtom: true
    },
    {
        name:       "Ver Produto",
        title:      'Ver Produto',
        path:       '/plan/buy',
        param:      '/:id',
        component:  PlanProduct ,
        redirectNavigation: true,
        backButtom: true
    },
    {
        name:       "Ver Entrega",
        title:      'Ver Entrega',
        path:       '/entrega/dia',
        param:      '/:date',
        component:  InformacaoDeEntrega ,
        redirectNavigation: true,
        backButtom: true
    },
    {
        name:       "Aviso",
        title:      'Ver aviso',
        path:       '/aviso/view',
        param:      '/:aviso_id',
        component:  AvisoView ,
        redirectNavigation: true,
        backButtom: true
    },
    {
        path:       "/pagamento/envio",
        name:       "Enviar Comprovante",
        title:      'Enviar Comprovante',
        component:  SendReceipt,
        backButtom: true,
        redirectNavigation : true
    }
]
