import React, { useState } from "react";
import {  useLocation } from "react-router-dom";
import AssociatedCalendar from "../../../app/components/atoms/associated/AssociatedCalendar";
import AssociatedDeliveryInfoList from "../../../app/components/molecules/associated/AssociatedDeliveryInfoList";


export default function ShowDeliveries(){
  const location = useLocation() as any;
  const { id }:any = location.state ? location.state : {};
  const [selectedDate, changeDate] = useState(new Date());

  return (<div>
    <AssociatedCalendar associatedId={id} onChange={changeDate} />
    <AssociatedDeliveryInfoList associated_id={id} date={selectedDate} />
  </div>)
}