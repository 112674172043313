import PresenterInterface from "../PresenterInterface";
import database from "../../database/DataBaseConfig";
import Receipt from "../../entities/Receipt";
import { getMonth, getYear } from "date-fns";

export type data_format = {
  sent_date: string;
  value: string;
  status: string;
  raw_status: string;
};

export default class PaymentsIndexPresenter implements PresenterInterface {
  storage_path: string;
  constructor(public associated_id: string, public date: Date) {
    this.storage_path =
      "/payments_index/" +
      this.associated_id +
      "/" +
      PaymentsIndexPresenter.getFormattedDate(date);
  }

  async getData(): Promise<data_format[]> {
    let receipt_list: Receipt[] = await database.receipt_db.searchAssociated(
      this.associated_id
    );
    let formatted_data_list: data_format[] = [];
    receipt_list.forEach((receipt: Receipt) => {
      if (
        getMonth(this.date) === getMonth(receipt.date) &&
        getYear(this.date) === getYear(receipt.date)
      ) {
        formatted_data_list.push({
          sent_date: PaymentsIndexPresenter.getFormattedDate(receipt.date),
          status: PaymentsIndexPresenter.getFormattedStatus(receipt.status),
          value: PaymentsIndexPresenter.getFormattedFloat(receipt.paidValue),
          raw_status: receipt.status,
        });
      }
    });

    return formatted_data_list;
  }

  static getFormattedDate(date: Date): string {
    let month = date.getUTCMonth() + 1; //months from 1-12
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();

    return day + "/" + month + "/" + year;
  }

  static getFormattedFloat(f: string): string {
    return parseFloat(f).toFixed(2).replace(".", ",");
  }

  static getFormattedStatus(status: string): string {
    switch (status) {
      case "accepted":
        return "Confirmado";
      case "refused":
        return "Rejeitado";
      case "pending":
        return "Aguardando confirmação";
      default:
        return "Estado não definido";
    }
  }
}
