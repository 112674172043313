import Neighborhood from "../../entities/Neighborhood";


export default function neighborhoodOnListInformation(neighborhood: Neighborhood | undefined){
  if(!neighborhood) neighborhood = Neighborhood.defaultNeighborhood()
  return {
    avatar:{
      color: neighborhood.color,
      letter: neighborhood.iconLetter()
    },
    name: neighborhood.name
  }
}