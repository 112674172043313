import ApplicationEntity from "./ApplicationEntity";

export type DeliveredProduct = {
	productId		: string,
	quantity		: number
}

export default class Associated_Delivery extends ApplicationEntity{
	date			    : Date
	observation		: string
  broken        : DeliveredProduct[]
	products		  : DeliveredProduct[]
	returnedProducts: DeliveredProduct[]
  associatedId  : string
  totalPrice?: number

  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }

}