import React from 'react';
import { AppBar, makeStyles, SwipeableDrawer } from '@material-ui/core';

import {
	useHistory,
	useLocation
} from "react-router-dom";

import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LogOutButton from '../components/LogOutButton'
import AdminTopBarMenu from '../components/AdminTopBarMenu'

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as MilramasIcon} from '../config/assets/topbar-logo.svg';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import configs from "../config/colors"

const useStyles = makeStyles((theme) => ({
  list: {
    width: 200,
  },
  fullList: {
    width: 'auto',
  },
  toolbarText:{
    width: '100%',
    verticalAlign: "middle",
    display: "flex",
    justifyContent:"center",
    alignItems: "center"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: "absolute"
  },
  actionButton:{
	float: "right"
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

function AdminDrawer({routes, routeProps}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()

  const [drawer, setDrawer] = React.useState(false);
  const reduxPageTitle = useSelector((state) => state.frontend.title);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  const redirectTo = route =>{
    history.push("/admin"+route.path)
  }

  const redirectToCreate = () => {
	let new_location = location.pathname.replace("/admin/", "")
	history.push("/admin/" + new_location + "/create")
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
    >
      <div className={classes.drawerHeader}/>
      <List>
        {routes.map((route, index) => (<div key={index}>
          <ListItem button onClick={()=>{redirectTo(route)}} >
            {/*<ListItemIcon><route.drawer.icon /></ListItemIcon>*/}
            <ListItemText primary={route.drawer.name} />
          </ListItem>
          {index !== (routes.length -1) && <Divider variant="middle"/> }
        </div>))}
      </List>
      <Divider />
    </div>
  );


  return (
    <>
    <AppBar position="fixed" style={{zIndex: 3000}}>
      <Toolbar style={{ background: configs.theme.admin.topBarColor }}>
        {routeProps.backButton ? (
          <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={()=>{history.goBack()}}>
            <ArrowBackIosIcon />
          </IconButton>
          ):(
          <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={toggleDrawer(!drawer)}>
            <MenuIcon />
          </IconButton>
        )}
        <Typography className={classes.toolbarText}>
          <MilramasIcon/>
          <span style={{marginLeft:"10px"}}>
            {routeProps.title ? routeProps.title : reduxPageTitle}
          </span>
        </Typography>

		{routeProps.addButton &&
				<IconButton edge="start" className={classes.actionButton} color="inherit" onClick={redirectToCreate}>
		  			<AddIcon />
				</IconButton>}
        {routeProps.dropDown ? (<AdminTopBarMenu options={routeProps.dropDown.options}/>) : ( routeProps.logOutButton ? <LogOutButton /> : <></>)}
      </Toolbar>
    </AppBar>
    <React.Fragment>
      <SwipeableDrawer
        anchor={"left"}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
    </>
  );
}




export default AdminDrawer;
