import React from "react";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PriceMaskedInput from "../../components/inputMasks/PriceMaskedInput";
import Products from "../../services/db/Products";
import { db } from "../../services/Firebase";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Flavor from "../../services/db/Flavor";
import SelectInput from "../../components/inputs/SelectInput";
import Loading from "../../components/Loading";
import { useFirebase } from "react-redux-firebase";
import AvatarInput from "../../components/inputs/AvatarInput";
import { compareString } from "../../utils";

const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório").trim(),
  flavorId: yup.string().required("Sabor é obrigatório").trim(),
  returnable: yup.string().required("Informação obrigatória").trim(),
  returnableName: yup.string().trim(),
  individual: yup.bool(),
  tastable: yup.bool(),
  price: yup.string().required("Preço é obrigatório").trim(),
  description: yup.string().trim(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
  avatar: {
    height: "15rem",
    width: "15rem",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
}));

const returnableOptions = [
  { label: "Sim", value: "Sim" },
  { label: "Não", value: "Não" },
  { label: "Opcional", value: "Opcional" },
];

function CreateProduct() {
  const [loading, setLoading] = React.useState(false);
  const firebase = useFirebase();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const { product } = location.state ? location.state : {};

  //const [open, setOpen] = React.useState(false);
  const [flavorOptions, setFlavorOptions] = React.useState([]);
  const [loadingInput, setLoadingInput] = React.useState(true);
  //const loadingInput = open && options.length === 0;

  React.useEffect(() => {
    /* let active = true;

    if (!loadingInput) {
      return undefined;
    } */

    (async () => {
      setLoadingInput(true);
      const flavors = await Flavor.all(db);

      //if (active) {
      setFlavorOptions(
        Object.keys(flavors)
          .map((fId) => ({
            label: flavors[fId].props.name,
            value: fId,
          }))
          .sort((a, b) => {
            return compareString(a.label, b.label);
          })
      );
      //}

      setLoadingInput(false);
    })();

    /* return () => {
      active = false;
    }; */
  }, []);

  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const returnable = watch("returnable");

  const reactivateHandler = async () => {
    setLoading(true);
    if (product) {
      try {
        await Products.reactivate(db, id);
        history.goBack();
      } catch (error) {
        console.log(error);
        alert("Erro ao deletar produto.");
      }
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    setLoading(true);
    if (product) {
      try {
        let deleteProduct = firebase.functions().httpsCallable("deleteProduct");
        await deleteProduct({id: id});
        history.goBack();
      } catch (error) {
        console.log(error);
        alert("Erro ao deletar produto.");
      }
    }
    setLoading(false);
  };

  const submitHandler = async (productInfo) => {
    setLoading(true);
    console.log(productInfo);

    const image = productInfo.image[0];

    if (!image && !product) {
      alert("Adicione uma imagem ao produto!");
      setLoading(false);
      return;
    }

    // Não tem como deletar a chave do argumento da função
    let productsData = { ...productInfo };
    delete productsData.image;

    try {
      if (product) {
        await Products.updateWithImage(db, firebase, id, productsData, image);
      } else {
        await Products.createWithImage(db, firebase, productsData, image);
      }
      reset({});
      history.goBack();
    } catch (error) {
      console.log(error);
      if (product) {
        alert("Erro ao editar produto.");
      } else {
        alert("Erro ao criar produto.");
      }
    }

    setLoading(false);
  };

  if (loadingInput) {
    return <Loading />;
  }

  function shouldRenderReturnableNameField() {
    if (product?.returnable !== "Não" && returnable === undefined) return true;
    if (returnable === undefined) return false;
    return returnable !== "Não";
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <AvatarInput
            //required={!product}
            name="image"
            inputRef={register}
            alt={product?.name}
            src={product?.imageUrl}
            label={
              product ? "Alterar foto do produto" : "Adicionar foto do produto"
            }
          />
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nome"
            label="Nome"
            name="name"
            type="text"
            error={!!errors.name}
            helperText={errors.name?.message}
            defaultValue={product?.name}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="price"
            label="Preço"
            name="price"
            autoComplete="price"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              inputComponent: PriceMaskedInput,
              defaultValue: product?.price || "0.00",
            }}
            error={!!errors.price}
            helperText={errors.price?.message}
            defaultValue={product?.price || "0.00"}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="stock"
            label="Estoque"
            name="stock"
            autoComplete="stock"
            type="number"
            InputProps={{
              defaultValue: product?.stock || "0",
            }}
            error={!!errors.stock}
            helperText={errors.stock?.message}
            defaultValue={product?.stock || "0"}
          />

          <SelectInput
            required
            options={flavorOptions}
            label="Sabor"
            name="flavorId"
            control={control}
            error={!!errors.flavorId}
            helperText={errors.flavorId?.message}
            defaultValue={product?.flavorId}
          />

          <SelectInput
            required
            options={returnableOptions}
            label="Retornável?"
            name="returnable"
            control={control}
            error={!!errors.returnable}
            helperText={errors.returnable?.message}
            defaultValue={product?.returnable}
          />

          {shouldRenderReturnableNameField() && (
            <TextField
              disabled={!shouldRenderReturnableNameField()}
              inputRef={register}
              variant="outlined"
              margin="normal"
              fullWidth
              id="returnableName"
              label="Nome Retornável"
              name="returnableName"
              type="text"
              error={!!errors.returnableName}
              helperText={errors.returnableName?.message}
              defaultValue={product?.returnableName || ""}
            />
          )}

          <FormGroup row>
            <FormControlLabel
              className={classes.spaceBetween}
              control={<Checkbox defaultChecked={product?.individual} />}
              label="Venda Unitária?"
              name="individual"
              inputRef={register}
              labelPlacement="start"
            />
          </FormGroup>

          <FormControlLabel
            control={<Checkbox defaultChecked={product?.tastable} />}
            label="Degustação?"
            name="tastable"
            inputRef={register}
            labelPlacement="start"
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rowsMax={8}
            id="description"
            label="Descrição"
            name="description"
            type="text"
            error={!!errors.description}
            helperText={errors.description?.message}
            defaultValue={product?.description}
          />

          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {product ? "Atualizar Produto" : "Criar Produto"}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
        {location.state && product?.deleted === false && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={deleteHandler}
          >
            Inativar Produto
          </Button>
        )}
        {location.state && product?.deleted === true && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={reactivateHandler}
          >
            Ativar Produto
          </Button>
        )}
      </div>
    </Container>
  );
}

export default CreateProduct;
