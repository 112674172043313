import React from "react";

import { useFirebase, useFirestore } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Alert from "../../services/db/Alert";
import { useHistory, useLocation } from "react-router-dom";
import AlertImageInput from "../../components/inputs/AlertImageInput";
import { format } from "date-fns";

const schema = yup.object().shape({
  name: yup.string().trim(),
  description: yup.string().trim(),
  endDate: yup.date().required(),
  initialDate: yup.date().required(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "1.2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  media: {
    marginTop: 10,
    height: 140,
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

function getFormatedDate(dateInSeconds) {
  const date = new Date(dateInSeconds * 1000);

  return format(date, "yyyy-MM-dd");
}


function CreateAlert() {
  const firebase = useFirebase();
  const db = useFirestore();
  const history = useHistory();
  const location = useLocation();
  const { aviso, id } = location.state ? location.state : {};
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = async (formParams) => {
    setLoading(true);

    const image = formParams.image[0];
    console.log(formParams);
    if (!aviso && (!image || !image.type.match(/.(jpg|jpeg|png|gif)$/i))) {
      alert("Adicione uma imagem ao aviso");
      setLoading(false);
      return;
    }

    const alertData = {
      ...formParams,
    };
    delete alertData.image;
    console.log(alertData);

    if (aviso) {
      await Alert.updateWithImage(db, firebase, id, alertData, image);
    } else {
      await Alert.createWithImage(db, firebase, alertData, image);
    }

    setLoading(false);
    history.goBack();
  };

  const deleteHandler = async () => {
    setLoading(true);
    await Alert.deleteWithImage(db, firebase, id);
    setLoading(false);
    history.goBack();
  };

  console.log(aviso);

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <AlertImageInput
            required={!aviso}
            name="image"
            inputRef={register}
            alt={"Sem imagem"}
            src={aviso ? aviso.imageUrl : null}
            label={aviso ? "Alterar foto do Aviso" : "Adicionar foto do Aviso"}
          />
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome do aviso"
            name="name"
            autoComplete="name"
            type="text"
            error={!!errors.name}
            helperText={errors.name?.message}
            defaultValue={aviso ? aviso.name : null}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            id="description"
            label="Descrição"
            name="description"
            autoComplete="description"
            type="text"
            error={!!errors.description}
            helperText={errors.description?.message}
            defaultValue={aviso ? aviso.description : null}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="initialDate"
            label="Data Inicial"
            name="initialDate"
            type="date"
            error={!!errors.initialDate}
            helperText={errors.initialDate?.message}
            defaultValue={aviso ? getFormatedDate(aviso.initialDate.seconds) : null}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="endDate"
            label="Data Final"
            name="endDate"
            type="date"
            error={!!errors.endDate}
            helperText={errors.endDate?.message}
            defaultValue={aviso ? getFormatedDate(aviso.endDate.seconds) : null}
          />

          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {aviso ? "Atualizar aviso" : "Criar Aviso"}
            </Button>
            {aviso && (
              <Button
                fullWidth
                variant="contained"
                color="inherit"
                className={classes.submit}
                onClick={deleteHandler}
                disabled={loading}
              >
                Excluir Aviso
              </Button>
            )}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default CreateAlert;
