import React from 'react';

import Card             from '@material-ui/core/Card';
import CardActionArea   from '@material-ui/core/CardActionArea';
import CardContent      from '@material-ui/core/CardContent';
import CardMedia        from '@material-ui/core/CardMedia';
import Typography       from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory }    from 'react-router-dom';

export type CarrouselCardDataType = {
  imageUrl:string, 
  body:string,
  title:string,
  id: string
}

const useStyles = makeStyles((theme)=>({
  media: {
    height: 140,
  },
}));

function CarrouselCard(props:CarrouselCardDataType){
  const classes = useStyles();
  const history = useHistory();

  const redirect = () => {
    history.push({
      pathname:"/associated/aviso/view/"+props.id, 
      state: {aviso: {
        name: props.title,
        imageUrl: props.imageUrl,
        description: props.body
      }}
    })
  }

  return (
    <Card onClick={redirect}>
    <CardActionArea>
      <CardMedia
        className={classes.media}
        image={props.imageUrl}
        title="Contemplative Reptile"
      />
      <CardContent>
        <Typography gutterBottom color="textSecondary" variant="body2" component="p">
          {props.title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  )
}

export default CarrouselCard