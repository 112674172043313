import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";

import "./index.css";

import { onMessageListener } from "./services/Firebase";

import DriverLayout from "./layouts/DriverLayout";
import AssociatedLayout from "./layouts/AssociatedLayout";
import AdminLayout from "./layouts/AdminLayout";
import Login from "./pages/Login";
import TermView from "./pages/TermView";

import configs from "./config/colors";

import {
  userIsAssociated,
  userIsDriver,
  userIsAdmin,
  UserIsNotAuthenticated,
} from "./routes/auth";
import RecoverPassword from "./pages/RecoverPassword";
import ServiceWorkerWrapper from "./serviceWorkerWrapper";

const navTheme = createTheme(
  {
    palette: {
      primary: {
        main: configs.theme.mainColor,
      },
    },
  },
  ptBR
);

onMessageListener()
  .then((payload) => {
    console.log(payload.notification);
    alert(payload.notification.title + " " + payload.notification.body);
  })
  .catch((err) => console.log("failed: ", err));

function App() {
  return (
    <>
      <ThemeProvider theme={navTheme}>
        <ServiceWorkerWrapper />
        <CssBaseline />
        <Router>
          <Switch>
            <Route
              path="/associated"
              component={userIsAssociated(AssociatedLayout)}
            />
            <Route path="/driver" component={userIsDriver(DriverLayout)} />
            <Route path="/admin" component={userIsAdmin(AdminLayout)} />
            <Route path="/termo" component={TermView} />
            <Route exact path="/" component={UserIsNotAuthenticated(Login)} />
            <Route exact path="/recover_password" component={RecoverPassword} />
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
