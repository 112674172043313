import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import {
  Box,
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

import PropTypes from "prop-types";
import ReceiptIndexList from "../../../molecules/admin/ReceiptIndexList";
import { ReceiptIndexListItemType } from "../../../atoms/admin/ReceiptIndexListItem";
import PaymentsIndexDatePicker from "../../../atoms/associated/PaymentsIndexDatePicker";

export type receipt_index_type = {
  search_value: string;
  date: Date | null;
  setDate: (date: MaterialUiPickersDate) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setSearchValue: (value: string) => void;
  setStatus: (value: string) => void;
  list: ReceiptIndexListItemType[];
};

export default function ReceiptIndexTemplate(props: receipt_index_type) {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    if (newValue === 0) {
      props.setStatus("pending");
      setValue(0);
    } else {
      props.setStatus("accepted");
      setValue(1);
    }
  };

  const handleTextFieldChange = (evt: { target: { value: any } }) => {
    let newValue = evt.target.value;
    props.setLoading(true);
    props.setSearchValue(newValue.toLowerCase());
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="simple tabs full width"
      >
        <Tab label="Pendentes" {...tabProps(0)} />
        <Tab label="Recebidos" {...tabProps(1)} />
      </Tabs>
      <TextField
        id="filled-secondary"
        label="Procurar"
        variant="filled"
        fullWidth={true}
        value={props.search_value}
        onChange={handleTextFieldChange}
        color="primary"
      />
      <PaymentsIndexDatePicker date={props.date} setDate={props.setDate} />

      <TabPanel value={value} index={0}>
        <div className={classes.tabContent}>
          {props.loading && (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}
          {props.list && <ReceiptIndexList list={props.list} />}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabContent}>
          {props.loading && (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}
          {props.list && <ReceiptIndexList list={props.list} />}
        </div>
      </TabPanel>
    </div>
  );
}

TabPanel.props = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    width: "100%",
  },
  acceptedText: {
    color: "green",
  },
  refusedText: {
    color: "red",
  },
}));
