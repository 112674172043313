import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirebaseInstance, ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel, { ModelDict } from "./Application"

type AlertFields = {
  name: string,
  description: string, 
  imageUrl: string, 
  initialDate: Date,
  endDate: Date
}

export default class Alert extends ApplicationModel {

  public static modelName = "alerts";

  constructor(
      public db: ExtendedFirestoreInstance,
      public props: AlertFields,
      public docRef: DocumentReference<DocumentData>
  ) {
      super(db, props, docRef)
  }

  static async getActive(db: ExtendedFirestoreInstance){
    let today = new Date((new Date()).toDateString());
    let query = db.collection("alerts")
      .where("endDate", ">=", today)
    
    let ret: ModelDict<Alert> = {}
    const data = await query.get();
    await Promise.all(data.docs.map(async obj =>{
      let data = obj.data()
      if(today >= data.initialDate)
        ret[obj.id] = new Alert(db, data as AlertFields, obj as any) 
    }))

    return ret
  }

  static async createWithImage(db: ExtendedFirestoreInstance, firebase:ExtendedFirebaseInstance, props:any, image:File){
    // Create new File on storage and change its name to avoid conflict

    if(image) {
      const newImageUrl = await Alert.createUrlForFile(firebase, image);
      props.imageUrl = newImageUrl
    }
    
    await Alert.create(db, {
      ...props,
    })
  }

  static async updateWithImage(db: ExtendedFirestoreInstance, firebase:ExtendedFirebaseInstance ,id:string, props:any, image:File){
    let alert = await Alert.find(db, id);
    if(alert){
      // Delete storage image
      if(image) {
        let oldImageUrl = alert.props.imageUrl

        if(oldImageUrl) {
          let imageRef = firebase.storage().refFromURL(oldImageUrl);
          await imageRef.delete();
        }

        const newImageUrl = await Alert.createUrlForFile(firebase, image) 
        props.imageUrl = newImageUrl
      } 


      await Alert.update(db, id, {
        ...props,
      })
    }
  }

  static async deleteWithImage(db:ExtendedFirestoreInstance, firebase: ExtendedFirebaseInstance, uid: string){
    let alert = await Alert.find(db, uid);
    if(alert){
      // Delete storage image
      let imageUrl = alert.props.imageUrl
      let imageRef = firebase.storage().refFromURL(imageUrl);
      await imageRef.delete();

      // Delete from db
      await db.collection("alerts").doc(uid).delete();
    }
  }

  private static async createUrlForFile(firebase:ExtendedFirebaseInstance, file:File){
    // Create new File on storage and change its name to avoid conflict
    const newFile = new File([file], Math.random().toString(36).substring(7) + file.name, {type: file.type});
    const uploadedImage = await firebase.uploadFile("avisos", newFile);
    const newImageUrl = await uploadedImage.uploadTaskSnapshot.ref.getDownloadURL();  

    return newImageUrl
  }
}
