import ReceiptIndexListItem, {
  ReceiptIndexListItemType,
} from "../../atoms/admin/ReceiptIndexListItem";
import React from "react";
import { List } from "@material-ui/core";

export default function ReceiptIndexList(props: {
  list: ReceiptIndexListItemType[];
}) {
  return (
    <List>
      {props.list.map((listItem, index) => {
        return (
          <div key={index}>
            <ReceiptIndexListItem {...listItem} />
          </div>
        );
      })}
    </List>
  );
}
