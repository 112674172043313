import React from "react";
import {
  List,
} from "@material-ui/core";

import AssociatedItem from "./AssociatedItem";
import DailyOrderPresenter from "../../../app/presenters/driver/DailyOrderPresenter";
import usePresenter from "../../../app/redux/usePresenter";

function AssociatedListComponent(props) {
  const list = usePresenter(new DailyOrderPresenter(props.driverId)) || []

  return (
    <List>
      {list.map((item) => {
        return ( <AssociatedItem item={item} key={item.id} removeRedirect={props.removeRedirect}/> );
      })}
    </List>
  );
}

export default AssociatedListComponent;
