import React, { useState } from "react";

import { List, makeStyles } from "@material-ui/core";

import { Droppable } from "react-beautiful-dnd";
import DragItem from "../../components/DragAndDrop/DragItem";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "10px",
    backgroundColor: "white",
  },
}));

/**
 * Espera lista de entradas no segunite formato
 * {
 *  items: [
 *    {
 *      primary: string,
 *      secondary: string,
 *      imageSrc: string,
 *      avatar?: string||componente
 *    }
 *  ]
 * }
 */
function DragCollum({ items, id, onClickItem, style }) {
  const classes = useStyles();

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <List
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classes.container}
          style={style}
        >
          {items.map((item, index) => (
            <DragItem
              key={index}
              index={index}
              id={item.id}
              columnId={id}
              primary={item.primary}
              secondary={item.secondary}
              imageSrc={item.imageSrc}
              avatar={item.avatar}
              onClickItem={onClickItem}
              shouldNotReceive={item.shouldNotReceive}
            />
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
}

export default DragCollum;
