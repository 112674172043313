import database from "../database/DataBaseConfig";
import Product from "../entities/Product";



export default class ProductInteractor{

  static async getProductFlavorName(product:Product){
    let flavor = await database.flavor_db.find(product.flavorId)
    if(!flavor) return "Sem sabor"
    return flavor.name
  }

}