import ApplicationEntity from "./ApplicationEntity";



export default class Flavor extends ApplicationEntity{
  name: string
  deleted: boolean|undefined
  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }

  static defaultFlavor(){
    return new Flavor("<inexistent id>", 
      {
        name: "<Sabor Inexistente>",
        deleted: false
      }
    )
  }
}