import DriverProfile from "../pages/driver/DriverProfile";
import DailyShipping from '../pages/driver/DailyShipping'
import DailyOrder from '../pages/driver/DailyOrder'
import DriverDelivery from '../pages/driver/DriverDelivery';
import DriverChat from '../pages/driver/DriverChat'

import {ReactComponent as TruckIcon} from '../assets/truckImage.svg';
import {ReactComponent as TodayIcon} from '../assets/today.svg';
import {ReactComponent as PersonIcon} from '../assets/person.svg';

/* Formato das rotas de motorista
{
    name:       "string",
    title:      "string",
    icon:       "Componente",
    component:  "Component",
    path:       "string"
}
*/
export default [
    {
        name: "Carga do Dia",
        title: 'Carga do Dia',
        icon: TruckIcon,
        component: DailyShipping ,
        path: "/daily_shipping",
    },
    {
        name: "Entregas do Dia",
        title: 'Entregas do Dia',
        icon: TodayIcon,
        path: '/daily_delivery',
        component: DailyOrder
    },
    {
        name: "Perfil",
        title: 'Meu Perfil',
        icon: PersonIcon,
        path: '/profile',
        component: DriverProfile
    },
    {
        name: "Entrega",
        title: 'Entrega',
        path: '/delivery',
        param: '/:associated_id',
        component: DriverDelivery ,
        redirectNavigation: true,
        backButtom: true
    },
    {
        name: "Fale com o Administrador",
        title: 'Fale com o Administrador',
        component: DriverChat,
        path: "/chat",
        redirectNavigation: true,
        backButtom: true
    },
]
