import React, { useEffect, useState } from "react";

import ReceiptIndexTemplate from "../../app/components/templates/admin/receipt/ReceiptIndexTemplate";
import usePresenter from "../../app/redux/usePresenter";
import IndexReceiptPresenter from "../../app/presenters/admin/receipt/IndexReceiptPresenter";

export default function ReceiptIndex() {
  let [date, setDate] = useState(new Date());
  let [loading, setLoading] = useState(true);
  let [query, setQuery] = useState("");
  let [status, setStatus] = useState("pending");

  let list = usePresenter(new IndexReceiptPresenter(date, status, query));

  useEffect(() => {
    setLoading(loading === undefined);
  }, [list, loading]);

  return (
    <ReceiptIndexTemplate
      search_value={query}
      date={date}
      setDate={setDate}
      loading={loading}
      setLoading={setLoading}
      setSearchValue={setQuery}
      list={list}
      setStatus={setStatus}
    />
  );
}
