import React from "react";

import { Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CarrouselCard, {
  CarrouselCardDataType,
} from "../../atoms/associated/CarrousselCard";
import { useSelector } from "react-redux";
import usePresenter from "../../../redux/usePresenter";
import CarouselPresenter from "../../../presenters/associated/Mural/CarouselPresenter";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  hText: {
    margin: theme.spacing(2),
  },
}));

function CarouselMural() {
  const classes = useStyles();
  const associatedAuth = useSelector((state: any) => state.firebase.auth);

  const data = usePresenter(new CarouselPresenter(associatedAuth.uid)) || [];

  if (data.length === 0) return <></>;

  return (
    <>
      <Divider />
      <div className={classes.hText}>
        <h3>{"Avisos"}</h3>
      </div>
      <Divider variant="inset" />
      <Carousel animation="slide">
        {data.map((item: CarrouselCardDataType, i: number) => (
          <CarrouselCard key={i} {...item} />
        ))}
      </Carousel>
      <div style={{ width: "90%", margin: "10px auto" }}></div>
    </>
  );
}

export default CarouselMural;
