import React, { useEffect } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Container, Button, makeStyles, Divider } from "@material-ui/core";
import AssociatedFormHeader from "../../../organisms/admin/associated/AssociatedFormHeader";
import AssociatedProductsForm from "../../../organisms/admin/associated/AssociatedProductsForm";
import AssociatedFormBody from "../../../organisms/admin/associated/AssociatedFormBody";
import EditAssociatedPresenter, {
  AssociatedFormValues,
  EditAssociatedFormProps,
} from "../../../../presenters/admin/associated/EditAssociatedPresenter";
import AssociatedAlergiesForm from "../../../organisms/admin/associated/AssociatedAlergiesForm";
import LoadingCircle from "../../../atoms/LoadingCircle";
import { db } from "../../../../../services/Firebase";
import DriverOrderInteractor from "../../../../interactors/driver/DriverOrderInteractor";
import { deleteStorage } from "../../../../redux/backendStorage";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
}));

const schema = yup.object().shape({
  displayName: yup.string().required("Nome é obrigatório").trim(),
  cpf: yup
    .string()
    .required("CPF é obrigatório")
    .trim(),
  email: yup.string().email().required("Email é obrigatório").trim(),
  phone: yup.string().required("Telefone é obrigatório").trim(),

  cep: yup.string().required("CEP deve existir").trim(),
  address: yup.string().required("Endereço deve existir").trim(),
  deliveryAddress: yup
    .string()
    .required("Endereço de entrega deve existir")
    .trim(),
  neighborhoodId: yup.string().required("Bairro deve existir").trim(),

  birthdate: yup.date().required(),
  observation: yup.string().trim(),

  deliveryInfo:yup.object({baseDeliveryDate: yup.date()}),

  // Nested
  paymentPlan: yup.array().of(
    yup.object().shape({
      productId: yup.string().required("Selecione um produto"),
      quantity: yup.number().min(1, "Selecione no mínimo 1").required(),
      frequency: yup.string().required("Selecione uma frequencia"),
      confirmed: yup.boolean().required(),
    })
  ),
  individualPurchases: yup.array().of(
    yup.object().shape({
      productId: yup.string().required("Selecione um produto").trim(),
      quantity: yup.number().min(1, "Selecione no mínimo 1").required(),
      confirmed: yup.boolean().required(),
    })
  ),
  intolerances: yup.array().of(
    yup.object().shape({
      flavorId: yup.string().required("Selecione a Alergia").trim(),
      confirmed: yup.boolean().required(),
    })
  ),
});

export default function EditAssociatedForm({
  id,
  defaultValues,
  planProducts,
  individualProducts,
  neighborhoods,
  flavors,
  deleted,
}: EditAssociatedFormProps) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    reset,
    control,
    register,
    errors,
    watch,
    setValue,
    trigger,
    getValues,
  } = useForm<AssociatedFormValues>({
    resolver: yupResolver(schema),

    defaultValues: {
      displayName: "",
      cpf: "",
      email: "",
      phone: "",
      cep: "",
      address: "",
      deliveryAddress: "",
      neighborhoodId: "",
      password: "",

      entry_date: "",
      birthdate: "",
      observation: "",
      delivery_observation: "",
      deliveryInfo:{
        baseDeliveryDate:"",
      },

      // Nested
      paymentPlan: [],
      individualPurchases: [],
      intolerances: [],
    },
  });

  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  const inactivateAssociated = async () => {
    EditAssociatedPresenter.inactivateAssociated(id!);
    history.goBack();
  };

  const activateAssociated = async () => {
    EditAssociatedPresenter.activateAssociated(id!);
    history.goBack();
  };

  const submitHandler = async (formInfo: any) => {
    console.log(formInfo)
    if (formInfo.password.length > 0 && formInfo.password.length < 6) {
      alert("Senha precisa ter pelo menos 6 caracteres");
      return;
    }
    if (
      formInfo.paymentPlan &&
      formInfo.paymentPlan.find((prod: any) => prod.confirmed === false)
    ) {
      alert("Confirme todos os produtos do plano antes de continuar");
      return;
    }
    if (
      formInfo.individualPurchases &&
      formInfo.individualPurchases.find((prod: any) => prod.confirmed === false)
    ) {
      alert("Confirme todos os produtos avulsos antes de continuar");
      return;
    }
    setLoading(true);
    if (id) {
      try {
        await EditAssociatedPresenter.updateAssociated(id, formInfo);
      } catch (error) {
        alert("Erro: " + error);
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingCircle show={true} />;
  }

  return (
    <div>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <AssociatedFormHeader
              control={control}
              register={register}
              errors={errors}
              neighborhoods={neighborhoods || []}
            />
            <Divider />
            <h3>Produtos - Plano</h3>
            <AssociatedProductsForm
              control={control}
              register={register}
              errors={errors}
              name={"paymentPlan"}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              frequency_options={[
                {
                  label: "Semanal",
                  value: "weekly",
                },
                {
                  label: "Quinzenal",
                  value: "biweekly",
                },
              ]}
              all_products={planProducts || []}
            />
            <Divider />
            <h3>Produtos - Avulsos</h3>
            <AssociatedProductsForm
              control={control}
              name={"individualPurchases"}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              frequency_options={[]}
              all_products={individualProducts || []}
            />
            <Divider />
            <h3>Outras Informações</h3>
            <AssociatedFormBody
              control={control}
              register={register}
              errors={errors}
            />
            <Divider />
            <h3>Alergias</h3>
            <AssociatedAlergiesForm
              control={control}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              all_flavors={flavors || []}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Atualizar Associado
            </Button>
            <div style={{ height: "40px" }}></div>

            {!deleted && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => {
                  inactivateAssociated();
                }}
              >
                Inativar Associado
              </Button>
            )}
            <div style={{ height: 10 }}></div>
            {deleted && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => {
                  activateAssociated();
                }}
              >
                Ativar Associado
              </Button>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
}
