import React, {useState} from 'react'
import {
    useLocation
} from "react-router-dom";
import {  useDispatch } from "react-redux";
import RefreshIcon from '@material-ui/icons/Refresh';
import AssociatedList from "../../components/driver/daily_order/AssociatedsList";
import { Divider, Container, Toolbar, Typography } from '@material-ui/core';


export default function DriverOrder() {
    const location = useLocation()
    const dispatch = useDispatch();
    let [driverId, ] = useState(location.state ? location.state.id : "")
    const todayDate = new Date().getDay();

    const atualizarLista = () => {
        dispatch({type: "app/resetState"})
    }

    return (<div>
        <Container>
            <Toolbar>
            <Typography variant="h6">Entregas do motorista:
                <span style={{right:16, position:"absolute"}}><RefreshIcon style={{marginTop:"3px"}} onClick={atualizarLista}/></span>
            </Typography>
            </Toolbar>
            <Divider variant="inset"/>
        </Container>
        <Divider variant="inset" />
        <AssociatedList driverId={driverId} todayDate={todayDate} removeRedirect/>
    </div>)
}
