import { remoteConfig } from "../../services/Firebase";
import store from "../../store";
import { resetFrontState } from "./frontendStorage";

export function deleteStorage(path){
  store.dispatch({type: "app/updateStorage", path:path , payload: undefined});
  resetFrontState()
}

export function updateStorage(path, data){
  store.dispatch({type: "app/updateStorage", path:path , payload: data});
  // resetFrontState() // ISSO AQ FAZ A TELA PISCAR CUIDADO
}

export function getStorage(path){
  let state = store.getState().backend;
  return state[path];
}

export async function injectReduxStore(db_function, path){
  let storage = getStorage(path);
  if(storage) return storage;    
  let data = await db_function();
  updateStorage(path, data);
  return data;
}

export async function getConfig(config) {
  let storage = getStorage("app/firebase/configs");
  if(!storage){
    await remoteConfig.fetch();
    await remoteConfig.activate();
    if(true) updateStorage("app/firebase/configs", "loaded");
    else throw new Error("Unable to get app configs");
  }
  console.log(remoteConfig.getAll())
  return remoteConfig.getValue(config);
}