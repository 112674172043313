import Product from "../../entities/Product";
import ProductDBInterface from "../interfaces/ProductDBInterface";
import { allWithRedux } from "./utils/all_firebase";
import { findWithRedux } from "./utils/find_firebase";

export default class ProductFirebase implements ProductDBInterface{
  async find(id:string):Promise<Product | undefined>{
    return findWithRedux(id, "products", Product);
  }

  async allActive():Promise<Product[]> {
    let pros = await allWithRedux("products", Product) as Product[];
    return pros;
    // return pros.filter((prod) => ( prod.deleted !== true ));
  }
}