import React from "react";

import { useFirebase, useFirestore } from "react-redux-firebase";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useHistory, useLocation, useParams } from "react-router-dom";
import SystemAlert from "../../services/db/SystemAlert";
import AlertImageInput from "../../components/inputs/AlertImageInput";

const schema = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatarWrapper: {
    margin: theme.spacing(2),
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  media: {
    marginTop: 10,
    height: 140,
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

function EditSystemAlert() {
  const firebase = useFirebase();
  const db = useFirestore();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const { systemAlert } = location.state ? location.state : {};
  let { id } = params;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = async (formParams) => {
    const image = formParams.image[0];
    if (!image || !image.type.match(/.(jpg|jpeg|png|gif)$/i)) {
      alert("Adicione uma imagem ao aviso");
      return;
    }
    setLoading(true);

    const alertData = {
      ...formParams,
    };

    delete alertData.image;
    console.log(alertData);

    await SystemAlert.updateWithImage(db, firebase, id, alertData, image);

    setLoading(false);
    history.goBack();
  };

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
          <AlertImageInput
            required
            name="image"
            inputRef={register}
            alt={"Sem imagem"}
            src={systemAlert.imageUrl}
            label={
              systemAlert ? "Alterar foto do Aviso" : "Adicionar foto do Aviso"
            }
          />
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome do aviso"
            name="name"
            autoComplete="name"
            type="text"
            error={!!errors.name}
            helperText={errors.name?.message}
            defaultValue={systemAlert.name}
          />

          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            id="description"
            label="Descrição"
            name="description"
            autoComplete="description"
            type="text"
            error={!!errors.description}
            helperText={errors.description?.message}
            defaultValue={systemAlert.description}
          />

          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {"Atualizar aviso"}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}

export default EditSystemAlert;
