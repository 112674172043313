import React from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import {  TextField } from "@material-ui/core";

type form_params  = { 
  control: Control<Record<string, any>>, 
  errors: DeepMap<Record<string, any>, FieldError>
  options: any[],
  name:string,
  label: string,
  hiddenOptions?: {value:string}[],
  [id:string]:any
} 

const FormSelect = ({control, errors, options, name, label, hiddenOptions, ...props}: form_params) => {
  hiddenOptions = hiddenOptions || [];
  return (
    <Controller 
      style={{marginTop: 0}}
      as={(
        <TextField
          fullWidth
          select
          SelectProps={{
            native: true,
            inputProps: { name }
          }}
          label={label}
        >
          <option key={""} value={""}>
              {""}
          </option>
          {options.map((option) => (
            <option key={option.label} value={option.value} hidden={hiddenOptions?.some((opt)=> opt.value === option.value)}>
              {option.label}
            </option>
          ))}
        </TextField>
      )}
      variant="outlined"
      margin="normal"
      required
      id={name}
      fullWidth
      name={name}
      control={control}
      error={!!errors[name]}
      helperText={errors[name]?.message}
      { ...props}
    /> 
  )
}

export default FormSelect;


