import React, { useEffect, useState } from 'react';
import Chat from "../../components/Chat/Chat"
import { isLoaded, useFirebase } from "react-redux-firebase";
import {useLocation} from "react-router-dom";

export default function AdminChat() {
    const firebase = useFirebase()
    const user = firebase.auth().currentUser;
    const location = useLocation();
    const receiverUserId = location.state ? location.state.id : ""

    return (<Chat user1={user.uid} user2={receiverUserId} fromId={user.uid} toId={receiverUserId} />)
}
