import database from "../../database/DataBaseConfig";
import Associated from "../../entities/Associated";
import Associated_Delivery from "../../entities/Associated_Delivery";
import { ProductOnDelivery } from "./DeliveryInfoInteractor";
import DeliveryInteractor from "./DeliveryInteractor";

export default class DeliveryHistoryInteractor{

  static async deliveryHistoryOnWeek(associated_id:string, date:Date):Promise<Associated_Delivery|null>{
    let deliveries = await database.associated_delivery_db.findDeliveriesOnDate(associated_id, date);
    if(deliveries.length === 0)return null;
    let basehistory = deliveries[0];

    return deliveries.slice(1).reduce((acc, history)=> DeliveryHistoryInteractor.mergeDeliveryHistory(acc, history), basehistory)
  }

  static mergeDeliveryHistory(src:Associated_Delivery, dest:Associated_Delivery){
    for(let product of dest.products){
      let index = src.products.findIndex( (p) => p.productId === product.productId)
      if(index !== -1) src.products[index].quantity += product.quantity
      else src.products.push(product)
    }

    for(let product of dest.returnedProducts){
      let index = src.returnedProducts.findIndex( (p) => p.productId === product.productId)
      if(index !== -1) src.returnedProducts[index].quantity += product.quantity
      else src.returnedProducts.push(product)
    }

    for(let product of dest.broken){
      let index = src.broken.findIndex( (p) => p.productId === product.productId)
      if(index !== -1) src.broken[index].quantity += product.quantity
      else src.broken.push(product)
    }

    return src
  }

  static async wasLastDeliveryComplete(associated:Associated):Promise<boolean>{
    let history = await DeliveryHistoryInteractor.deliveryHistoryOnWeek(associated.id, associated.deliveryInfo.lastDeliveryDate || new Date());
    let correct_delivery = await DeliveryInteractor.shouldReceiveToday(associated.id);
    if(!history) return false;

    for(let item of correct_delivery){
      if( !history.products.some((prod) =>( prod.productId === item.id && prod.quantity === item.total) )){
        return false;
      }
    }

    let correct_return = await DeliveryInteractor.shouldReturnToday(associated.id);
    for(let item of correct_return){
      if( !history.returnedProducts.some((prod) =>( prod.productId === item.id && prod.quantity === item.total) )){
        return false;
      }
    }

    return true;
  }

  static async waslastWeekDeliveryComplete(associated:Associated):Promise<boolean>{
    let products = await DeliveryInteractor.shouldReceiveToday(associated.id);
    let to_return_list = associated.deliveryInfo.pendingItems
    if(!to_return_list) return true;
    
    for(let returnable of to_return_list){
      let product = findProductOnList(returnable.productId, products);
      if(!product) return false;
      if(product.total !== returnable.quantity) return false;
    }
    
    return true;
  }
}

function findProductOnList(id:string, list: ProductOnDelivery[]){
  for(let product of list){
    if(id === product.id) return product
  }
  return null;
}