import {
  BottomNavigation,
  Divider,
  IconButton,
  makeStyles,
  Button,
  Avatar,
  TextField,
  Badge,
  Fab,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";

import { DragDropContext } from "react-beautiful-dnd";
import { useFirestore } from "react-redux-firebase";

import DragCollum from "../../components/DragAndDrop/DragCollum";

import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Associated from "../../services/db/Associated";

import { useParams, useHistory } from "react-router-dom";
import Driver from "../../services/db/Driver";
import VisibilityIcon from "@material-ui/icons/Visibility";
import firebase from "../../services/Firebase";

import ChatIcon from "@material-ui/icons/Chat";
import { useDispatch } from "react-redux";
import ModalProductsOnDelivery from "../../app/components/templates/admin/ModalProductsOnDelivery";
import { deleteStorage } from "../../app/redux/backendStorage";
import resolveOrderInconsistency from "../../app/interactors/driver/ResolveOrderInconsistency";
import DeliveryInteractor from "../../app/interactors/delivery/DeliveryInteractor";
import AssociatedInteractor from "../../app/interactors/AssociatedInteractor";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#EBEBEB",
  },
  hText: {
    margin: theme.spacing(2),
    backgroundColor: "#EBEBEB",
  },
  hWithInput: {
    margin: theme.spacing(1),
    backgroundColor: "#EBEBEB",
    display: "flex",
    flexDirection: "row",
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  shape: {
    width: 40,
    height: "1em",
  },
  actionButton: {
    float: "right",
    padding: "0px 12px",
  },
  blank: {
    display: "flex",
    backgroundColor: "transparent",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const diasDaSemana = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];

async function parseAssociatedListToDND(associateds) {
  return await Promise.all(
    associateds.map(async (associated) => {
      let neighbor = associated.neighborhood;

      let shouldReceive =
        (await DeliveryInteractor.shouldReceiveToday(associated.docRef.id))
          .length !== 0;

      return {
        id: associated.docRef.id,
        primary: associated.props.displayName,
        secondary:
          neighbor.props.name + " - " + associated.props.deliveryAddress,
        shouldNotReceive: !shouldReceive,
        avatar: (
          <Avatar style={{ backgroundColor: neighbor.props.color || 0 }}>
            {neighbor.props.name[0]}
          </Avatar>
        ),
      };
    })
  );
}

function EditDriverDeliveries() {
  const db = useFirestore();
  const classes = useStyles();
  const history = useHistory();
  const updateDriverDeliveryOrder = firebase
    .functions()
    .httpsCallable("updateDriverDeliveryOrder");
  const params = useParams();
  const dispatch = useDispatch();
  let { id } = params;

  const [modalOpen, setModalOpen] = useState(false);
  const [enableUpdates, setEnableUpdates] = useState(true);
  const [query, setQuery] = useState("");
  const [queryItems, setQueryItems] = useState([]);
  const [state, setState] = useState({
    column1: {
      id: "column1",
      items: [],
    },
    column2: {
      id: "column2",
      items: [],
    },
  });

  const [driver, setDriver] = useState();
  const [bottomBar, setBottomBar] = useState(0);

  const updateColumn = (columnId, newItems) => {
    let newState = { ...state };
    newState[columnId].items = newItems;
    setState(newState);
  };

  useEffect(() => {
    (async () => {
      let driver = await Driver.find(db, id);
      setDriver(driver);
      // Atualiza titulo da pagina
      dispatch({
        type: "app/title/update",
        payload: "Entregas de " + driver.props.displayName,
      });
      dispatch({
        type: "app/menuOptions/update",
        payload: [
          {
            name: "Editar Motorista",
            redirectPath: "/admin/driver/create",
            state: { uid: driver.docRef.id, props: driver.props },
          },
          {
            name: "Rota do dia",
            redirectPath: "/admin/driver/route",
            state: { id: driver.docRef.id },
          },
        ],
      });

      resolveOrderInconsistency(id).then((hasError) => {
        if (hasError) {
          alert(
            "Inconsistencias foram encontradas, a pagina sera recarregada para realizar o ajuste"
          );
          window.location.reload();
        }
      });

      // Atualiza valores da coluna de desalocados
      const associatedsWithoutDriver = await Associated.search(
        db,
        "deliveryInfo.driverId",
        null
      );
      let activeAssociateds = Object.values(associatedsWithoutDriver).filter(
        (a) => a.props.deleted === false
      );
      await Associated.awaitForAssociations(activeAssociateds);
      const dataFiltered = await parseAssociatedListToDND(activeAssociateds);

      updateColumn("column1", dataFiltered);
      setQueryItems(dataFiltered);
    })();
  }, [db, id]);

  useEffect(() => {
    (async () => {
      const thisDriver = driver ? driver : await Driver.find(db, id);
      let dayNumber = bottomBar + 1;
      let associatedsFetched = await thisDriver.deliveries.getOrderFor(
        dayNumber
      );
      await Associated.awaitForAssociations(associatedsFetched);
      let dataFiltered = await parseAssociatedListToDND(associatedsFetched);
      updateColumn("column2", dataFiltered);
    })();
  }, [db, bottomBar, driver]);

  const handleQueryChange = (evt) => {
    let newValue = evt.target.value;
    setQuery(newValue);
    updateQueryItems(newValue);
  };

  const updateQueryItems = (newQuery) => {
    setQueryItems(
      state.column1.items.filter((item) => {
        let name = item.primary.toLowerCase();
        let neigh = item.secondary.toLowerCase();
        let queryNorm = newQuery.toLowerCase();
        if (newQuery === "") return true;
        return name.startsWith(queryNorm) || neigh.startsWith(queryNorm);
      })
    );
  };

  const onDragEnd = async (result) => {
    const sourceItems = state[result.source.droppableId].items;
    const draggedItem = sourceItems[result.source.index];
    const associatedId = draggedItem.id;
    let dayNumber = bottomBar + 1;

    // return if item was dropped outside
    if (!result.destination) return;
    if (!enableUpdates) return;
    // return if the item was dropped in the same place
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;
    if (result.source.droppableId !== result.destination.droppableId) {
      // update sourceColumn
      const newItemsSource = [...sourceItems];
      newItemsSource.splice(result.source.index, 1);
      updateColumn(result.source.droppableId, newItemsSource);

      // update destination
      const destinationItems = state[result.destination.droppableId].items;
      const newItemsdestination = [...destinationItems];

      newItemsdestination.splice(result.destination.index, 0, draggedItem);

      updateColumn(result.destination.droppableId, newItemsdestination);
    } else {
      const items = state[result.source.droppableId].items;

      const newItems = [...items];
      // delete the item from source position and insert it to the destination positon
      newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, draggedItem);
      // console.log(newItems);
      updateColumn(result.destination.droppableId, newItems);
    }
    // Update Collumns
    updateQueryItems(query);

    let newData = {
      associatedId,
      driverId: driver.docRef.id,
      weekDay: dayNumber,
    };
    if (
      result.source.droppableId === "column1" &&
      result.destination.droppableId === "column1"
    )
      return;
    if (
      result.source.droppableId === "column1" &&
      result.destination.droppableId === "column2"
    ) {
      console.log("Adicionado ao Motorista");
      newData.order = driver.deliveries.addAssociatedOnPosition(
        dayNumber,
        associatedId,
        result.destination.index
      );
      newData.type = "ADD";
    }
    if (
      result.source.droppableId === "column2" &&
      result.destination.droppableId === "column1"
    ) {
      console.log("Removido do Motorista");
      newData.order = driver.deliveries.removeAssociatedFromDriver(
        dayNumber,
        associatedId
      );
      newData.type = "REMOVE";
    }
    if (
      result.source.droppableId === "column2" &&
      result.destination.droppableId === "column2"
    ) {
      console.log("Updated no Motorista");
      newData.order = driver.deliveries.updateAssociatedPosition(
        dayNumber,
        associatedId,
        result.destination.index
      );
      newData.type = "UPDATE";
    }
    setEnableUpdates(false);
    await updateDriverDeliveryOrder(newData);
    await driver.refresh();
    setEnableUpdates(true);
    deleteStorage("driver/" + id);
  };

  return (
    <div className={classes.background}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Divider />
        <div className={classes.hWithInput}>
          <div className={classes.hText}>
            <b>Associados não Alocados</b>
          </div>
          <div style={{ margin: "auto 0px" }}>
            <TextField
              label="Procurar"
              value={query}
              onChange={handleQueryChange}
              color="primary"
            />
          </div>
        </div>
        <DragCollum
          style={{
            maxHeight: "25vh",
            overflowY: "scroll",
          }}
          id={state.column1.id}
          items={queryItems}
        />
        <div className={classes.hText}>
          <h3>
            {"Ordem de entrega "}({diasDaSemana[bottomBar]})
            <IconButton
              edge="start"
              color="inherit"
              className={classes.actionButton}
              onClick={() => setModalOpen(true)}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.actionButton}
              onClick={() => {
                history.push({
                  pathname: "/admin/chat",
                  state: {
                    id,
                  },
                });
              }}
            >
              <ChatIcon />
            </IconButton>
          </h3>
        </div>
        <DragCollum id={state.column2.id} items={state.column2.items} />
        <div className={classes.blank} />
      </DragDropContext>
      <BottomNavigation
        value={bottomBar}
        onChange={(event, newValue) => {
          if (enableUpdates) setBottomBar(newValue);
        }}
        showLabels
        className={classes.bottomBar}
      >
        {diasDaSemana.map((dia, index) => {
          let icon = <></>;
          if (driver) {
            let delivery = driver.props.deliveries.find(
              (delivery) => delivery.weekDay - 1 === index
            );
            if (delivery) {
              let numberofdeliveries = delivery.order.length;
              if (numberofdeliveries !== 0) {
                icon = (
                  <Badge badgeContent={numberofdeliveries} color="primary">
                    {<div className={classes.shape} />}
                  </Badge>
                );
              }
            }
          }
          return <BottomNavigationAction key={index} label={dia} icon={icon} />;
        })}
      </BottomNavigation>

      {driver && (
        <ModalProductsOnDelivery
          open={modalOpen}
          setOpen={setModalOpen}
          weekDay={bottomBar + 1}
          driverId={driver.docRef.id}
        />
      )}
    </div>
  );
}

export default EditDriverDeliveries;
