import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import {createBrowserHistory} from "history";
import roles from "../constants/roles";

// console.log(roles.ADMIN);
// console.log(roles.ASSOCIATED);
// console.log(roles.DRIVER);

const locationHelper = locationHelperBuilder({});
const browserHistory = createBrowserHistory();

const redirectToRolePath = (profile) => {
  switch (profile.role) {
    case roles.DRIVER:
      return "/driver";

    case roles.ASSOCIATED:
      return "/associated";

    case roles.ADMIN:
      return "/admin";

    default:
      return "/";
  }
};

const redirectPathHandler = ({ firebase: { profile } }, ownProps) =>
  locationHelper.getRedirectQueryParam(ownProps) || redirectToRolePath(profile);

export const userIsDriver = connectedRouterRedirect({
  wrapperDisplayName: "UserIsDriver",
  allowRedirectBack: false,
  redirectPath: redirectPathHandler,
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || !profile.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile.role === roles.DRIVER,
  redirectAction: (newLoc) => (dispatch) => {
    browserHistory.replace(newLoc);
    dispatch({ type: "UNAUTHED_REDIRECT" });
  },
});

export const userIsAssociated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAssociated",
  allowRedirectBack: false,
  redirectPath: redirectPathHandler,
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || !profile.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile.role === roles.ASSOCIATED,
  redirectAction: (newLoc) => (dispatch) => {
    browserHistory.replace(newLoc);
    dispatch({ type: "UNAUTHED_REDIRECT" });
  },
});

export const userIsAdmin = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAdmin",
  allowRedirectBack: false,
  redirectPath: redirectPathHandler,
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || !profile.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile.role === roles.ADMIN,
  redirectAction: (newLoc) => (dispatch) => {
    
    browserHistory.replace(newLoc);
    dispatch({ type: "UNAUTHED_REDIRECT" });
  },
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsNotAuthenticated",
  allowRedirectBack: false,
  redirectPath: redirectPathHandler,
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || !profile.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  redirectAction: (newLoc) => (dispatch) => {
    browserHistory.replace(newLoc); // or routerActions.replace
    dispatch({ type: "UNAUTHED_REDIRECT" });
  },
});
