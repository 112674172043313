import database from "../../database/DataBaseConfig";
import Driver from "../../entities/Driver";
import firebase from "../../../services/Firebase";

export default class DriverOrderInteractor{

  static async removeAssociatedFromDeliveries(associatedId:string){
    const deleteAssociatedFromDriver = firebase.functions().httpsCallable('updateDriverDeliveryOrder')
    let associated = await database.associated_db.find(associatedId);
    if(!associated)   throw new Error("Error: Associado nao encontrado");
    if( !associated.deliveryInfo.driverId ) return;
    return DriverOrderInteractor.removeAssociatedFromDriver(associated.deliveryInfo.driverId, associatedId);
  }

  static async removeAssociatedFromDriver(driverId:string, associatedId:string):Promise<string[]>{
    let associated = await database.associated_db.find(associatedId);
    let driver = await database.driver_db.find(driverId);
    if(!associated)   throw new Error("Error: Associado nao encontrado");
    if(!driver)       throw new Error("Error: Motorista nao encontrado");

    let order_info = findOrderInfo(associatedId, driver);
    if(!order_info)   {
      throw new Error("Error: Associado nao alocado em motorista");
    }
    
    await database.driver_db.updateDriverDeliveryOrder({
      associatedId,
      driverId: driverId,
      weekDay: order_info.weekDay,
      order: order_info.order.filter((id) => id !== associatedId),
      type: "REMOVE"
    });

    return [];
  }

}



function findOrderInfo(associatedId:string, driver:Driver){
  for(let orderInfo of driver.deliveries){
    if(orderInfo.order.includes(associatedId)) return orderInfo;
  }
}