import { compareDeliveryDates, getTodayNumber } from "../../../utils/date_manipulation";
import Associated from "../../entities/Associated";
import DeliveryHistoryInteractor from "../../interactors/delivery/DeliveryHistoryInteractor";
import DriverInteractor from "../../interactors/DriverInteractor"
import PresenterInterface from "../PresenterInterface";
import neighborhoodOnListInformation from "../utils/neighborhood";

interface associatedListInformation {
  id:string,
  avatar:{
    color: string,
    letter: string
  },
  already_received: boolean,
  name: string,
  neighborhood: string,
  deliveryAddress:string,
  delivery_complete: boolean,
  activate_warning: boolean
}

export default class DailyOrderPresenter implements PresenterInterface{
  public storage_path:string
  constructor(private driver_id:string){
    this.storage_path = "daily_order/"+driver_id
  }

  async getData(){
    let associateds = await DriverInteractor.getDriverAssociatedsForWeekDay(this.driver_id, getTodayNumber())
  
    let showItems = associateds.map( async (associated) =>{
      return this.createDataForAssociated(associated);
    })
    return await Promise.all(showItems)
  }

  async createDataForAssociated(associated:Associated):Promise<associatedListInformation>{
    let last_delivery_date:Date|undefined = associated.deliveryInfo.lastDeliveryDate
    let neighborhood = await associated.getNeighborhood();
    let neighborhood_info = neighborhoodOnListInformation(neighborhood);
    let delivery_complete = false;
    let already_received = last_delivery_date ? compareDeliveryDates(new Date(), last_delivery_date) : false

    if(already_received){
      delivery_complete = await DeliveryHistoryInteractor.wasLastDeliveryComplete(associated);
    }
    let activate_warning = !(await DeliveryHistoryInteractor.waslastWeekDeliveryComplete(associated));

    return {
      id: associated.id,
      name: associated.displayName,
      avatar: neighborhood_info.avatar,
      neighborhood: neighborhood_info.name,
      deliveryAddress: associated.deliveryAddress,
      already_received: last_delivery_date ? compareDeliveryDates(new Date(), last_delivery_date) : false,
      delivery_complete: (!already_received || (already_received && delivery_complete)),
      activate_warning
    }
  }
}