import ApplicationEntity from "./ApplicationEntity";

export default class Neighborhood extends ApplicationEntity {

  name: string
  taxPlan: string
  taxIndividual: string
  color: string

  constructor(id: string, data: any) {
    super(id, data);
    Object.assign(this, data);
  }

  iconLetter() {
    return this.name[0]
  }

  static defaultNeighborhood() {
    return new Neighborhood("<inexistent id>", {
      name: "<Bairro Inexistente>",
      taxPlan: "0",
      taxIndividual: "0",
      color: "#f0f0f0",
    })
  }
}