import React from "react";
import DeliveryHistoryIndex from "../../../app/components/templates/DeliveryHistoryIndex";
import {useLocation} from "react-router-dom";


export default function DeliveryHistory() {
    const location = useLocation();


    const { id } = location.state ? location.state : {};

    return <DeliveryHistoryIndex associated_id={id} />

}