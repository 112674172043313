import React, {
    useEffect,
    useState
} from "react";

import {
    CircularProgress,
    TextField,
    Button,
    Container,
    makeStyles
} from "@material-ui/core";
import {
    useHistory
} from "react-router-dom";

import {
    useFirestore,
    useFirebase
} from "react-redux-firebase";

export default function TermIndex() {
    const db = useFirestore();
    const firebase = useFirebase()
    const history = useHistory();
    const classes = useStyles()
    let [fileUrl, setFileUrl] = useState("")

    let [fileLoading, setFileLoading] = useState(false);
    const termUrl = "termo/termo.pdf"
    const termPath = "termo/"
    const storageRf = firebase.storage().ref();
    const termRef = storageRf.child(termUrl)

    useEffect(() => {

        const setFile = async() =>{
            setFileUrl(await termRef.getDownloadURL())
        }
        setFile()
    }, [db, firebase]);

    const imageUpload = async (event) => {
        setFileLoading(true)
        if (!event.target.files)
            return;

        const selectedImage = event.target.files[0];
        await termRef.delete()
        await firebase.uploadFile(termPath, selectedImage, null, {
            name: "termo.pdf"
        });
        history.go(0)


    };

    return (
        <div>
        <Container>
    <iframe
        src={fileUrl}
        style={{
            width: "100%",
            minHeight: window.innerHeight - 200,
            marginTop: "20px"
        }}
    />
    <div className={classes.wrapper}>

        <Button variant="contained" component="label" disabled={fileLoading}>
            {fileLoading && (
                <CircularProgress size={24} classes={classes.buttonProgress} />
            )}
                Enviar Novo Termo
            <input type="file" accept="*" hidden="hidden"  name="image" size="small" onChange={imageUpload}  />

    </Button>


    </div>


        </Container>
    </div>
    );
}

const useStyles = makeStyles((theme) => ({

    wrapper: {
        marginTop: '20px',
        float: "right",
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -9,
        marginLeft: -12,
    },

}));
