import { isSameDay } from "date-fns";
import { normalizeDeliveryDate } from "./date_manipulation";

export function dayIsSuspended(date, associated){
  let suspended_days = getSuspendedDates(associated);
  let normalized_date = normalizeDeliveryDate(date)
  for(let suspended_day of suspended_days){
    if(isSameDay(suspended_day, normalized_date)){
      return true;
    }
  }
  return false;
}

export function getSuspendedDates(associated){
  let suspended_days = associated.suspendedDates || [];
  return suspended_days.map((timestamp)=>{
    try{
      return timestamp.toDate();
    }catch{
      return timestamp
    }
  })
}
