import React from "react";

import {
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";

import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#EBEBEB",
    borderRadius: "5px",
    width: "auto",
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: "10px",
  },
}));

function DragItem({
  primary,
  secondary,
  imageSrc,
  index,
  columnId,
  id,
  onClickItem,
  avatar,
  shouldNotReceive = false,
}) {
  const classes = useStyles();

  if (!onClickItem) onClickItem = () => {};
  if (!id) id = index;

  const handleClick = () => {
    onClickItem(columnId + "-" + id);
  };

  return (
    <Draggable draggableId={columnId + "-" + id + "-" + index} index={index}>
      {(provided) => (
        <ListItem
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.container}
          onClick={handleClick}
          ref={provided.innerRef}
        >
          <ListItemAvatar>
            {avatar ? avatar : <Avatar alt="alt" src={imageSrc} />}
          </ListItemAvatar>
          <ListItemText primary={primary} secondary={secondary} />
          {shouldNotReceive && (
            <ListItemSecondaryAction
              className={classes.marginRight}
              onClick={() => alert("Este associado não receberá entrega hoje")}
            >
              <IconButton edge="end">
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      )}
    </Draggable>
  );
}

export default DragItem;
