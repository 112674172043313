import React, { useEffect, useState } from 'react';

import {
  IconButton,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  CircularProgress, TextField
} from '@material-ui/core';
import {
	useHistory
} from "react-router-dom";

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ImageIcon from '@material-ui/icons/Image';
import {compareStringName} from '../../utils/index'
import { useFirestore } from 'react-redux-firebase';
import Neighborhood from '../../services/db/Neighborhood';


function NeighborhoodIndex() {
  const db = useFirestore();
  const history = useHistory();

  let [loading, setLoading] = useState(true);
  let [allNeighbors, setAllNeighbors] = useState([])
  let [neighbors, setNeighbors] = useState([]);
  let [query, setQuery] = useState("");

  useEffect(()=>{
    const fetchData = async () =>{
      const neighborsFetched  = await Neighborhood.all(db)

      setNeighbors(Object.values(neighborsFetched).sort(compareStringName));
      setAllNeighbors(Object.values(neighborsFetched).sort(compareStringName));
      setLoading(false);
    }
    fetchData();
  },[])

  const handleChange = (evt) =>{
    let newValue = evt.target.value
    setQuery(newValue)
    setNeighbors( allNeighbors.filter( (neighbor)=>neighbor.props.name.toLowerCase().startsWith(newValue.toLowerCase())))
  }

  return <div>
    {loading ? (<div className="loading-container"><CircularProgress /></div>) : (<>
    <TextField
      id="filled-secondary"
      label="Procurar"
      variant="filled"
      fullWidth={true}
      value={query}
      onChange={handleChange}
      color="primary"
    />
    <List>
      {
        neighbors.map( (neighbor, index) =>{
          return (<div key={index}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{backgroundColor:neighbor.props.color||0}}>
                  {neighbor.props.name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={neighbor.props.name} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={()=>{history.push({pathname:'/admin/neighborhood/create', state:{ uid: neighbor.docRef.id, props: neighbor.props}})}}
                >
                  <ArrowForwardIos />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>)
        })
      }
      </List>
    </>)}
  </div>;
}

export default NeighborhoodIndex;
