import React from "react";
import { List } from "@material-ui/core";
import ModalListItem, {ModalListItemProps} from "../atoms/admin/ModalListItem";

type ListItemProps = {
  items: ModalListItemProps[],
  title: string
}

export default function ModalProductList({title, items}:ListItemProps) {
  return (<>
    <h3>{title}</h3>
      <List>
      {
        items.map( (item) =>{
          return <ModalListItem {...item} />
        })
      }
      </List>
    </>
  );
}
