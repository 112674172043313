// Mudar tambem arquivo "firebase-messaging-sw"

const firebaseConfig = {
  apiKey: "AIzaSyDHUFkBmOc_ApF10ARMieGfccA3Mo6VleU",
  authDomain: "idolatre-f0161.firebaseapp.com",
  databaseURL: "https://idolatre-f0161.firebaseio.com",
  projectId: "idolatre-f0161",
  storageBucket: "idolatre-f0161.appspot.com",
  messagingSenderId: "998142379388",
  appId: "1:998142379388:web:bfdb16afcf1f30f62bd0f9",
};

export default firebaseConfig