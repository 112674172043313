import {
	DocumentData, DocumentReference
} from "@firebase/firestore-types";
import { ExtendedFirestoreInstance }from "react-redux-firebase";
import ApplicationModel, {ModelDict, StaticThis} from "./Application";
import DeliveryHelper from "./helpers/associated/DeliveryHelper";
import IndividualPurchasesHelper 	from "./helpers/associated/IndividualPurchasesHelper";
import PlanHelper from "./helpers/associated/PlanHelper";
import SuspendedDays from "./helpers/associated/SuspendedDays";
import Neighborhood from "./Neighborhood";
import Products 					from "./Products";
import Users from "./User";


/****  Define Associated Data *****/
// Helpers Types
export type IndividualPurchase = {
	productId		: string,
	quantity		: number,
	required?		: boolean,
	date?				: string,
}

export type PaymentPlanItem = IndividualPurchase & {
	frequency		: "weekly" | "biweekly",
	productId 		: string
}

export type DeliveryInfo = {
	driverId		: string,
	weekDay		: number,
	order			: string,
	lastDeliveryDate: any | undefined,
	pendingItems	: IndividualPurchase[] | undefined
}

type intolarance = {
	flavorId: string
}

// Data Type
type AssociatedFields = {
  deleted: any;
	paymentPlan	: PaymentPlanItem[],
	individualPurchases: IndividualPurchase[],
	deliveryInfo	: DeliveryInfo,
	address: string,
	deliveryAddress: string,
	intolerances: intolarance[],
	neighborhoodId: string,
	name: string,
	displayName:string,
	suspendedDates: any[],
	acceptedTerm : boolean
}

/****  Define Subcollection Data *****/
export type DeliveryFields = {
	date			: Date,
	observation		: string,
	products		: IndividualPurchase[],
	returnedProducts: IndividualPurchase[]
}

export default class Associated extends ApplicationModel {
	// Associated types
	individualPurchases	: IndividualPurchasesHelper;
	delivery			: DeliveryHelper;
	planHelper		: PlanHelper;
	suspendedDays	: SuspendedDays;

	// Associations
	user					: Users;
	neighborhood	: Neighborhood;

	public static modelName = "associated";
	constructor(
		public db		: ExtendedFirestoreInstance,
		public props	: AssociatedFields,
		public docRef	: DocumentReference < DocumentData >
		) {
		super(db, props, docRef)
		this.individualPurchases 	= new IndividualPurchasesHelper(this.db, this);
		this.delivery 				= new DeliveryHelper(this.db, this);
		this.planHelper				= new PlanHelper(this.db, this);
		this.suspendedDays 		= new SuspendedDays(this.db, this);
	}

	static async all<Associateds extends ApplicationModel>(
		this: StaticThis<Associateds>,
		db: ExtendedFirestoreInstance,
		deleted = false
	): Promise<ModelDict<Associateds>> {
		const modelName = this.modelName;
		let query = db.collection(modelName)
		const data = await query.get();

		let ret: ModelDict<Associateds> = {};

		await Promise.all(
			data.docs.map(async (obj) => {
				ret[obj.id] = new this(db, obj.data(), obj);
			})
		);

		let array = Object.values(ret).filter((value)=> value.props.deleted !== undefined ||
			value.props.deleted === deleted)

		array.forEach((obj)=>{ret[obj.docRef.id] = obj})

		return ret


	}

	static async awaitForAssociations(associateds: Associated[]){
		await Promise.all(associateds.map( async associ =>{ 
			await associ.updateAssociations();
			return associ;
		}))
	}

	static async filterEmptyDelivery(associateds: Associated[], date: Date){
		let returnArray:Associated[] = []
		for(let associated of associateds){
			if(await associated.suspendedDays.dayIsSuspended(new Date())) continue;

			if(associated.props.paymentPlan.length !== 0){
				returnArray.push(associated);
			}
			else if(associated.props.individualPurchases && associated.props.individualPurchases.length !== 0){
				returnArray.push(associated);
			}
		}
		return returnArray;
	}

	async getPendingItems() {

		let ret: {
			id: string,
			name: string,
			returnableName: string,
			required?: boolean,
			quantity: number
		} [] = []
		let products = await Products.all(this.db);

		if (this.props.deliveryInfo.pendingItems)
			this.props.deliveryInfo.pendingItems.forEach((item) => {
				let prod = products[item.productId]

				ret.push({
					id: item.productId,
					required: item.required,
					name: prod.props.name,
					returnableName: prod.props.returnableName,
					quantity: item.quantity
				})
			})

		return ret
	}

	async updateAssociations(){
		this.user 						= await Users.find(this.db, this.docRef.id) as Users;
		this.neighborhood 		= await Neighborhood.find(this.db, this.user.props.neighborhoodId as string) as Neighborhood;
	}

	async getAllBills(){
		const bills =  this.db.collection("associated/"+ this.docRef.id+"/bill")
        const data = await bills.get()
        return await Promise.all(data.docs.map(async(element:any )=>{return element.data()}))

	}
	getDeliveryDay(){
		return (this.props.deliveryInfo.weekDay || 2)
	}
}
