import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
  avatar: {
    height: "15rem",
    width: "15rem",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
}));

export default function AvatarInput({
  name,
  inputRef,
  alt,
  src,
  label,
  required,
}) {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = React.useState();

  const onImageChange = (event) => {
    if (!event.target.files) return;

    console.log(event.target.files[0]);
    const selectedImage = event.target.files[0];

    const imageURL = URL.createObjectURL(selectedImage);

    setImagePreview(imageURL);
  };
  return (
    <div className={classes.avatarWrapper}>
      <Avatar
        variant="circular"
        alt={alt}
        src={imagePreview || src}
        className={classes.avatar}
      />
      <div className={classes.imageInputWrapper}>
        <label htmlFor="image">{label}</label>
        <input
          accept="image/*"
          className={classes.imageInput}
          id="image"
          name={name}
          type="file"
          onChange={onImageChange}
          ref={inputRef}
          required={required}
        />
      </div>
    </div>
  );
}
