import React from "react";
import { Control, Controller, DeepMap, FieldError, useFieldArray } from "react-hook-form";
import { Button, IconButton, ListItem, TextField } from "@material-ui/core";
import { Avatar, Divider, ListItemAvatar, ListItemSecondaryAction, ListItemText, List } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FormSelect from "../../../atoms/FormSelect";

type produto_fields = {
  name: string,
  id: string,
  image_url: string
}

type form_params  = { 
  control: Control<Record<string, any>>, 
  errors: DeepMap<Record<string, any>, FieldError>,
  register: any,
  watch: any,
  setValue: any,
  trigger:any,
  getValues: any,
  name: string,
  frequency_options: any[],
  all_products: produto_fields[],
}


const TranslateFrequency:any = {
  "weekly": "Semanal",
  "biweekly": "Quinzenal"
}

const AssociatedProductsForm = ({control, all_products, frequency_options, name, getValues, watch, setValue, trigger, errors, register}:form_params) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const paymentPlan = watch(name, fields);

  const productOptions = all_products.map( (product) => {
    return {
      label: product.name, value: product.id
    }
  })

  const shouldDisplayPlusButton = () => {
    return !paymentPlan.some((p:any) => p.confirmed === "false" || p.confirmed === false) || paymentPlan.length === 0;
  }
  return (
    <div>
      <List disablePadding>
        {paymentPlan.map((item:any, idx:any) => {
          if(!(item.confirmed === true || item.confirmed === "true")) return <></>
          let prod =  all_products.reduce((acc, prod)=> prod.id === item.productId ? prod : acc, {} as any)
          return (
            <ConfirmedProduct 
              id={"confirmedprod/"+idx} 
              primary={item.quantity + " - " + prod.name}
              secondary={TranslateFrequency[item.frequency]}
              image_url={prod.image_url}
              index={idx}
              remove={remove}
            />
          )
        })}
      </List>

      {fields.map((item, index)=> {
        const confirmed = getValues(`${name}[${index}].confirmed`);
        return (
          <SingleProductFormField 
            key={`${name}[${index}].productId`}
            show={!(confirmed === true || confirmed === "true")}
            index={index}
            form_name={name}
            control={control}
            errors={errors}  
            remove={remove}
            setValue={setValue}
            register={register}
            trigger={trigger}
            item={item}
            getValues={getValues}
            frequency_options={frequency_options}
            productOptions={productOptions}
            hiddenOptions={[]}
          />
        )
      })}
      <div></div>
      {shouldDisplayPlusButton() && (
        <IconButton
          size={"small"}
          onClick={() => {
              if(productOptions.length !== 0)
                  append({
                      quantity: 1,
                      frequency: "weekly",
                      confirmed: false,
                      productId: productOptions[0].value || ""
                  });
              else
                  alert('Não há nenhum produto cadastrado')
          }}
        >
          <AddCircleRoundedIcon style={{ fontSize: 30 }} />
        </IconButton>
      )}
    </div>
  )
}

export default AssociatedProductsForm

type SingleProductFormFieldType = {
  show: boolean,
  index: number,
  control: any,
  errors: any,
  remove: any,
  setValue: any,
  item: any,
  form_name: string,
  frequency_options: any[],
  hiddenOptions: any[],
  getValues: any,
  productOptions: any,
  register: any,
  key: any,
  trigger: any
}

const SingleProductFormField = (props:SingleProductFormFieldType) => {

  const isValidItem = () => {
    let form = props.getValues();
    let this_item = form[props.form_name][props.index];
    if(this_item.productId === undefined){
      return false;
    }
    if(props.frequency_options.length > 0){
      if(this_item.frequency === undefined){
        return false;
      }
    }
    return true;
  }
  return (
    <div key={props.key} style={{display: !props.show ? "none" : "inline"}}>
      <FormSelect
        options={props.productOptions}
        label="Produto"
        name={`${props.form_name}[${props.index}].productId`}
        control={props.control}
        errors={props.errors}
        hiddenOptions={props.hiddenOptions}
        // defaultValue={''}
      />

      <Controller
        style={{marginTop: 0}}
        as={TextField}
        control={props.control}
        variant="outlined"
        id="quantity"
        margin="normal"
        label="Quantidade"
        name={`${props.form_name}[${props.index}].quantity`}
        required={false}
        fullWidth
        type="number"
        defaultValue={`${props.item.quantity}`}
      />

      {props.frequency_options.length > 0 && (
        <FormSelect
          options={props.frequency_options}
          label="Frequência"
          name={`${props.form_name}[${props.index}].frequency`}
          control={props.control}
          errors={props.errors}
          // defaultValue={""}
        />
      )}
      
      <Controller
        render={()=> (<div></div>)}
        control={props.control}
        id="confirmed"
        name={`${props.form_name}[${props.index}].confirmed`}
        required={false}
        fullWidth
        defaultValue={`${props.item.confirmed}`}
      />

      <Button
        variant="contained"
        color="secondary"
        style={{marginRight:"10px"}}
        onClick={() => props.remove(props.index)}
      >
        Remover
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          if(isValidItem()){
            props.setValue(`${props.form_name}[${props.index}].confirmed`, true)
            props.trigger(props.form_name);
          }
          else{
            alert("Corrija os campos do produto!")
          }
        }}
      >
        Adicionar produto
      </Button>
    </div>
  )
}


type confirmed_prod_type = {
  id:string,
  image_url: string,
  primary: string,
  secondary: string,
  index: number,
  remove: any
}
const ConfirmedProduct = ({id, image_url, primary, secondary, index, remove}:confirmed_prod_type) => {
  return (
    <div id={id}>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            src={image_url}
            />
        </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
      <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => {
            remove(index)
          }}
        >
          <DeleteIcon  />
        </IconButton>

      </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}