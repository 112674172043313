import React from "react";
import {
	Switch,
	Route,
	useRouteMatch,
  Redirect
} from "react-router-dom";

import driverRoutes from '../routes/driver'

import AppBottomNavigation from './AppBottomNavigation'

import { makeStyles, ThemeProvider } from "@material-ui/core";
import { driverTheme } from "../utils/themes";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));


function DriverLayout() {
  let { path } = useRouteMatch();
  let classes = useStyles();
  return (
    <ThemeProvider theme={driverTheme}>
      <div>
        <Switch>
          {
            driverRoutes.map((route, index)=>{
              let new_path = `${path}${route.path}`;
              if(route.param) new_path += route.param;
              return (
              <Route 
                key={index}
                exact 
                path={new_path} 
              >
              <div className={classes.content}>
                <div className={classes.drawerHeader} />
                <route.component />
              </div>
              <div className={classes.drawerHeader} />
                <AppBottomNavigation route={route} model="driver" routesList={driverRoutes}/>      
              </Route>
            )})
          }
          <Route path={path} >
            <Redirect to={"/driver/daily_shipping"} />
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default DriverLayout;
