import { isSameDay, isSameWeek } from "date-fns";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { normalizeDate } from "../../../../utils";
import Associated, { IndividualPurchase } from "../../Associated";
import Products from "../../Products";

export default class IndividualPurchasesHelper{
	constructor(public db:ExtendedFirestoreInstance, public associated:Associated){}

	async getAll(){
		if(!this.associated.props.individualPurchases) return [];
		let products = await Products.all(this.db);	
		return this.associated.props.individualPurchases.map( (indiv) =>{
			return {
				product: products[indiv.productId],
				productId: indiv.productId,
				quantity: indiv.quantity,
				total: indiv.quantity * products[indiv.productId].props.price,
				date: indiv.date
			}
		})
	}

	async removePurchase(productRef:string, date: string | undefined){
		if(!this.associated.props.individualPurchases) return;
		let updatedList = this.associated.props.individualPurchases.filter( (val) => {
			return !(val.productId === productRef && this.compareDates(val.date, date));
		})
		console.log(updatedList);
		this.update(updatedList);
	}

	async updatePurchase(productRef:string, quantity:number, date: string|undefined){
		let updatedList = this.associated.props.individualPurchases || [];
		
		// Check if product is already purchased and update it
		let alreadyInList = false
		updatedList.forEach( (p, index, list) =>{
			if(p.productId === productRef){
				if(this.compareDates(p.date, date)){
					list[index].quantity 	= quantity;
					alreadyInList	 		= true;
				}
			}
		})

		// If not purchases, add to list
		let newProduct:any = {
			productId: productRef,
			quantity: quantity
		}
		if(date !== undefined) {
			newProduct["date"] = normalizeDate(new Date(date)).toISOString().split('T')[0];
		}

		if( ! alreadyInList ){
			updatedList.push(newProduct)
		}
		return await this.update(updatedList);
	}

	public alreadyPurchased(productRef:string, date:string|undefined):IndividualPurchase | undefined{
		if(!this.associated.props.individualPurchases) return;
		for(let p of this.associated.props.individualPurchases){
			if(p.productId === productRef){
				if(this.compareDates(p.date, date)){
					console.log(p)
					return p;
				}
			}
		}
	}

	public compareDates(d1: string|undefined|Date, d2: string|undefined|Date){
		return isSameWeek(
			this.individualPurchaseDate(d1), 
			this.individualPurchaseDate(d2)
		);
	}

	public individualPurchaseDate(date: string|undefined|Date):Date {
		let val = date === undefined ? 0 : date;
		let d = new Date(val);
		return d;
	}

	private async update(updatedList:IndividualPurchase[]){
		await Associated.update(this.db, this.associated.docRef.id, {
			individualPurchases: updatedList
		})
		this.associated.props.individualPurchases = updatedList
	}
}