import ApplicationEntity from "./ApplicationEntity"

type Delivery = {
    weekDay : number,
    order : Array<string>
}

export default class Driver extends ApplicationEntity{
  image: string
  name: string
  cellphone: string
  email: string
  displayName:string
  deliveries : Delivery[]

  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }

}
