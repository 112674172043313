import PresenterInterface from "../../PresenterInterface";
import database from "../../../database/DataBaseConfig";
import Flavor from "../../../entities/Flavor";


export default class IndexFlavorPresenter implements PresenterInterface {
    storage_path: string

    constructor() {
        this.storage_path = "/admin/flavor/index";
    }

    async getData(): Promise<Flavor[]> {
        let flavors = await database.flavor_db.all();
        let flavorsList:{name:string, deleted:boolean|undefined, id:string}[] = []
        flavors.forEach((flavor)=>{
            flavorsList.push({...flavor})
        })

        return  flavorsList;
    }


}