import React,{ useRef,useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import "firebase/database"
import SendIcon from '@material-ui/icons/Send';
import {
    Paper,
    Grid,
    Divider,
    TextField,
    List,
    Fab

} from "@material-ui/core"


import ChatMessage from "./ChatMessage"

export default function Chat({user1, user2, fromId, toId}) {
    const classes = useStyles();
    const firebase = useFirebase()
    const db = useFirestore()

    const realtime = firebase.database()
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState("")


    const [user1Info, setUser1Info] = useState({})
    const [user2Info, setUser2Info] = useState({})

    const chatId = user1 + ":" + user2
    let textInput
    let textInputRef = useRef()
    let divider = useRef()

    useEffect(()=>{

      const getChat = async ()=>{
          await realtime.ref("chats/"+chatId).on("value", snapshot => {
              let chats = [];
              snapshot.forEach((snap) => {
                  chats.push(snap.val());
              });


              setMessages(chats)
              if(divider){
                  try{
                      divider.current.scrollIntoView()
                  }catch{}
              }

              updateChat(chats)


          });


          setUser1Info(await db.collection("users").doc(user1).get())
          setUser2Info(await db.collection("users").doc(user2).get())

      }
      getChat();


  },[realtime, chatId])



        const updateChat = async (messages)=>{

            if(messages.length === 0)
                return

            messages.forEach((message) =>{
                if (message.read === undefined || message.read === false && message.user1 === toId){
                    message.read = true
                }
            })


            await realtime.ref("chats/"+chatId).set(messages)


        }



    const handleSubmit = async ()=>{
        textInput.value = ""

        try {

            await realtime.ref("chats/"+chatId).push({
                content: newMessage,
                timestamp: Date.now(),
                from: fromId,
                to: toId,
                user1Name: user1Info.data().displayName,
                user2Name: user2Info.data().displayName,
                read: false
            });

        } catch (error) {
            console.log(error);
        }
        divider.current.scrollIntoView()
        setNewMessage("")
    }

    const textAlignment = (message) =>{

        if (message.from === fromId) {
            return "right"
        }else{
            return "left"
        }
    }

    const keyDownHandler = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const updateMessage = (event) =>{
        setNewMessage(event.target.value)
    }

    return (<div>

        <Grid container component={Paper} className={classes.chatSection}>

            <Grid item xs={12}>
                <List className={classes.chatSection}>
                    {
                        messages.map((message, i)=>{
                            return(<ChatMessage time={message.timestamp} alignment={textAlignment(message)} text={message.content} index={i} />)
                        })

                    }


                </List>
                <Divider  ref={divider} />
                <Grid container className={classes.messageArea} >

                    <Grid item xs={10}>
                        <TextField id="outlined-basic-email" onKeyDown={keyDownHandler} label="Mensagem" onChange={updateMessage} inputRef={el => textInput = el} fullWidth/>
                    </Grid>
                    <Grid item xs={2} align="right">
                        <Fab type="submit" size="small"  color="primary" onClick={handleSubmit} aria-label="add"><SendIcon/></Fab>

                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    </div>);
}

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    chatSection: {
        width: '100%',
        minHeight: "83vh",
        marginBottom: "5vh"
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        overflowY: 'auto',
        padding: 5,
        height: "10vh",
        bottom: 0,
        position: "fixed",
        backgroundColor: "white",
        boxShadow: "0px -11px 14px -3px rgba(0,0,0,0.10)"
    },

});
