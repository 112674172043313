import database from "../../database/DataBaseConfig";
import Driver from "../../entities/Driver";

export default async function resolveOrderInconsistency(driver_id: string):Promise<boolean>{
  let driver = await database.driver_db.find(driver_id);
  if(!driver) return false;
  let errorFound = false;
  for(let day=0;day<7;day+=1){
    if(await resolveWeekDay(driver, day)){
      errorFound = true;
    }
  }
  return errorFound;
}

async function resolveWeekDay(driver: Driver, weekDay:number):Promise<boolean>{
  let errorFound = false;

  let associateds = await database.associated_db.getWeekDayAssociatedsForDriver(weekDay, driver.id);
  let driver_delivery = driver.deliveries.find((delivery_info) => delivery_info.weekDay === weekDay);
  let associated_ids_on_driver = driver_delivery ? driver_delivery.order : [];

  // verify if all associateds in driver order are correctly alocated
  for(let id of associated_ids_on_driver){
    let asssociated_found = associateds.find( associated => associated.id === id);
    if(!asssociated_found){ resolveErrors(id); errorFound = true;}
  }

  // verify if all associateds from query are on the list
  for(let associated of associateds){
    let id_found = associated_ids_on_driver.find( id => id === associated.id);
    if(!id_found){resolveErrors(associated.id); errorFound = true;}
  }

  return errorFound;
}


async function resolveErrors(associated_id:string) {
  let drivers = await database.driver_db.all();
  
  for(let driver of drivers){
    let should_update = false;
    for(let delivery_index  in driver.deliveries){
      let delivery = driver.deliveries[delivery_index];
      let associated_found = delivery.order.find( id => id === associated_id);
      if(associated_found){
        should_update = true;
        driver.deliveries[delivery_index].order = driver.deliveries[delivery_index].order.filter( id => id !== associated_id);
      }
    }

    if(should_update){
      await database.driver_db.update(driver);
    }
  }
  await database.associated_db.resetDriverInfo(associated_id);
}