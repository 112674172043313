import React from "react";
import { useSelector } from "react-redux";

import AssociatedList from "../../components/driver/daily_order/AssociatedsList";



export default function DailyOrder() {
  const driverAuth = useSelector((state) => state.firebase.auth);
  const todayDate = new Date().getDay();
  
  return ( <AssociatedList driverId={driverAuth.uid} todayDate={todayDate}/> );
}
