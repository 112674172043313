import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";

import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9, // Center spinner
    marginLeft: -12,
  },
}));

export default function RecoverPasswordForm(){
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, ] = React.useState("");
  const firebase = useFirebase();
  const classes = useStyles();

  const onSubmit = (e:any) => {
    e.preventDefault();
    setLoading(true);
    
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        alert("Email de recuperação enviado")
        setLoading(false);
      })
      .catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        alert("Houve o seguinte erro: "+ errorMessage)
        setLoading(false);
      });
  };

  return (
    <form className={classes.form} noValidate>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        error={!!error}
        helperText={error}
      />
      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.submit}
          disabled={loading}
        >
          Enviar
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </div>
    </form>
  )
}