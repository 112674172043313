import { DocumentData, DocumentReference } from "@firebase/firestore-types"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import ApplicationModel, {ModelDict, StaticThis} from "./Application"
import {db} from "../Firebase";

type FlavorFields = {
    name: string
}

export default class Flavor extends ApplicationModel {

    public static modelName = "flavor";

    constructor(
        public db: ExtendedFirestoreInstance,
        public props: FlavorFields,
        public docRef: DocumentReference<DocumentData>
    ) {
        super(db, props, docRef)
    }

    static async all<Flavor extends ApplicationModel>(
        this: StaticThis<Flavor>,
        db: ExtendedFirestoreInstance,
        deleted = false
    ): Promise<ModelDict<Flavor>> {
        const modelName = this.modelName;
        let query = db.collection(modelName)
        const data = await query.get();

        let ret: ModelDict<Flavor> = {};

        await Promise.all(
            data.docs.map(async (obj) => {
                ret[obj.id] = new this(db, obj.data(), obj);
            })
        );

        let new_ret: ModelDict<Flavor> = {}

        let array = Object.values(ret).filter((value)=> (value.props.deleted === undefined)||
            value.props.deleted === deleted)

        array.forEach((obj)=>{new_ret[obj.docRef.id] = obj})



        return new_ret


    }


}
