import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon   from '@material-ui/icons/MoreVert';


function AdminTopBarMenu({options}) {
  const history = useHistory();
  const reduxMenuOptions = useSelector((state) => state.frontend.menuOptions);
  // Menu (tres pontinhos)
  const [anchorEl, setAnchorEl] = useState(null);
  const [allOptions, setAllOptions] = useState([])
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    if(!anchorEl)
      setAnchorEl(event.currentTarget);
    else
      setAnchorEl(null);
  };

  React.useEffect(()=>{
    setAllOptions( reduxMenuOptions ? options.concat(reduxMenuOptions) : options)
  }, [reduxMenuOptions, options])
  const closeMenu = () => setAnchorEl(null);

  const handleOptionClick = (option) => {
    return () => {
      if(option.state){
        history.push({pathname: option.redirectPath, state: option.state})
      }
      else{
        history.push(option.redirectPath);
      }
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <div  onClick={handleMenuClick}>
        <MoreVertIcon />
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {allOptions.map((option, index) => (
          <MenuItem key={index} onClick={handleOptionClick(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default AdminTopBarMenu;
