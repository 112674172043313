import ApplicationEntity from "./ApplicationEntity"

export default class Product extends ApplicationEntity{
  flavorId: string;
  name: string;
  returnableName: string;
  price: number;
  imageUrl: string;
  individual: boolean;
  returnable: "Sim"|"Não"|"Opcional" | undefined 

  deleted: boolean | undefined
  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }

}
