import usePresenter from "../../redux/usePresenter";

import React from "react";

import DeliveryHistoryList from "../molecules/DeliveryHistoryList";
import ShowDeliveryHistoryListPresenter from "../../presenters/shared/delivery/ShowDeliveryListPresenter";


type DeliveryHistoryProps ={
    associated_id : string
}

export default function DeliveryHistoryIndex(props: DeliveryHistoryProps ) {
    const show_data:any = usePresenter(new ShowDeliveryHistoryListPresenter(props.associated_id));

    if(!show_data) return <></>;

    return <><DeliveryHistoryList list={show_data}/></> ;
}