export default {
  theme:{
    mainColor: "#838949",
    driver: {
      topBarColor:"#838949",
      themeColor:"#838949"
    },
    admin: {
      topBarColor:"#838949",
      themeColor:"#838949"
    },
    associated:{
      topBarColor:"#838949",
      themeColor:"#838949"
    }
  }
}