import { Card, CardActionArea, CardMedia, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
  avatar: {
    height: "15rem",
    width: "15rem",
  },
  imageInputWrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  imageInput: {
    visibility: "hidden",
    color: theme.palette.primary,
  },
  media: {
    marginTop: 10,
    height: 200,
  },
}));

export default function AlertImageInput({
  name,
  inputRef,
  alt,
  src,
  label,
  required,
}) {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = React.useState();
  src = src || "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.png";


  const onImageChange = (event) => {
    if (!event.target.files) return;

    console.log(event.target.files[0]);
    const selectedImage = event.target.files[0];

    const imageURL = URL.createObjectURL(selectedImage);

    setImagePreview(imageURL);
  };
  return (
    <div >
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={imagePreview || src}
            alt={"tese"}
          />
        </CardActionArea>
      </Card>
      <div className={classes.imageInputWrapper}>
        <label htmlFor="image">{label}</label>
        <input
          accept="image/*"
          className={classes.imageInput}
          id="image"
          name={name}
          type="file"
          onChange={onImageChange}
          ref={inputRef}
          required={required}
        />
      </div>
    </div>
  );
}
