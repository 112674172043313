import React, {useEffect, useState} from 'react'
import { useParams }    from 'react-router-dom';
import { useFirestore }               from 'react-redux-firebase';
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import { useLocation} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  hText:{
    margin: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  root:{

    width: "90%", 
    margin: "20px auto"
  }
}));

function AvisoView() {
  const db        = useFirestore();
  const params      = useParams();
  const classes     = useStyles();
  const location = useLocation()

  const {aviso} = location.state
  let {aviso_id} = params
  const [loading, setLoading]   = useState(true)

  useEffect(()=>{
    const fetchData = async ()=>{

      setLoading(false);
    }
    fetchData();
  }, [db, aviso_id]);


  return <div >
    {loading ? (<div className="loading-container"><CircularProgress /></div>) : (
    <div className={classes.container}>
      <Divider/>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={aviso.imageUrl}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {aviso.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {aviso.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>)}
  </div>;
}

export default AvisoView;