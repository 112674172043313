import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import {ReactComponent as IdolatreIcon} from '../config/assets/topbar-logo.svg';
import ChatIcon from "@material-ui/icons/Chat";

import {
	useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import User from "../services/db/User"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LogOutButton from '../components/LogOutButton'
import Constants from "../constants/roles"
import { useFirestore } from 'react-redux-firebase';

import configs from "../config/colors"

function getCorrectRouteNumber(route, routesList){
  return routesList.reduce((acc, routeTitle, index)=>{
    if(routeTitle.path === route.path)
      return index
    else
      return acc
  }, 0)
}


const useStyles = makeStyles((theme) => ({
  toolbarText:{
    width: '100%',
    verticalAlign: "middle",
    display: "flex",
    justifyContent:"center",
    alignItems: "center"
  },
  bottomNav:{
    width:"100%",
    position:"fixed",
    bottom: 0,
    marginBotton: "300px"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: "absolute"
  },
  chatIconDiv :{
	  marginRight : theme.spacing(1)
  }
}));


export default function AppBottomNavigation({route, model, routesList}) {

  const history = useHistory();
  const classes = useStyles();
  const db = useFirestore()

  const auth      = useSelector((state) => state.firebase.auth);
	
	const returnUserPath = async() => {

		const user  = await User.find(db, auth.uid)
		if (user.props.role === Constants.ASSOCIATED) {
			return Constants.ASSOCIATED.toLowerCase()
		}else {
			return Constants.DRIVER.toLowerCase()
		}
	}

  const [value, setValue] = React.useState( getCorrectRouteNumber(route, routesList) );
  let theme = model === "driver" ? configs.theme.driver : configs.theme.associated
  return (
    <AppBar>
      <Toolbar style={{ background: theme.topBarColor }}>
        {route.backButtom ? (
        <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={()=>{history.goBack()}}>
          <ArrowBackIosIcon />
        </IconButton>
        ): <></>}
        <Typography className={classes.toolbarText}>
          <IdolatreIcon/>
          <span style={{marginLeft:"10px"}}>
            {route.title}
          </span>
        </Typography>

		<div onClick={async ()=>{history.push("/" + await returnUserPath() + "/chat")}} className={classes.chatIconDiv}>
			<ChatIcon/>
		</div>

		<LogOutButton/>
      </Toolbar>
      {route.redirectNavigation ? <></> : (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            history.push(`/${model}${routesList[newValue].path}`)
            setValue(newValue);
          }}
          showLabels
          className={classes.bottomNav}
        >
        {
          routesList.reduce( (acc,route, index) =>{
            if(route.redirectNavigation) return acc
            acc.push(
              <BottomNavigationAction key={index} label={route.name} icon={<route.icon />} />
            )
            return acc;
          }, [])
        }
        </BottomNavigation>
      )}
    </AppBar>
  );
}
