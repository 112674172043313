import React, { useEffect } from "react";
import { getConfig } from "../../redux/backendStorage";

type ConfigWrapperProps = {
  name: string,
  expected_to_show: string,
  children: React.ReactNode
}

export default function ConfigWrapper({name, expected_to_show, children}:ConfigWrapperProps) {
  const [loading, setLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);


  useEffect(()=> {
    (async function fetch() {
      const config_val = await getConfig(name);

      setShow(config_val.asString() === expected_to_show);
      setLoading(false);
    })()
  }, [])

  
  if(loading) return (<></>)
  if(!show) return (<></>)

  return (
    <div>
      {children}
    </div>
  );
}
