import PresenterInterface from "../../PresenterInterface";
import { ShowReceiptInfoProps } from "../../../components/organisms/receipt/ShowReceiptInfo";
import {format } from 'date-fns'
import database from "../../../database/DataBaseConfig";
import { getInfoForShowReceipt } from "../../utils/receipt";
import Receipt from "../../../entities/Receipt";
import Associated_Delivery from "../../../entities/Associated_Delivery";
import {DeliveryHistoryListItemType} from "../../../components/atoms/shared/associated/DeliveryHistoryListItem";

export default class ShowDeliveryHistoryListPresenter implements PresenterInterface{
    storage_path:string
    constructor(public associated_id:string){
        this.storage_path = "/show_deliveries/"+this.associated_id;
    }

    async getData():Promise<DeliveryHistoryListItemType[]>{
        let  deliveries : Associated_Delivery[] = await database.associated_delivery_db.findDeliveriesByPagination(this.associated_id);
        let data : DeliveryHistoryListItemType[] = []

        deliveries.forEach((delivery)=>{

            data.push({
                date: this.formatDate(delivery.date),
                total_value:  delivery.totalPrice != undefined ? delivery.totalPrice.toString() : "0",
                number_items: delivery.products.reduce( (lastValue, current)=> lastValue + current.quantity, 0).toString()
            })
        })
        return data
    }

    private formatDate(date: Date):string{
        return format(date, 'dd/MM/yyyy')
    }



}