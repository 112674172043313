import { subDays, subMonths } from "date-fns";
import { compareDeliveryDates } from "../../../../utils/date_manipulation";
import database from "../../../database/DataBaseConfig";
import DeliveryDaysInteractor from "../../../interactors/delivery/DeliveryDaysInteractor";
import PresenterInterface from "../../PresenterInterface";

type data_format = {
  suspended_dates:Date[],
  should_receive_date: (date:Date) => boolean,
  delivery_day_number: number | null
}

export default class CalendarioMuralPresenter implements PresenterInterface{
  storage_path:string
  constructor(public associated_id:string){
    this.storage_path = "/calendandar/"+this.associated_id;
  }

  async getData():Promise<data_format>{
    let associated = await database.associated_db.find(this.associated_id);
    if(!associated) return this.defaultData();
    let _associated = associated
    let delivery_dates = await database.associated_delivery_db.deliveriesOnTimeRange(this.associated_id, subMonths(new Date(), 3), subDays(new Date(), 1))

    return {
      suspended_dates: associated.suspendedDates || [],
      delivery_day_number: associated.deliveryInfo.weekDay,
      should_receive_date: (date:Date) => {
        if (date < subDays(new Date(), 1)){
          for(let d of delivery_dates){if(compareDeliveryDates(d, new Date(date)))return true}
          return false
        }
        return DeliveryDaysInteractor.shouldAssociatedReceiveOnWeek(date, _associated)
      },
    }
  }


  private defaultData():data_format{
    return {
      suspended_dates: [],
      delivery_day_number: null,
      should_receive_date: () => false
    }
  }
}