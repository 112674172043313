import React, { useEffect, useState } from "react";

import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import { compareStringDisplayName } from "../../utils/index";
import { useFirestore } from "react-redux-firebase";
import Associated from "../../services/db/Associated";

function AssociatedIndex() {
  const db = useFirestore();
  const history = useHistory();
  let [loading, setLoading] = useState(true);
  let [allAssociateds, setAllAssociateds] = useState([]);
  let [associateds, setAssociateds] = useState([]);
  let [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const associateds = await Associated.all(db, true);

      setAssociateds(Object.values(associateds).sort(compareStringDisplayName));
      setAllAssociateds(
        Object.values(associateds).sort(compareStringDisplayName)
      );
      setLoading(false);
    };
    fetchData();
  }, [db]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    window.scrollTo(0, parseInt(scrollPosition));
  });

  const handleChange = (evt) => {
    let newValue = evt.target.value;
    setQuery(newValue);
    setAssociateds(
      allAssociateds.filter((associated) =>
        associated.props.displayName
          .toLowerCase()
          .includes(newValue.toLowerCase())
      )
    );
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <TextField
            id="filled-secondary"
            label="Procurar"
            variant="filled"
            fullWidth={true}
            value={query}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {
                    associateds.filter((associated) => {
                      return !(
                        associated.props.deleted !== undefined &&
                        associated.props.deleted
                      );
                    }).length
                  }
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            color="primary"
          />
          <List>
            {associateds.map((associated, index) => {
              return (
                <div key={index}>
                  <ListItem
                    disabled={
                      associated.props.deleted !== undefined &&
                      associated.props.deleted
                    }
                  >
                    <ListItemText primary={associated.props.displayName} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          sessionStorage.setItem(
                            "scrollPosition",
                            window.pageYOffset
                          );
                          history.push({
                            pathname: "/admin/associated/edit",
                            state: {
                              id: associated.docRef.id,
                            },
                          });
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
}

export default AssociatedIndex;
