import React, {useState, useEffect} from 'react';
import { useHistory, useLocation, useParams }    from 'react-router-dom';
import { useSelector }              from 'react-redux';
import { 
  TextField,
  Button,
  CircularProgress }                from '@material-ui/core';
import { makeStyles }               from '@material-ui/core/styles';
import Products                     from '../../services/db/Products';
import { useFirestore }             from 'react-redux-firebase';
import ProductInfo                  from '../../components/ProductInfo';
import { deleteStorage } from '../../app/redux/backendStorage';

const useStyles = makeStyles((theme) => ({
  loading: {
    display         : "flex",
    marginTop       :  "100px",
    justifyContent  : "center"
  },
  hText:  {
    paddingTop      : theme.spacing(2),
    margin          : theme.spacing(1),
  },
  rodapeComentary:{fontSize: "12px", color: "rgb(111 111 111)", margin:"2px auto"},
}));

function printFloat(f){
  return parseFloat(f).toFixed(2).replace(".", ",")
}


export default function BuyIndividualProduct() {
  const db          = useFirestore();
  const params      = useParams();
  const history     = useHistory();
  const classes     = useStyles();
  const location    = useLocation();

  const associated    = useSelector((state) => state.backend.associated);

  let [loading, setLoading]   = useState(true);
  let [product, setProduct]   = useState({});
  let [quantity, setQuantity] = useState(0);
  let [alreadyPurchased, 
    setAlreadyPurchased]      = useState(false);
  let [agendarEntrega, setAgendarEntrega]        = useState(false);
  let state = location.state ? location.state : {};
  let [dataEntrega, setDataEntrega] = useState(state.date ? state.date : undefined);
  let {id} = params


  useEffect( ()=>{
    const fetchData = async () =>{
      const productFetched  = await Products.find(db, id);

      // Se o produto ja tiver sido comprado
      const purchaseInfo = associated.individualPurchases.alreadyPurchased(id, dataEntrega);
      if(purchaseInfo){
        setAlreadyPurchased(true);
        setQuantity(purchaseInfo.quantity)
        if(purchaseInfo.date) setAgendarEntrega(true);
      }

      setProduct(productFetched);
      setLoading(false);
    }

    fetchData();
  }, [db, id, associated]);

  useEffect(() => {
    const purchaseInfo = associated.individualPurchases.alreadyPurchased(id, dataEntrega);
    if(purchaseInfo){
      setAlreadyPurchased(true)
      setQuantity(purchaseInfo.quantity)
      if(purchaseInfo.date) setAgendarEntrega(true);
    }
    else {
      setAlreadyPurchased(false)
    }
  }, [dataEntrega])

  async function handleQuero(){
    if(quantity <= 0){alert("Por favor selecionar mais de 1 item");return}
    await associated.individualPurchases.updatePurchase(product.docRef.id, quantity, dataEntrega);
    deleteStorage("associated/"+associated.docRef.id)
    history.push("/associated/avulsos")
  }

  return loading ? <div className={classes.loading}><CircularProgress /></div> : ( 
    <div className={classes.hText}>
      <ProductInfo product={product} />

      <TextField 
        id="standard-basic" 
        inputProps={{ min: 0 }} 
        label="Quantidade" 
        type="number" 
        value={quantity}
        onChange={(ev)=>{setQuantity(ev.target.value)}}
        style={{marginTop: "10px"}}
        fullWidth={true} 
      />

      {agendarEntrega && (
        <TextField 
          InputLabelProps={{ shrink: true }}
          id="standard-basic" 
          inputProps={{ min: 0 }} 
          label="Data de entrega" 
          type="date" 
          value={dataEntrega}
          onChange={(ev)=>{setDataEntrega(ev.target.value)}}
          style={{marginTop: "10px"}}
          fullWidth={true} 
        />
      )}
      {!agendarEntrega && (
        <Button variant="contained" color="primary" size="big" style={{fontSize: "12px", marginTop: "10px"}} onClick={() => setAgendarEntrega(true)} >
          {"Agendar Entrega"}
        </Button>
      )}

      <div style={{display:"flex", flexDirection:"column",margin:"10px 20px", paddingTop:"50px", justifyContent:"center"}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div>Total:</div>
          <div>R$ {printFloat(quantity * product.props.price)}</div>
          <Button variant="contained" color="primary" size="medium" style={{fontSize: "12px"}} onClick={handleQuero}>
            {alreadyPurchased ? "Atualizar entrega" : "Quero"}
          </Button>
        </div>
        <div className={classes.rodapeComentary}>
          { !agendarEntrega ? "Itens individuais serão entregues na próxima entrega" : "Itens agendados serão enviados juntos a entrega da semana" }
        </div>
      </div>
    </div>
  );
}
