import React, { useEffect, useState } from 'react';
import Chat from "../../components/Chat/Chat"
import { isLoaded, useFirebase } from "react-redux-firebase";


export default function AssociatedChat() {
    const firebase = useFirebase()
    const user = firebase.auth().currentUser;

    const admin_id = "JLQ1S20FIKZRfJOAxOJkUsSUHCx2"

    return (<Chat user1={admin_id} user2={user.uid} fromId={user.uid} toId={admin_id}/>)
}
