import ApplicationEntity from "./ApplicationEntity"


export default class SystemAlert extends ApplicationEntity{
  name: string
  description: string
  imageUrl: string
  systemDescription: string
  type: "BIRTHDAY" | "DELIVERY" | "LATEPAYMENT"

  constructor(id:string, data:any){
    super(id, data);
    Object.assign(this, data);
  }
}
