import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core';

import {
	Switch,
	Route,
	useRouteMatch,
  Redirect
} from "react-router-dom";

import routes from '../routes/admin'
import AdminDrawer from './AdminDrawer';
import { adminTheme } from '../utils/themes';


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));


const drawerRoutes = routes.filter((route) => route.drawer && route.drawer.show)



function AdminLayout() {
  let classes = useStyles();
  let { path } = useRouteMatch();


  return (    
    <ThemeProvider theme={adminTheme}>
      <Switch>
        {
          routes.map((route, index)=>{
            let new_path = `${path}${route.path}`;
            if(route.param) new_path += route.param;
            return (
            <Route 
              key={index}
              exact 
              path={new_path} 
            > 
              <AdminDrawer routes={drawerRoutes} routeProps={route}/>
              <div className={classes.content}>
                <div className={classes.drawerHeader} />
                <route.component />   
              </div>
            </Route>
          )})
        }
        <Route path={path} >
          <Redirect to={"/associated/mural"} />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default AdminLayout;